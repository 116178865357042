import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";

import EditTradeBatchOffer from "./EditTradeBatchOffer";
import Button from "../../../../shared/components/Buttons/Button";
import InventoryOverviewThreeCards from "../../../../shared/components/inventoryOverviewThreeCards/InventoryOverviewThreeCards";
import {
  toastType,
  toastMessages,
  buttonNameConstants,
  buttonTypeConstants,
  transactionConstants,
} from "../../../../constants";
import {
  customToast,
  isSpinnerEnabled,
  isAllSystemHasSerialNumber,
  parseToThousandSeperatorDecimalNumber,
} from "../../../../shared/utility";

const AddTradeBatchFooter = ({
  data,
  closeModal,
  spinnerArray,
  handleResetBatch,
  cashTradeOfferValue,
  handleOfferValueChange,
  ...props
}) => {
  const handleCashChange = (data) => {
    if (data.value > 0) {
      handleOfferValueChange(data, true);
    } else {
      customToast(toastMessages.TOTAL_CASH_OFFER, toastType.ERROR);
    }
  };
  const handleTradeChange = (data) => {
    if (data.value > 0) {
      handleOfferValueChange(data);
    } else {
      customToast(toastMessages.TOTAL_TRADE_OFFER, toastType.ERROR);
    }
  };

  return (
    <>
      <Row className="m-0 mb-3 px-0  mt-4">
        <Col
          md={6}
          className="  d-flex flex-column align-items-center justify-content-center"
        >
          <Col md={12} className="h-100">
            <EditTradeBatchOffer
              {...props}
              data={data}
              handleTradeChange={handleTradeChange}
              handleCashChange={handleCashChange}
              cashTradeOfferValue={cashTradeOfferValue}
              handleRefresh={handleResetBatch}
            />
          </Col>
        </Col>
        <Col md={6} className="pe-0">
          <InventoryOverviewThreeCards
            data={[
              {
                label: "Total Items",
                value: data.totalItems ?? 0,
              },
              {
                label: "Total Market Value",
                value:
                  "$" +
                  parseToThousandSeperatorDecimalNumber(
                    data.totalMarketPrice ?? 0,
                    true
                  ),
              },
              {
                label: "Total Stock Price",
                value:
                  "$" +
                  parseToThousandSeperatorDecimalNumber(
                    data.totalPrice ?? 0,
                    true
                  ),
              },
            ]}
          />
          <Row className="m-0 mt-3">
            <Col
              md={6}
              className="d-flex ps-0 justify-content-end align-items-center"
            >
              <Button
                label={buttonNameConstants.CLOSE}
                className="w-100 "
                type="button"
                handleClick={() => closeModal()}
                buttonType={buttonTypeConstants.GHOST_BUTTON}
                isDisabled={isSpinnerEnabled(
                  spinnerArray,
                  transactionConstants.ADD_TRADE_BATCH_ITEM
                )}
              />
            </Col>
            <Col md={6} className="d-flex pe-0 align-items-center">
              <Button
                label={buttonNameConstants.ADD_TO_CART}
                className="w-100"
                type="submit"
                handleClick={props.handleSubmitAddBatchInventory}
                isDisabled={
                  !Number(data.totalItems) ||
                  cashTradeOfferValue.cashOfferValue <= 0 ||
                  cashTradeOfferValue.tradeOfferValue <= 0 ||
                  isSpinnerEnabled(
                    spinnerArray,
                    transactionConstants.ADD_TRADE_BATCH_ITEM
                  ) ||
                  isAllSystemHasSerialNumber(
                    props.currentStore,
                    props.addTradeBatchInventory
                  )
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default AddTradeBatchFooter;

import React, { useRef } from "react";
import { Form, Formik } from "formik";
import { Modal, Row, Col } from "react-bootstrap";

import DataTable from "react-data-table-component";
import Button from "../../../../shared/components/Buttons/Button";
import {
  PRODUCT_TYPES_ENUMS,
  SET_BY_MARGIN_ENUMS,
} from "../../../../system/globalEnums";
import SelectField from "../../../../shared/components/selectField/SelectField";
import SwitchButton from "../../../../shared/components/SwitchButton/SwitchButton";
import TradeFilters from "../../../../shared/components/FilterDropdowns/TradeFilters";
import InputNumberField from "../../../../shared/components/inputNumberField/InputNumberField";
import {
  globalConstants,
  buttonNameConstants,
  buttonTypeConstants,
  SET_BY_TRADE_IN_MARGIN_OPTIONS,
  EMPTY_STRING,
  toastMessages,
  toastType,
  tradeInDiscountConstant,
} from "../../../../constants";
import {
  tradeInsModalValidationSchema,
  tradeInsModalWithOutCheckValidationSchema,
} from "../../../../shared/validationSchema/validationSchema";
import {
  PercentageIcon,
  DefaultSortingIcon,
  AsendingSortingIcon,
  DesendingSortingIcon,
  DollarIcon,
} from "../../../../assets/icons/iconsProvider";
import SearchInventory from "../../../Inventory/Items/SearchInventory/SearchInventory";
import SelectDropDown from "../../../../shared/components/selectDropdown/SelectDropdown";
import ProductTagList from "../../../../shared/components/tags/ProductTagList";
import {
  customToast,
  getAllDefaultTags,
  getProductSubCategory,
} from "../../../../shared/utility";

const AddTradeInMargin = ({
  show,
  isEdit,
  columns,
  setColumns,
  productTags,
  customTypes,
  setByFilter,
  selectedTags,
  editCategory,
  itemMarginUnit,
  categoriesColumn,
  searchCategories,
  customCategories,
  handleSetByChange,
  modalHandleSubmit,
  filterProductType,
  setItemMarginUnit,
  selectedCondition,
  handleSwitchChange,
  selectedCategories,
  customSubCategories,
  modalCheckBoxStatus,
  setSearchCategories,
  setFilterProductType,
  handleCategorySelect,
  productConditionList,
  setSelectedCategories,
  productMarginColumns,
  filterProductCategory,
  setSelectedCondition,
  toggleCustomTradeModal,
  handleSelectedTagsChange,
  setFilterProductCategory,
  defaultCategorySortFieldId,
  setDefaultCategorySortFieldId,
}) => {
  const asyncSelectRef = useRef();

  const initialModalValues = {
    id: editCategory?.id || globalConstants.EMPTY_STRING,
    cashOffer:
      editCategory?.cashMarginPercentage || globalConstants.EMPTY_STRING,
    tradeOffer:
      editCategory?.tradeinMarginPercentage || globalConstants.EMPTY_STRING,
    minPrice:
      Number(editCategory?.minPriceRange) >= 0
        ? editCategory?.minPriceRange
        : "0.00",
    maxPrice:
      Number(editCategory?.maxPriceRange) >= 0
        ? editCategory?.maxPriceRange
        : "0.00",
    setBy: editCategory?.setBy || SET_BY_MARGIN_ENUMS.TYPE.value,
  };

  const getFormatedColumns = (categoriesColumn) => {
    const formattedColumns = categoriesColumn.map((column) => {
      if (column.sortable) {
        return {
          ...column,
          name: (
            <div className=" d-flex w-100">
              {column.name}
              <div className="ml-2 d-flex justify-content-center align-items-center">
                {column.sortDirection === null && (
                  <DefaultSortingIcon className="ms-1 cursor-pointer" />
                )}
                {column.sortDirection === globalConstants.SORT.ASC && (
                  <AsendingSortingIcon className="ms-1 cursor-pointer" />
                )}
                {column.sortDirection === globalConstants.SORT.DESC && (
                  <DesendingSortingIcon className="ms-1 cursor-pointer" />
                )}
              </div>
            </div>
          ),
        };
      } else {
        return column;
      }
    });

    return formattedColumns;
  };

  const onCategoryTableSort = (column, sortDirection) => {
    setColumns(
      columns.map((col) => {
        if (col.id === column.id) {
          return {
            ...col,
            sortDirection,
          };
        } else {
          return {
            ...col,
            sortDirection: null,
          };
        }
      })
    );

    setDefaultCategorySortFieldId(column.id);
  };

  const handleMenuProductClick = (value) => {
    if (
      selectedCategories?.length >= tradeInDiscountConstant.MAXIMUM_PRODUCTS
    ) {
      customToast(
        `Maximum ${tradeInDiscountConstant.MAXIMUM_PRODUCTS} products is allowed at a time.`,
        toastType.ERROR
      );
      return;
    }

    const isProductAlreadyExist = selectedCategories.find(
      (item) => item.product_id === value.product_id
    );
    if (isProductAlreadyExist) {
      customToast(toastMessages.PRODUCT_ALREADY_ADDED, toastType.ERROR);
      return;
    }

    setSelectedCategories([
      ...selectedCategories,
      {
        product_id: value.product_id,
        product_name: value.product_name,
        productType: value.productType,
        category_name: value.category_name,
        cardRarity: EMPTY_STRING,
        subcategory: getProductSubCategory(value.genre),
      },
    ]);
  };

  return (
    <Modal
      show={show}
      onHide={toggleCustomTradeModal}
      size="md"
      animation={true}
      centered
      backdrop="static"
      className="add-inventory-modal"
    >
      <Formik
        initialValues={initialModalValues}
        validate={
          modalCheckBoxStatus
            ? tradeInsModalValidationSchema
            : tradeInsModalWithOutCheckValidationSchema
        }
        onSubmit={modalHandleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form className="px-0">
            <Modal.Header closeButton>
              <Modal.Title>
                {!isEdit
                  ? "Add Custom Trade-in Margin"
                  : "Edit Custom Trade-in Margin"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
              <Row className="m-0 p-0">
                <Col md={12} className="p-0 mb-3">
                  <SelectField
                    name="setBy"
                    label="Set By:"
                    options={SET_BY_TRADE_IN_MARGIN_OPTIONS}
                    isSearchable={false}
                    placeHolder={"Set By..."}
                    onChangeValue={handleSetByChange}
                    isDisabled={isEdit}
                  />
                </Col>
                <p className="p-0">
                  Apply to{" "}
                  {
                    SET_BY_TRADE_IN_MARGIN_OPTIONS.find(
                      (option) => option.value === values.setBy
                    ).label
                  }
                  :
                </p>
                <TradeFilters
                  setBy={values.setBy}
                  customTypes={customTypes}
                  customCategories={customCategories}
                  filterProductType={filterProductType}
                  customSubCategories={customSubCategories}
                  setFilterProductType={setFilterProductType}
                  filterProductCategory={filterProductCategory}
                  setFilterProductCategory={setFilterProductCategory}
                />
                {values.setBy !== SET_BY_MARGIN_ENUMS.PRODUCT.value ? (
                  <Col md={12} className="p-0 position-relative">
                    <SelectField
                      name="searchOptions"
                      options={searchCategories.map((category) => ({
                        value: category,
                        label: category?.name,
                      }))}
                      isSearchable={true}
                      isSelectedValueAsPlaceholder={false}
                      placeHolder={
                        values.setBy === SET_BY_MARGIN_ENUMS.TYPE.value
                          ? "Search Types"
                          : values.setBy === SET_BY_MARGIN_ENUMS.CATEGORY.value
                          ? "Search Categories"
                          : values.setBy ===
                            SET_BY_MARGIN_ENUMS.SUBCATEGORY.value
                          ? "Search Sub-Categories"
                          : "Search Item"
                      }
                      onChangeValue={(option) => {
                        handleCategorySelect(
                          option,
                          setByFilter,
                          searchCategories,
                          setSearchCategories,
                          selectedCategories,
                          setSelectedCategories,
                          filterProductCategory
                        );
                      }}
                    />
                  </Col>
                ) : (
                  <Col md={12} className="p-0">
                    <SearchInventory
                      asyncSelectRef={asyncSelectRef}
                      isTradeInSearch={true}
                      handleMenuProductClick={handleMenuProductClick}
                      dropdownIndicator={false}
                      placeHolder="Add Product By Search"
                      defaultProductType={PRODUCT_TYPES_ENUMS.VIDEO_GAME}
                      customTypes={customTypes}
                      customCategories={customCategories}
                      isTypeDisabled={true}
                    />
                  </Col>
                )}
                <Col md={12} className="p-0 mt-2">
                  <DataTable
                    className="categories-data-table"
                    columns={getFormatedColumns(
                      values.setBy === SET_BY_MARGIN_ENUMS.PRODUCT.value
                        ? productMarginColumns
                        : categoriesColumn
                    )}
                    data={selectedCategories}
                    fixedHeader={true}
                    onSort={onCategoryTableSort}
                    defaultSortFieldId={defaultCategorySortFieldId}
                  />
                </Col>
                {values.setBy === SET_BY_MARGIN_ENUMS.PRODUCT.value ? (
                  <>
                    <Col md={12} className="px-0 mt-2">
                      <p className="p-0">Select Condition/Tags:</p>
                    </Col>
                    <Col md={6} className="px-0">
                      <SelectDropDown
                        label="Condition"
                        menuPortalTarget={""}
                        menuPlacement={"bottom"}
                        options={productConditionList}
                        value={selectedCondition}
                        handleChange={setSelectedCondition}
                      />
                    </Col>
                    <Col md={12} className="px-0 mt-3">
                      <div className="trade-in-item-tag-wrapper p-3">
                        <Col md={12} className="pricing-Text p-0">
                          Tags({selectedTags?.length || 0}):
                        </Col>
                        <ProductTagList
                          isShowLabel={false}
                          className={
                            "d-flex flex-column align-items-start mt-3"
                          }
                          tagsList={getAllDefaultTags(productTags)}
                          suggestedArrayList={productTags}
                          onSelectTagsChange={handleSelectedTagsChange}
                          customProductTags={true}
                          productDetail={{
                            tags: [
                              ...selectedTags?.map((tag, index) => ({
                                id: index,
                                label: tag,
                                selected: true,
                              })),
                              ...getAllDefaultTags(productTags)
                                .filter(
                                  (defaultTag) =>
                                    !selectedTags.includes(defaultTag)
                                )
                                .map((defaultTag, index) => ({
                                  id: index + selectedTags.length, // Adjusting ID to ensure it doesn't overlap
                                  label: defaultTag,
                                  selected: false,
                                })),
                            ],
                          }}
                        />
                      </div>
                    </Col>
                    <Col
                      md={5}
                      className="px-0 mt-3 d-flex align-items-center gap-3 my-3"
                    >
                      <h5 className="label-modal-text-heading mb-0">
                        Percentage
                      </h5>
                      <SwitchButton
                        onSwitchChange={(id, value) => {
                          setFieldValue("cashOffer", 0);
                          setFieldValue("tradeOffer", 0);
                          setItemMarginUnit(value);
                        }}
                        isSwitchOn={itemMarginUnit}
                        id="print-label-switch"
                      />
                      <h5 className="label-modal-text-heading mb-0 text-nowrap">
                        Fixed Value
                      </h5>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col md={5} className="mt-3 mb-3 p-0">
                      <SwitchButton
                        onSwitchChange={handleSwitchChange}
                        label={"Set Price Range"}
                        id="setpricerange"
                        className="switch-alignment"
                        isSwitchOn={modalCheckBoxStatus}
                      />
                    </Col>
                    <Col
                      md={7}
                      className="d-flex gap-2 mt-2 mb-2 p-0 justify-content-between"
                    >
                      {modalCheckBoxStatus && (
                        <>
                          <Col md={6} className=" ps-0 ">
                            <InputNumberField
                              name="minPrice"
                              placeHolder="Min Price"
                              type="number"
                            />
                          </Col>
                          <Col md={6} className=" pe-0 ">
                            <InputNumberField
                              name="maxPrice"
                              placeHolder="Max Price"
                              type="number"
                            />
                          </Col>
                        </>
                      )}
                    </Col>
                  </>
                )}
                <Row className=" m-0 offer-percentage-div ">
                  <Col
                    className=" ps-0 d-flex align-items-center  m-0 mb-3 "
                    md={8}
                  >
                    <span className="trade-offer-text m-0">
                      {values.setBy === !SET_BY_MARGIN_ENUMS.PRODUCT.value
                        ? "Cash Offer Percentage:"
                        : "Cash Offer"}
                    </span>
                  </Col>
                  <Col md={4} className=" pe-0 mb-3 ">
                    <InputNumberField
                      className="input-field-background-tradeIns"
                      name="cashOffer"
                      placeHolder={`00.00`}
                      type="number"
                      maxValue={itemMarginUnit ? 9999 : 100}
                      IconImage={itemMarginUnit ? DollarIcon : PercentageIcon}
                    />
                  </Col>
                  <Col className=" ps-0 d-flex align-items-center  m-0 " md={8}>
                    <span className="trade-offer-text m-0">
                      {values.setBy === !SET_BY_MARGIN_ENUMS.PRODUCT.value
                        ? " Trade Offer Percentage:"
                        : " Trade Offer"}
                    </span>
                  </Col>
                  <Col md={4} className=" pe-0 ">
                    <InputNumberField
                      className="input-field-background-tradeIns"
                      name="tradeOffer"
                      placeHolder={`00.00`}
                      type="number"
                      maxValue={itemMarginUnit ? 9999 : 100}
                      IconImage={itemMarginUnit ? DollarIcon : PercentageIcon}
                    />
                  </Col>
                </Row>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Col md={12} className=" m-0 d-flex gap-2">
                <Button
                  type="button"
                  label={buttonNameConstants.CANCEL}
                  handleClick={toggleCustomTradeModal}
                  buttonType={buttonTypeConstants.GHOST_BUTTON}
                  className="w-100"
                ></Button>
                <Button
                  type="submit"
                  className="w-100"
                  label={
                    isEdit
                      ? buttonNameConstants.UPDATE
                      : buttonNameConstants.SAVE
                  }
                ></Button>
              </Col>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddTradeInMargin;

import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";

import { EMPTY_STRING, transactionConstants } from "../../../../constants";
import EditTradeBatchOfferModal from "../../../SellAndTrade/NewTrade/AddTradeBatch/EditTradeBatchOfferModal";
import {
  parseToDecimalNumber,
  parseToThousandSeperatorDecimalNumber,
} from "../../../../shared/utility";
import {
  EditIcon,
  ResetCartIcon,
} from "../../../../assets/icons/iconsProvider";

const EditCogsOffer = ({
  data,
  cogsPrice,
  handleRefresh,
  handleCogsChange,
  addBatchInventory,
}) => {
  // useState
  const [isEditOfferModalOpen, setIsEditOfferModalOpen] =
    useState(EMPTY_STRING);
  const offerPercentages = {
    cogOfferPercentage:
      (data?.totalMarketPrice && (cogsPrice / data?.totalMarketPrice) * 100) ||
      0,
  };

  //-------open edit offer modal
  const toggleEditOfferModal = (value) => setIsEditOfferModalOpen(value);
  const isDisabled = !addBatchInventory?.length;
  return (
    <Row className="h-100 w-100 edit-total-cash-trade-wrapper ">
      <Col
        sm={12}
        className="d-flex align-items-center justify-content-between mb-2"
      >
        <span className="batch-edit-offer-heading">Total COGS</span>
        <button
          className={`action-Button-Wrapper ps-0 pe-0 reset-total-button`}
          onClick={() => handleRefresh()}
          disabled={isDisabled}
        >
          <ResetCartIcon className={`link-unlink-button-icon`} />
        </button>
      </Col>
      <Col
        sm={12}
        className="d-flex justify-content-center align-items-center gap-4"
      >
        <div className="w-100">
          <Row className="m-0">
            <Col
              md={12}
              className="d-flex justify-content-between align-items-center px-0 position-relative"
            >
              <span className="edit-trade-batch-offer-field-heading">COGS</span>
              <button
                className="action-Button-Wrapper ps-0 pe-0 reset-total-button cursor-none"
                onClick={() => {
                  toggleEditOfferModal(transactionConstants.EDIT_COGS_MODAL);
                }}
                disabled={isDisabled}
              >
                <EditIcon className=" link-unlink-button-icon" />
              </button>
              {isEditOfferModalOpen === transactionConstants.EDIT_COGS_MODAL ? (
                <EditTradeBatchOfferModal
                  data={data}
                  handleSubmit={handleCogsChange}
                  headingLabel={"Edit Total Cash Offer"}
                  value={cogsPrice}
                  isEditOfferModalOpen={isEditOfferModalOpen}
                  toggleEditOfferModal={toggleEditOfferModal}
                  percentage={offerPercentages?.cogOfferPercentage}
                />
              ) : (
                ""
              )}
            </Col>
            <Col
              md={12}
              className="d-flex justify-content-between align-items-center mt-1  px-0"
            >
              <span className="edit-trade-batch-offer-field">
                ${parseToThousandSeperatorDecimalNumber(cogsPrice || 0)}
                <span className="ms-2">
                  (
                  {`${parseToDecimalNumber(
                    offerPercentages?.cogOfferPercentage || 0
                  )} %`}
                  )
                </span>
              </span>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default EditCogsOffer;

import React from "react";
import { Col, Row } from "react-bootstrap";

import NetworkAveragePriceSelect from "./NetworkAveragePriceSelect";
import { EMPTY_STRING, NETWORK_AVERAGE_PRICE_TYPES } from "../../../constants";
import {
  convertToUsd,
  parseToThousandSeperatorDecimalNumber,
} from "../../utility";

const AddEditInventoryModalPricesHistoryStats = (props) => {
  // initializations
  const {
    productDataObject: {
      gameStopBuyPrice,
      averageBuyPrice,
      maxBuyPrice,
      averageSellPrice,
      totalUnitsSold,
      networkAverageBuySellPrices,
      ...productDataObject
    },
    propertyNameOfStatCardsToHide = [],
    selectedCondition: selectedCondition,
  } = props;

  return (
    <Row className="m-0">
      <Col
        md={12}
        className="add-inventory-prices-detail-wrapper product-stats-con-height container-scrollbar d-flex flex-column align-items-lg-stretch gap-3"
      >
        {[
          {
            title: "Total Units Sold",
            value: totalUnitsSold,
            isDollarSign: false,
            isVisible:
              !propertyNameOfStatCardsToHide.includes("totalUnitsSold"),
          },
          {
            title: "GameStop Buy Price",
            value: parseToThousandSeperatorDecimalNumber(
              convertToUsd(gameStopBuyPrice)
            ),
            isVisible:
              !propertyNameOfStatCardsToHide.includes("gameStopBuyPrice"),
          },
          {
            title: "Average Buy Price",
            value: parseToThousandSeperatorDecimalNumber(averageBuyPrice || 0),
            isVisible:
              !propertyNameOfStatCardsToHide.includes("averageBuyPrice"),
          },
          {
            title: "Max Buy Price",
            value: parseToThousandSeperatorDecimalNumber(maxBuyPrice || 0),
            isVisible: !propertyNameOfStatCardsToHide.includes("maxBuyPrice"),
          },
          {
            title: "Average Sell Price",
            value: parseToThousandSeperatorDecimalNumber(averageSellPrice || 0),
            isVisible:
              !propertyNameOfStatCardsToHide.includes("averageSellPrice"),
          },
        ].map(
          (element) =>
            element.isVisible && (
              <div className="price-stat-con">
                <span className="price-stat-heading">{element.title}</span>
                <span>
                  {element.isDollarSign === false ? EMPTY_STRING : "$"}
                  {element.value}
                </span>
              </div>
            )
        )}
        <NetworkAveragePriceSelect
          networkAverageBuySellPrices={networkAverageBuySellPrices}
          networkAveragePriceType={NETWORK_AVERAGE_PRICE_TYPES.SELL_PRICE}
        />
        <NetworkAveragePriceSelect
          networkAverageBuySellPrices={networkAverageBuySellPrices}
          networkAveragePriceType={NETWORK_AVERAGE_PRICE_TYPES.BUY_PRICE}
        />
      </Col>
    </Row>
  );
};

export default AddEditInventoryModalPricesHistoryStats;

import { STATS_SCREEN_CONSTANTS } from "../constants";
import {
  postRequest,
  getRequest,
  deleteRequest,
  putRequest,
  uploadLogoRequest,
  getZplCodeRequest,
  deleteRequestWithData,
} from "../system/serverCall";
import serverResponseHandler from "../system/serverResponseHandler";

//-------Get Store Details from server
const getStores = () => {
  return getRequest("store", true).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Add store service
const addStore = (data) => {
  return postRequest("store", data, true).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Edit store service
const editStore = (data) => {
  return putRequest(`store/${data.id}`, data, true).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Get Store Details from server
const getStoreOwners = () => {
  return getRequest("get-users?role=store-owner", true).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Delete Store from server
const deleteStore = (storeId) => {
  return deleteRequest(`store/${storeId}`, true).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

const uploadLogo = (data) => {
  return uploadLogoRequest("upload", data).then(
    (response) => response,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

const updateReceiptDetails = (id, data) => {
  return postRequest(`receipt-details/${id}?isUpdateLogo=true`, data).then(
    (response) => response,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

const updateStoreSettings = (id, data) => {
  return postRequest(`receipt-details/${id}`, data).then(
    (response) => response,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

const getUserNotifications = (storeId) => {
  return getRequest(`notification/${storeId}`, true).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};
//-------Store Subscription payment
const storeSubscriptionPayment = (storeId) => {
  return getRequest(`store-subscription-payment/${storeId}`, true).then(
    (response) => response.data.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------payouts information
const getPayoutsInformation = (storeId, startDate, endDate) => {
  return getRequest(
    `at-a-glance/${storeId}?startDate=${startDate}&endDate=${endDate}&screenName=${STATS_SCREEN_CONSTANTS.FINANCE_STATS}`
  ).then(
    (response) => response.data.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};
//-------mark notification as read

const markNotificationAsRead = (storeId, data) => {
  return putRequest(`notification/${storeId}`, data).then(
    (response) => response,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

const createNotification = (storeId, data) => {
  return postRequest(`notification/${storeId}`, data).then(
    (response) => response,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

const editSaleTax = (ObjectDetail, data) => {
  return putRequest(
    `store-budget/${ObjectDetail.storeId}?isDefaultTax=true`,
    data
  ).then(
    (response) => response?.data?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

const getStoreLogoZplCode = (data) => {
  return getZplCodeRequest("https://api.labelary.com/v1/graphics", data).then(
    (response) => response,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Cancel Store from server
const cancelStripeSubscription = (data) => {
  return deleteRequestWithData(
    `store-subscription-payment/${data.storeId}`,
    data,
    true
  ).then(
    (response) => response?.data,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Export store Services
export const storeService = {
  getStores,
  addStore,
  editStore,
  getStoreOwners,
  deleteStore,
  uploadLogo,
  updateReceiptDetails,
  getUserNotifications,
  storeSubscriptionPayment,
  getPayoutsInformation,
  markNotificationAsRead,
  editSaleTax,
  getStoreLogoZplCode,
  cancelStripeSubscription,
  createNotification,
  updateStoreSettings,
};

import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";

import {
  EditIcon,
  LinkIcon,
  ResetCartIcon,
  UnLinkIcon,
} from "../../../../assets/icons/iconsProvider";
import EditTradeCashOfferModal from "./EditTradeCashOfferModal";
import {
  EMPTY_STRING,
  globalConstants,
  toastMessages,
  toastType,
  transactionConstants,
} from "../../../../constants";
import {
  customToast,
  parseToThousandSeperatorDecimalNumber,
} from "../../../../shared/utility";

const EditTotalCashTrade = ({
  data,
  setIsLink,
  activeButton,
  isLink = false,
  totalCashTrade,
  handleResetCartItems,
  handleButtonClick,
  handleOfferValueChange,
}) => {
  // useState
  const [isEditOfferModalOpen, setIsEditOfferModalOpen] =
    useState(EMPTY_STRING);
  const isButtonsDisbaled = !data?.inventory?.length;
  //-------open edit offer modal
  const toggleEditOfferModal = (value) => setIsEditOfferModalOpen(value);

  //------- calculate Percentages for totalCashOffer and totalTradeOffer with respect to market price
  const offerPercentages = {
    //------- totalCashOffer/totalMarketPrice * 100
    cashOfferPercentage:
      Number(data?.totalMarketPrice) !== 0 && data?.totalMarketPrice != null
        ? (totalCashTrade?.cashOfferValue / Number(data?.totalMarketPrice)) *
          100
        : 0,
    //------- totalTradeOffer/totalMarketPrice * 100
    tradeOfferPercentage:
      Number(data?.totalMarketPrice) !== 0 && data?.totalMarketPrice != null
        ? (totalCashTrade?.tradeOfferValue / Number(data?.totalMarketPrice)) *
          100
        : 0,
  };

  const handleApplyOffer = (data, isCashOfferValue = false) => {
    if (Number(data?.value) > 0) {
      handleOfferValueChange(data, isCashOfferValue);
    } else {
      customToast(
        isCashOfferValue
          ? toastMessages.TOTAL_CASH_OFFER
          : toastMessages.TOTAL_TRADE_OFFER,
        toastType.ERROR
      );
    }
  };

  return (
    <Row className="m-0 w-100 h-100 edit-cash-trade-wrapper">
      <Col sm={11} className="p-0 h-100">
        <Row className="h-100 w-100 edit-total-cash-trade-wrapper px-2 py-3">
          <Col sm={6} className="h-100">
            <div
              className={`position-relative w-100 h-100 d-flex flex-column justify-content-center align-items-center total-cash-wrapper  ${
                activeButton === globalConstants.CASH
                  ? "total-cash-wrapper-active"
                  : ""
              } `}
              onClick={() => handleButtonClick(globalConstants.CASH)}
            >
              <span className="total-cash-heading">Cash</span>
              <div className="edit-cash-trade-offer-value">
                <span>
                  $
                  {parseToThousandSeperatorDecimalNumber(
                    totalCashTrade.cashOfferValue
                  )}
                </span>
                <span>
                  (
                  {`${parseToThousandSeperatorDecimalNumber(
                    offerPercentages.cashOfferPercentage || 0
                  )}%`}
                  )
                </span>
              </div>
              <button
                className="action-Button-Wrapper ps-0 pe-0 edit-total-button"
                onClick={(event) => {
                  event.stopPropagation();
                  toggleEditOfferModal(
                    transactionConstants.EDIT_CASH_OFFER_MODAL
                  );
                }}
                disabled={!data?.inventory?.length}
              >
                <EditIcon className="edit-offer-icon" />
              </button>
              {isEditOfferModalOpen ===
              transactionConstants.EDIT_CASH_OFFER_MODAL ? (
                <EditTradeCashOfferModal
                  data={data}
                  handleSubmit={(data) => handleApplyOffer(data, true)}
                  value={totalCashTrade.cashOfferValue}
                  headingLabel={"Edit Total Cash Offer"}
                  isEditOfferModalOpen={isEditOfferModalOpen}
                  toggleEditOfferModal={toggleEditOfferModal}
                  percentage={offerPercentages.cashOfferPercentage}
                />
              ) : (
                ""
              )}
            </div>
          </Col>
          <Col sm={6} className="h-100">
            <div
              className={`position-relative w-100 h-100 d-flex flex-column justify-content-center align-items-center total-cash-wrapper  ${
                activeButton === globalConstants.CREDIT
                  ? "total-cash-wrapper-active"
                  : ""
              } `}
              onClick={() => handleButtonClick(globalConstants.CREDIT)}
            >
              <span className="total-cash-heading">Store Credit</span>
              <div className="edit-cash-trade-offer-value">
                <span>
                  $
                  {parseToThousandSeperatorDecimalNumber(
                    totalCashTrade.tradeOfferValue
                  )}
                </span>
                <span>
                  (
                  {`${parseToThousandSeperatorDecimalNumber(
                    offerPercentages.tradeOfferPercentage || 0
                  )}%`}
                  )
                </span>
              </div>
              <button
                className="action-Button-Wrapper ps-0 pe-0 edit-total-button"
                onClick={(event) => {
                  event.stopPropagation();
                  toggleEditOfferModal(
                    transactionConstants.EDIT_TRADE_OFFER_MODAL
                  );
                }}
                disabled={!data?.inventory?.length}
              >
                <EditIcon className=" edit-offer-icon" />
              </button>
              {isEditOfferModalOpen ===
              transactionConstants.EDIT_TRADE_OFFER_MODAL ? (
                <EditTradeCashOfferModal
                  data={data}
                  handleSubmit={(data) => handleApplyOffer(data)}
                  value={totalCashTrade.tradeOfferValue}
                  className={"edit-trade-offer-modal"}
                  headingLabel={"Edit Total Trade Offer"}
                  isEditOfferModalOpen={isEditOfferModalOpen}
                  toggleEditOfferModal={toggleEditOfferModal}
                  percentage={offerPercentages.tradeOfferPercentage}
                />
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>
      </Col>
      <Col
        sm={1}
        className="p-0 d-flex flex-column justify-content-center align-items-center gap-4"
      >
        <button
          className="action-Button-Wrapper ps-0 pe-0 reset-total-button"
          onClick={() => {
            setIsLink(!isLink);
          }}
        >
          {isLink ? (
            <LinkIcon className="cursor-pointer link-unlink-button-icon" />
          ) : (
            <UnLinkIcon className="cursor-pointer link-unlink-button-icon" />
          )}
        </button>
        <button
          className="action-Button-Wrapper ps-0 pe-0 reset-total-button"
          onClick={() => handleResetCartItems()}
          disabled={!data?.inventory?.length}
        >
          <ResetCartIcon className=" link-unlink-button-icon" />
        </button>
      </Col>
    </Row>
  );
};

export default EditTotalCashTrade;

import { isAdminLogin } from "../shared/utility";
import { postRequest } from "../system/serverCall";
import { setItemToLocalStorage, setItemToStorage } from "../system/storage";
import { authenticationConstants, globalConstants } from "../constants";
import serverResponseHandler from "../system/serverResponseHandler";
import { encryptObject, getUserInfoFromToken } from "../system/encryption";

//-------handling the Login response
//-------if user is authenticated then saving
//-------the jwt token in the local storage

//-------Handle login response
const handleCompanyLoginResponse = (response) => {
  const { status } = response;
  if (status !== authenticationConstants.LOGIN_STATUS.SUCCESS) {
    return serverResponseHandler.handleServerResponse(response);
  }

  const encryptedPayload = encryptObject({
    ...response.data.data,
  });

  const userInfo = getUserInfoFromToken(response?.data?.data?.accessToken);

  if (isAdminLogin(userInfo)) {
    setItemToStorage(process.env.REACT_APP_PAYLOAD, encryptedPayload);
  } else {
    setItemToLocalStorage(
      globalConstants.COMPANY_DOMAIN_URL,
      userInfo?.domainUrl
    );
    setItemToLocalStorage(
      process.env.REACT_APP_COMPANY_PAYLOAD,
      encryptedPayload
    );
  }

  return Promise.resolve(response.data.data);
};

//-------Handle Employee login response
const handleEmployeeLoginResponse = (response) => {
  const { status } = response;

  if (status !== authenticationConstants.LOGIN_STATUS.SUCCESS) {
    return serverResponseHandler.handleServerResponse(response);
  }

  const encryptedPayload = encryptObject({
    ...response.data.data,
  });

  setItemToStorage(process.env.REACT_APP_PAYLOAD, encryptedPayload);
  return Promise.resolve(response.data.data);
};

//-------Authenticating the user from server
const companyLogin = (userLoginData) => {
  return postRequest("login-company", userLoginData, false).then(
    (response) => handleCompanyLoginResponse(response),
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Authenticating the user from server
const employeeLogin = (userLoginData, companyId) => {
  return postRequest(`login/${companyId}`, userLoginData).then(
    (response) => handleEmployeeLoginResponse(response),
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Forgot Password
const forgotPassword = (data, companyId) => {
  return postRequest(`forgot-password?companyId=${companyId}`, data).then(
    (response) => response,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Forgot Password
const savePassword = (data) => {
  return postRequest("forgot-password?isResetPassword=true", data).then(
    (response) => response,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Forgot Password
const isLinkExpired = (data) => {
  return postRequest("forgot-password?isLinkExpired=true", data).then(
    (response) => response,
    (error) => serverResponseHandler.handleHTTPResponses(error.response)
  );
};

//-------Export Authentication Services
export const authenticationService = {
  companyLogin,
  employeeLogin,
  forgotPassword,
  savePassword,
  isLinkExpired,
};

import CryptoJS from "crypto-js";
import { jwtDecode } from "jwt-decode";

import { stringifyObject, parseJsonObject } from "../shared/utility";
import { globalConstants, authenticationConstants } from "../constants";
import {
  clearStorage,
  getItemFromLocalStorage,
  getItemFromStorage,
  removeItemFromStorage,
} from "./storage";

//-------Encrypt the object
export const encryptObject = (object) => {
  var ciphertext = CryptoJS.AES.encrypt(
    stringifyObject(object),
    process.env.REACT_APP_ENCRYPTED_KEY
  ).toString();

  return ciphertext;
};

//-------Decrypt the object
export const decryptObject = (
  encryptedString,
  key = process.env.REACT_APP_ENCRYPTED_KEY
) => {
  try {
    if (encryptedString) {
      var bytes = CryptoJS.AES.decrypt(encryptedString, key);
      // password encryption check as password is not a json but payload is a json
      var decryptedData =
        key !== process.env.REACT_APP_PASSWORD_ENCRYPTED_KEY
          ? parseJsonObject(bytes.toString(CryptoJS.enc.Utf8))
          : bytes.toString(CryptoJS.enc.Utf8);
      return decryptedData;
    } else {
      return globalConstants.EMPTY_OBJECT;
    }
  } catch (e) {
    removeItemFromStorage(process.env.REACT_APP_PAYLOAD);
    clearStorage();
    window.location.reload();
  }
};

//-------Decrypt Payload
export const getDecryptPayload = () => {
  const encryptedPayload =
    getItemFromStorage(process.env.REACT_APP_PAYLOAD) ||
    globalConstants.EMPTY_STRING;
  const payload = decryptObject(encryptedPayload);
  return payload;
};

//-------Decrypt Payload
export const getDecryptCompanyPayload = () => {
  const encryptedPayload =
    getItemFromLocalStorage(process.env.REACT_APP_COMPANY_PAYLOAD) ||
    globalConstants.EMPTY_STRING;
  const payload = decryptObject(encryptedPayload);
  return payload;
};

//-------Get userInfo from the token
export const getUserInfoFromToken = (token) => {
  const tokenBase64 = token;
  const tokenData = jwtDecode(tokenBase64);
  return tokenData;
};

//------Get Password Reset Decrypted token
export const getDecryptedResetPasswordToken = (token) => {
  try {
    return jwtDecode(token);
  } catch (error) {
    console.error("Error decrypting token:", error.message);
    return null;
  }
};

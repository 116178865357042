import React from "react";
import { useNavigate } from "react-router";
import { connect } from "react-redux";

import { TopbarPrinterIcon } from "../../../assets/icons/iconsProvider";
import { TopbarUserInfoIcon } from "../../../assets/icons/iconsProvider";
import { TopbarBillingIcon } from "../../../assets/icons/iconsProvider";
import { TopbarPayoutsIcon } from "../../../assets/icons/iconsProvider";
import { TopbarLogoutIcon } from "../../../assets/icons/iconsProvider";
import { routesPathConstants } from "../../../constants/routesPath.constant";
import { clearStorage } from "../../../system/storage";
import {
  createCompanyDynamicUrl,
  isAdminLogin,
  isStoreOwnerLogin,
} from "../../utility";
import { authenticationActions } from "../../../redux/actions";

const UserDropdown = (props) => {
  const navigate = useNavigate();
  const { user, isStoreActive } = props;

  return (
    <div
      className=""
      onMouseEnter={() => props.onMouseEnter()}
      onMouseLeave={() => props.onMouseLeave()}
    >
      <ul className="d-flex justify-content-end align-items-end flex-column">
        <li
          onClick={() =>
            navigate(
              createCompanyDynamicUrl(
                routesPathConstants.SETTINGS_PRINTER_SETTINGS
              )
            )
          }
        >
          {(isStoreActive || isAdminLogin(user)) && (
            <>
              <TopbarPrinterIcon className="pe-1" /> Printer Settings
            </>
          )}
        </li>
        <li
          onClick={() =>
            navigate(
              createCompanyDynamicUrl(routesPathConstants.USER_INFORMATION)
            )
          }
        >
          <TopbarUserInfoIcon className="pe-1" />
          User Information
        </li>
        {(isAdminLogin(user) || isStoreOwnerLogin(user)) && (
          <>
            {/* <li onClick={() => navigate(routesPathConstants.BILLING)}>
              <TopbarBillingIcon className="pe-1" />
              Billing
            </li> */}
            <li
              onClick={() =>
                navigate(createCompanyDynamicUrl(routesPathConstants.PAYOUTS))
              }
            >
              <TopbarPayoutsIcon className="pe-1" />
              Finances
            </li>
          </>
        )}

        <li
          onClick={() => {
            if (isAdminLogin(user)) {
              props.companyLogout();
            } else {
              authenticationActions.employeeLogout();
            }
          }}
        >
          <TopbarLogoutIcon className="pe-1" />
          Log Out
        </li>
      </ul>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.authentication.user,
  isStoreActive: state.store.currentStore.isStoreActive,
});
const mapDispatchToProps = (dispatch) => ({
  companyLogout: () => dispatch(authenticationActions.companyLogout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDropdown);

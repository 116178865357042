import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router";
import { Row, Col } from "react-bootstrap";

import { EMPTY_STRING, SIDEBAR_BUTTONS } from "../../constants";
import {
  isAdminLogin,
  isCompanyLogin,
  isSpinnerEnabled,
} from "../../shared/utility";
import LogoImage from "../../assets/images/LoginLogo.png";
import { authenticationActions } from "../../redux/actions";
import Button from "../../shared/components/Buttons/Button";
import {
  resetPasswordValidationSchema,
  resetPinValidationSchema,
} from "../../shared/validationSchema/validationSchema";
import InputPasswordField from "../../shared/components/inputPasswordField/InputPasswordField";
import {
  authenticationConstants,
  buttonNameConstants,
  buttonTypeConstants,
  routesPathConstants,
} from "../../constants";
import { useSearchParams } from "react-router-dom";
import { getDecryptedResetPasswordToken } from "../../system/encryption";

//-------Login component start here
const ResetPassword = (props) => {
  const initialValues = {
    newPassword: "",
    confirmPassword: "",
  };
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("id");
  const token = searchParams.get("token");
  const [decryptedToken, setDecryptedToken] = useState(EMPTY_STRING);

  const resetPasswordOrPin =
    isAdminLogin(decryptedToken) || isCompanyLogin(decryptedToken)
      ? "Password"
      : "Pin";

  //-------Handle handleSavePassword click
  const handleSavePassword = (values) => {
    props.handleSavePassword(
      {
        password: values.newPassword,
        token: token,
        userId: userId,
        email: EMPTY_STRING,
      },
      decryptedToken
    );
    props.setActiveSidebarBtn(SIDEBAR_BUTTONS.DASHBOARD);
  };

  const handleLoginClick = () => {
    navigate(routesPathConstants.LOGIN);
  };

  useEffect(() => {
    if (userId && token) {
      props.isLinkExpired({
        userId: userId,
        email: EMPTY_STRING,
        token: token,
      });
      setDecryptedToken(getDecryptedResetPasswordToken(token));
    } else {
      navigate(routesPathConstants.DEFAULT_PATH);
    }
  }, [userId, token]);

  //-------Return Login Component
  return (
    <div>
      <div className=" login-card-wrapper row d-flex justify-content-center align-items-center m-0 p-4 min-vh-100 min-vw-100">
        <img
          className="login-collect-pos-icon"
          src={LogoImage}
          alt="collect pos icon"
        />
        <div className="login-card col-sm-8 col-md-6 col-lg-4 py-4 px-5 pb-3 d-flex flex-column justify-content-center align-items-center rounded-4">
          <h1 className="py-2">Reset your {resetPasswordOrPin}</h1>
          <Formik
            initialValues={initialValues}
            validate={
              isAdminLogin(decryptedToken) || isCompanyLogin(decryptedToken)
                ? resetPasswordValidationSchema
                : resetPinValidationSchema
            }
            onSubmit={handleSavePassword}
          >
            <Form className="login-form-wrapper">
              <Row className="w-100 m-0">
                <Col xs={12} className="p-0 mb-3">
                  <InputPasswordField
                    type="password"
                    name="newPassword"
                    label={`New ${resetPasswordOrPin}`}
                    placeHolder={`Enter New ${resetPasswordOrPin}`}
                    autoComplete="off"
                  />
                </Col>
                <Col xs={12} className="p-0 mb-3">
                  <InputPasswordField
                    type="password"
                    name="confirmPassword"
                    label={`Confirm ${resetPasswordOrPin}`}
                    placeHolder={`Enter Confirm ${resetPasswordOrPin}`}
                    autoComplete="off"
                  />
                </Col>
                <Col xs={12} className="p-0 mt-3 ">
                  <Button
                    type="submit"
                    className="w-100"
                    label={`${buttonNameConstants.SAVE}  ${resetPasswordOrPin}`}
                    buttonType={buttonTypeConstants.BLACK_BUTTON}
                    isDisabled={isSpinnerEnabled(
                      props.spinnerArray,
                      authenticationConstants.RESET_PASSWORD
                    )}
                  />
                </Col>
                <Col
                  xs={12}
                  className="p-0 mt-2 mb-0 d-flex align-items-center"
                >
                  <span
                    className="forgot-password-link"
                    onClick={handleLoginClick}
                  >
                    Login to your account
                  </span>
                </Col>
                <Col
                  xs={12}
                  className="p-0 mt-4 mb-0 d-flex justify-content-center align-items-center text-muted"
                >
                  Powered by CollectPOS
                </Col>
              </Row>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
}; //-------Login component end here

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  spinnerArray: state.system.spinnerArray,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  handleSavePassword: (data, role) =>
    dispatch(authenticationActions.savePassword(data, role)),
  isLinkExpired: (data) => dispatch(authenticationActions.isLinkExpired(data)),
});

//-------Export Login Component
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);

import { SET_BY_MARGIN_ENUMS } from "../system/globalEnums";

export const tradeInDiscount = {
  ADD_GLOBAL_MARGIN: "ADD_GLOBAL_MARGIN",
  ADD_CATEGORY_MARGIN: "ADD_CATEGORY_MARGIN",
  DELETE_CATEGORY_MARGIN: "DELETE_CATEGORY_MARGIN",
  UPDATE_CATEGORY_MARGIN: "UPDATE_CATEGORY_MARGIN",
  ADD_MONTHLY_BUDGET: "ADD_MONTHLY_BUDGET",
  ADD_DISCOUNT: "ADD_DISCOUNT",
  DELETE_CATEGORYTYPE_DISCOUNT: "DELETE_CATEGORYTYPE_DISCOUNT",
  UPDATE_CATEGORYTYPE_DISCOUNT: "UPDATE_CATEGORYTYPE_DISCOUNT",
  GET_ALL_DISCOUNT: "GET_ALL_DISCOUNT",
  GET_ALL_MARKUP: "GET_ALL_MARKUP",
  GET_ALL_MARGIN: "GET_ALL_MARGIN",
};

export const tradeInDiscountConstant = {
  MAXIMUM_PRODUCTS: 50,
  ROUND_VALUE_TO_95: ".95",
  ROUND_VALUE_TO_99: ".99",
  ROUND_VALUE_TO_00: ".00",
  PERCENTAGE: "PERCENTAGE",
  FIXED_VALUE: "FIXED_VALUE",
  DISCOUNT_OPTION: [
    {
      value: "Percentage",
      label: "Percentage Discount",
    },

    // {
    //   value: "Standard",
    //   label: "Standard",
    // },
  ],
  CATEGORY_TYPE: [
    {
      value: "Type",
      label: "Type",
    },
    {
      value: "Category",
      label: "Category",
    },
    {
      value: "Subcategory",
      label: "Subcategory",
    },
  ],
  DEFAULT_MIN_RANGE: -2,
  DEFAULT_MAX_RANGE: -1,
};

export const SET_BY_MARGIN_OPTIONS = [
  {
    value: SET_BY_MARGIN_ENUMS.TYPE.value,
    label: SET_BY_MARGIN_ENUMS.TYPE.label,
  },
  {
    value: SET_BY_MARGIN_ENUMS.CATEGORY.value,
    label: SET_BY_MARGIN_ENUMS.CATEGORY.label,
  },
  {
    value: SET_BY_MARGIN_ENUMS.SUBCATEGORY.value,
    label: SET_BY_MARGIN_ENUMS.SUBCATEGORY.label,
  },
];

export const SET_BY_TRADE_IN_MARGIN_OPTIONS = [
  {
    value: SET_BY_MARGIN_ENUMS.TYPE.value,
    label: SET_BY_MARGIN_ENUMS.TYPE.label,
  },
  {
    value: SET_BY_MARGIN_ENUMS.CATEGORY.value,
    label: SET_BY_MARGIN_ENUMS.CATEGORY.label,
  },
  {
    value: SET_BY_MARGIN_ENUMS.SUBCATEGORY.value,
    label: SET_BY_MARGIN_ENUMS.SUBCATEGORY.label,
  },
  {
    value: SET_BY_MARGIN_ENUMS.PRODUCT.value,
    label: SET_BY_MARGIN_ENUMS.PRODUCT.label,
  },
];

import { ROLES } from "../../constants";

//------ login user is Admin or not
export const isAdminLogin = (userInfo) => {
  return userInfo?.role === ROLES.ADMIN || false;
};

//------ login user is Admin or not
export const isCompanyLogin = (userInfo) => {
  return userInfo?.role === ROLES.COMPANY || false;
};

//------- is Employee Login
export const isEmployeeLogin = (userInfo) => {
  return userInfo?.role === ROLES.EMPLOYEE || false;
};

//------- is StoreOwner login
export const isStoreOwnerLogin = (userInfo) => {
  return userInfo?.role === ROLES.STORE_OWNER || false;
};
//------- is Manager Login
export const isManagerLogin = (userInfo) => {
  return userInfo?.role === ROLES.STORE_MANAGER || false;
};

import React, { useState, useRef, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Formik, Form } from "formik";
import { connect } from "react-redux";

import TextareaField from "../../../shared/components/textareaField/TextareaField";
import Button from "../../../shared/components/Buttons/Button";
import {
  buttonNameConstants,
  buttonTypeConstants,
  EMPTY_STRING,
  globalConstants,
  storeConstants,
  toastType,
  toggleSwitchButtonConstants,
} from "../../../constants";
import { addReceiptLogoValidationSchema } from "../../../shared/validationSchema/validationSchema";
import { LogoImageReplaceIcon } from "../../../assets/icons/iconsProvider";
import LogoImage from "../../../assets/images/Rectangle31.png";
import { storeActions } from "../../../redux/actions";
import Topbar from "../../../shared/components/topbar/Topbar";
import {
  customToast,
  isSpinnerEnabled,
  resizeImageBlob,
} from "../../../shared/utility";
import SwitchButton from "../../../shared/components/SwitchButton/SwitchButton";

const ReceiptAndLabels = (props) => {
  const {
    store,
    uploadLogo,
    spinnerArray,
    updateStoreSettings,
    updateReceiptDetails,
  } = props;

  //-------store reducer
  const { currentStore } = store;

  //------Usestate
  const [storeLogoImage, setStoreLogoImage] = useState(
    currentStore?.logo || LogoImage
  );
  const [logoToSend, setLogoToSend] = useState(false);

  //------UseRef
  const hiddenFileInput = useRef(null);

  //-------initial state
  const initialValues = {
    logo: storeLogoImage,
    returnPolicy: currentStore?.returnPolicy || globalConstants.EMPTY_STRING,
    [toggleSwitchButtonConstants.SHOW_ON_LABEL]:
      currentStore?.[toggleSwitchButtonConstants.SHOW_ON_LABEL] || false,
    [toggleSwitchButtonConstants.SHOW_ON_RECEIPT]:
      currentStore?.[toggleSwitchButtonConstants.SHOW_ON_RECEIPT] || false,
    [toggleSwitchButtonConstants.IS_AUTO_PRINT_RECEIPT]:
      currentStore?.[toggleSwitchButtonConstants.IS_AUTO_PRINT_RECEIPT] ||
      false,
    [toggleSwitchButtonConstants.IS_SERIAL_NUMBER_REQUIRED_INVENTORY]:
      currentStore?.[
        toggleSwitchButtonConstants.IS_SERIAL_NUMBER_REQUIRED_INVENTORY
      ] || false,
    [toggleSwitchButtonConstants.IS_SALE_OUT_OF_STOCK]:
      currentStore?.[toggleSwitchButtonConstants.IS_SALE_OUT_OF_STOCK] || false,
    [toggleSwitchButtonConstants.IS_ID_REQUIRED_TRADE_IN]:
      currentStore?.[toggleSwitchButtonConstants.IS_ID_REQUIRED_TRADE_IN] ||
      false,
    [toggleSwitchButtonConstants.IS_ID_REQUIRED_TRADE_IN_STORE_CREDIT]:
      currentStore?.[
        toggleSwitchButtonConstants.IS_ID_REQUIRED_TRADE_IN_STORE_CREDIT
      ] || false,
    [toggleSwitchButtonConstants.APPLY_STORE_CREDIT_PAYMENTS_AS_PRE_TAX]:
      currentStore?.[
        toggleSwitchButtonConstants.APPLY_STORE_CREDIT_PAYMENTS_AS_PRE_TAX
      ] || false,
  };

  //-------Change Logo image button
  const handleReplaceButtonClick = () => {
    hiddenFileInput.current.click();
  };
  //------handle upload button
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg"
      ) {
        if (file) {
          const reader = new FileReader();

          reader.onload = (e) => {
            const img = new Image();
            img.src = e.target.result;

            img.onload = () => {
              // const width = img.width;
              // const maxWidth = 256;

              // if (width >= maxWidth) {
              //   customToast(toastMessages.LOGO_SIZE_ERROR, toastType.ERROR);
              //   // You can also reset the input or take other actions based on your application needs.
              // } else {
              setStoreLogoImage(URL.createObjectURL(file));
              setLogoToSend(file);
              // }
            };
          };
          reader.readAsDataURL(file);
        }
      } else {
        customToast(
          "Only .jpg, .jpeg and .png files are allowed",
          toastType.ERROR
        );
        e.target.value = null;
      }
    }
  };

  //------Handle submit
  const handleSubmit = async (data) => {
    if (logoToSend) {
      const formData = new FormData();
      const resizedImage = await resizeImageBlob(logoToSend, 200);
      formData.append("file", resizedImage);
      uploadLogo(currentStore?.id, formData, data);
    } else {
      updateReceiptDetails(currentStore?.id, {
        ...data,
        logo: currentStore?.logo,
      });
    }
  };

  //-------- handleSwitchButtonChange
  const handleSwitchButtonChange = (value, values, setFieldValue) => {
    setFieldValue(value, !values[value]);

    updateStoreSettings(currentStore?.id, {
      ...values,
      [value]: !values[value],
    });
  };

  const isSwitchDsiabled = (value) => {
    return isSpinnerEnabled(
      spinnerArray,
      storeConstants.UPDATE_RECEIPT_DETAILS
    );
  };

  useEffect(() => {
    if (currentStore) {
      setStoreLogoImage(currentStore?.logo || LogoImage);
      setLogoToSend(false);
    }
  }, [currentStore]);
  return (
    <>
      <Topbar title="Store Settings"></Topbar>{" "}
      <Formik
        initialValues={initialValues}
        validate={addReceiptLogoValidationSchema}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ resetForm, values, setFieldValue }) => (
          <Form>
            <Row className="m-0">
              <Col md={6}>
                <Row className="m-0">
                  <Col xs={12}>
                    <p className="receipt-label-heading">Inventory</p>
                  </Col>
                  <Col
                    md={12}
                    className="d-flex justify-content-between align-items-start"
                  >
                    <p className="store-settings-switch-button-labels">
                      Require Serial Numbers for Systems
                    </p>
                    <SwitchButton
                      onSwitchChange={(value) =>
                        handleSwitchButtonChange(value, values, setFieldValue)
                      }
                      id={
                        toggleSwitchButtonConstants.IS_SERIAL_NUMBER_REQUIRED_INVENTORY
                      }
                      isSwitchOn={
                        values[
                          toggleSwitchButtonConstants
                            .IS_SERIAL_NUMBER_REQUIRED_INVENTORY
                        ]
                      }
                      isDisabled={isSwitchDsiabled()}
                    />
                  </Col>
                  <Col xs={12}>
                    <p className="receipt-label-heading  mt-3">Trade-Ins</p>
                  </Col>
                  <Col
                    md={12}
                    className="d-flex justify-content-between align-items-start "
                  >
                    <p className="store-settings-switch-button-labels">
                      Require ID for Cash Trade-Ins
                    </p>
                    <SwitchButton
                      onSwitchChange={(value) =>
                        handleSwitchButtonChange(value, values, setFieldValue)
                      }
                      id={toggleSwitchButtonConstants.IS_ID_REQUIRED_TRADE_IN}
                      isSwitchOn={
                        values[
                          toggleSwitchButtonConstants.IS_ID_REQUIRED_TRADE_IN
                        ]
                      }
                      isDisabled={isSwitchDsiabled()}
                    />
                  </Col>
                  <Col
                    md={12}
                    className="d-flex justify-content-between align-items-start "
                  >
                    <p className="store-settings-switch-button-labels">
                      Require ID for Store Credit Trade-Ins
                    </p>
                    <SwitchButton
                      onSwitchChange={(value) =>
                        handleSwitchButtonChange(value, values, setFieldValue)
                      }
                      id={
                        toggleSwitchButtonConstants.IS_ID_REQUIRED_TRADE_IN_STORE_CREDIT
                      }
                      isSwitchOn={
                        values[
                          toggleSwitchButtonConstants
                            .IS_ID_REQUIRED_TRADE_IN_STORE_CREDIT
                        ]
                      }
                      isDisabled={isSwitchDsiabled()}
                    />
                  </Col>
                  <Col xs={12}>
                    <p className="receipt-label-heading mt-3">Selling</p>
                  </Col>
                  <Col
                    md={12}
                    className="d-flex justify-content-between align-items-start "
                  >
                    <p className="store-settings-switch-button-labels">
                      Allow Sale for Out-of-Stock Items
                    </p>
                    <SwitchButton
                      onSwitchChange={(value) =>
                        handleSwitchButtonChange(value, values, setFieldValue)
                      }
                      id={toggleSwitchButtonConstants.IS_SALE_OUT_OF_STOCK}
                      isSwitchOn={
                        values[toggleSwitchButtonConstants.IS_SALE_OUT_OF_STOCK]
                      }
                      isDisabled={isSwitchDsiabled()}
                    />
                  </Col>{" "}
                  <Col
                    md={12}
                    className="d-flex justify-content-between align-items-start "
                  >
                    <p className="store-settings-switch-button-labels">
                      Apply Store Credit Payments as Pre-Tax
                    </p>
                    <SwitchButton
                      onSwitchChange={(value) =>
                        handleSwitchButtonChange(value, values, setFieldValue)
                      }
                      id={
                        toggleSwitchButtonConstants.APPLY_STORE_CREDIT_PAYMENTS_AS_PRE_TAX
                      }
                      isSwitchOn={
                        values[
                          toggleSwitchButtonConstants
                            .APPLY_STORE_CREDIT_PAYMENTS_AS_PRE_TAX
                        ]
                      }
                      isDisabled={isSwitchDsiabled()}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row className="m-0">
                  <Col xs={12}>
                    <p className="receipt-label-heading">Store Logo</p>
                  </Col>
                  <Col md={12}>
                    <div className="receipt-logo-image-wrapper">
                      <img
                        src={values.logo}
                        alt="logo"
                        className="receipt-logo-image"
                      />
                      <input
                        type="file"
                        className="d-none"
                        accept=".png, .jpg, .jpeg"
                        onChange={handleImageChange}
                        ref={hiddenFileInput}
                      />
                      <button
                        onClick={handleReplaceButtonClick}
                        type="button"
                        className="replace-logo-button"
                      >
                        <LogoImageReplaceIcon className="replace-icon" />
                      </button>
                    </div>
                  </Col>
                  <Col
                    md={12}
                    className="d-flex justify-content-between align-items-start mt-4"
                  >
                    <p className="store-settings-switch-button-labels">
                      Show on Labels
                    </p>
                    <SwitchButton
                      onSwitchChange={(value) =>
                        handleSwitchButtonChange(value, values, setFieldValue)
                      }
                      id={toggleSwitchButtonConstants.SHOW_ON_LABEL}
                      isSwitchOn={
                        values[toggleSwitchButtonConstants.SHOW_ON_LABEL]
                      }
                      isDisabled={isSwitchDsiabled()}
                    />
                  </Col>
                  <Col
                    md={12}
                    className="d-flex justify-content-between align-items-start mt-2"
                  >
                    <p className="store-settings-switch-button-labels">
                      Show on Receipt
                    </p>
                    <SwitchButton
                      onSwitchChange={(value) =>
                        handleSwitchButtonChange(value, values, setFieldValue)
                      }
                      id={toggleSwitchButtonConstants.SHOW_ON_RECEIPT}
                      isSwitchOn={
                        values[toggleSwitchButtonConstants.SHOW_ON_RECEIPT]
                      }
                      isDisabled={isSwitchDsiabled()}
                    />
                  </Col>
                  <Col
                    md={12}
                    className="d-flex justify-content-between align-items-start mt-2"
                  >
                    <p className="store-settings-switch-button-labels">
                      Auto-Print Receipts
                    </p>
                    <SwitchButton
                      onSwitchChange={(value) =>
                        handleSwitchButtonChange(value, values, setFieldValue)
                      }
                      id={toggleSwitchButtonConstants.IS_AUTO_PRINT_RECEIPT}
                      isSwitchOn={
                        values[
                          toggleSwitchButtonConstants.IS_AUTO_PRINT_RECEIPT
                        ]
                      }
                      isDisabled={isSwitchDsiabled()}
                    />
                  </Col>
                  <Col xs={12} className="refund-heading-wrapper">
                    <p className="receipt-label-heading">
                      Refund Policy (Receipt)
                    </p>
                  </Col>
                  <Col md={12} className="mb-3">
                    <TextareaField
                      name="returnPolicy"
                      placeHolder="Refund Policy"
                    />
                  </Col>
                  <Col md={4}></Col>
                  <Col md={8} className="mb-3 d-flex gap-2">
                    <Button
                      type="button"
                      label={buttonNameConstants.CANCEL}
                      handleClick={() => {
                        resetForm();
                        setStoreLogoImage(currentStore?.logo || LogoImage);
                        setLogoToSend(false);
                      }}
                      buttonType={buttonTypeConstants.GHOST_BUTTON}
                      className="w-100"
                    ></Button>
                    <Button
                      type="submit"
                      className="w-100"
                      label={
                        currentStore?.logo
                          ? buttonNameConstants.UPDATE
                          : buttonNameConstants.SAVE
                      }
                    ></Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};
//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  spinnerArray: state.system.spinnerArray,
  store: state.store,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  uploadLogo: (id, logoPath, data) =>
    dispatch(storeActions.uploadLogo(id, logoPath, data)),
  updateReceiptDetails: (id, data) =>
    dispatch(storeActions.updateReceiptDetails(id, data)),
  updateStoreSettings: (id, data) =>
    dispatch(storeActions.updateStoreSettings(id, data)),
});

//-------Export Login Component
export default connect(mapStateToProps, mapDispatchToProps)(ReceiptAndLabels);

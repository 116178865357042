import React, { useEffect, useState, useRef, useMemo } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router";

import { customerActions } from "../../redux/actions";
import Button from "../../../src/shared/components/Buttons/Button";
import SearchField from "../../../src/shared/components/searchField/SearchField";
import CustomTooltip from "../../../src/shared/components/toolTipComponent/CustomTooltip";
import CustomCellRendererWithSkeleton from "../../shared/components/agGrid/CustomCellRendererWithSkeleton";
import Topbar from "../../../src/shared/components/topbar/Topbar";
import {
  buttonNameConstants,
  buttonTypeConstants,
  customerConstants,
  defaultGridState,
  EMPTY_STRING,
  inventoryConstants,
  ROLES,
  routesPathConstants,
  skeletonArray,
  systemConstants,
  toastMessages,
  toastType,
} from "../../constants";
import {
  AddIcon,
  EditIcon,
  DeleteIcon,
  ImportCSVIcon,
  ActionDotsIcons,
  ExportCSVIcon,
} from "../../assets/icons/iconsProvider";
import DataTableComponent from "../../../src/shared/components/table/ReactDataTableComponent";
import { globalConstants } from "../../constants";
import ImportCustomer from "./ImportCustomer/ImportCustomer";
import {
  createCompanyDynamicUrl,
  beautifyStringForImport,
  customToast,
  downloadCSV,
  getPaginationFilterParamsFromGridState,
  getRowId,
  getRowStyle,
  isButtonDisable,
  isSpinnerEnabled,
  LoadingIndicator,
  parseToThousandSeperatorDecimalNumber,
  stringToBase64,
  isEmployeeLogin,
} from "../../shared/utility";
import ServerSearchTable from "../../shared/components/agGrid/ServerSearchTable";
import DropdownSkeleton from "../../shared/Skeletons/DropdownSkeleton/DropdownSkeleton";
import ServerPaginationTable from "../../shared/components/agGrid/ServerPaginationTable";
import { customerService } from "../../services";

//-------Customer component start here
const Customer = (props) => {
  const {
    user,
    customer,
    allCustomers,
    currentStore,
    spinnerArray,
    deleteCustomer,
    getAllCustomer,
    duplicateCustomers,
    bulkUploadCustomers,
    emptyDuplicateRecords,
    getPaginationCustomers,
    customerPaginationData,
    setIsServerSearchLoading,
  } = props;
  const [defaultSortFieldId, setDefaultSortFieldId] = useState();
  const [gridState, setGridState] = useState(defaultGridState);
  const navigate = useNavigate();
  let numOfRenders = useRef(0);
  const gridRef = useRef();
  const [importCustomerModal, setImportCustomerModal] = useState(false);

  //-------Handle Customer edit click
  const handleEditClick = (row) => {
    navigate(createCompanyDynamicUrl(routesPathConstants.CUSTOMERS_EDIT), {
      state: {
        data: row,
        isEdit: true,
        currentStore: currentStore,
      },
    });
  };
  //-------Handle Customer delete click
  const handleDeleteClick = (row) => {
    if (row?.id) {
      deleteCustomer(row.id, currentStore?.companyId, currentStore?.id);
      // reset the pagination
      numOfRenders.current++;
    }
  };

  const toggleImportCustomerModal = () => {
    setImportCustomerModal(!importCustomerModal);
  };

  //-------Handle add button click
  const handleAddClick = () => {
    navigate(createCompanyDynamicUrl(routesPathConstants.CUSTOMERS_ADD), {
      state: {
        isEdit: false,
        currentStore: currentStore,
      },
    });
  };
  const CustomerColDefs = useMemo(
    () => [
      {
        headerName: "First Name",
        field: "firstName",
        cellRenderer: (params) => {
          return (
            <CustomCellRendererWithSkeleton
              params={params}
              cellComponent={
                <span className="users-cell-max-size">
                  {params?.data?.firstName}
                </span>
              }
            />
          );
        },
      },
      {
        headerName: "Last Name",
        field: "lastName",
        cellRenderer: (params) => (
          <CustomCellRendererWithSkeleton
            params={params}
            cellComponent={
              <span className="users-cell-max-size">
                {params?.data?.lastName}
              </span>
            }
          />
        ),
      },
      {
        headerName: "Email",
        field: "email",
        cellRenderer: (params) => (
          <CustomCellRendererWithSkeleton
            params={params}
            cellComponent={
              <span className="users-cell-max-size">{params?.data?.email}</span>
            }
          />
        ),
      },
      {
        headerName: "Mobile",
        field: "mobile",
        cellRenderer: (params) => (
          <CustomCellRendererWithSkeleton
            params={params}
            cellComponent={
              <span className="users-cell-max-size">
                {params?.data?.mobile}
              </span>
            }
          />
        ),
      },
      {
        headerName: "Customer Credit",
        field: "currentBalance",
        cellRenderer: (params) => (
          <CustomCellRendererWithSkeleton
            params={params}
            cellComponent={
              <span className="users-cell-max-size">
                $
                {parseToThousandSeperatorDecimalNumber(
                  params?.data?.currentBalance
                )}
              </span>
            }
          />
        ),
      },
      {
        maxWidth: 150,
        headerName: "",
        field: "id",
        cellStyle: {
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          overflow: "visible",
        },
        lockPosition: "right",
        sortable: false,
        cellRenderer: (params) => {
          return (
            <CustomCellRendererWithSkeleton
              params={params}
              cellComponent={
                <>
                  <CustomTooltip anchorSelect={`#tool${params?.data?.id}`}>
                    <Button
                      label={buttonNameConstants.EDIT}
                      className="w-100"
                      buttonType={buttonTypeConstants.ACTION_BUTTON}
                      IconImage={EditIcon}
                      handleClick={() => handleEditClick(params.data)}
                    />
                    <Button
                      label={buttonNameConstants.DELETE}
                      className="w-100"
                      buttonType={buttonTypeConstants.ACTION_BUTTON}
                      IconImage={DeleteIcon}
                      handleClick={() => handleDeleteClick(params.data)}
                    />
                  </CustomTooltip>
                  <button
                    id={`tool${params?.data?.id}`}
                    className="action-Button-Wrapper"
                  >
                    <ActionDotsIcons className="cursor-pointer" />
                  </button>
                </>
              }
            />
          );
        },
      },
    ],
    // eslint-disable-next-line
    []
  );
  // const [columns, setColumns] = useState([]);
  const gridData = useMemo(() => {
    // setIsServerSearchLoading(false);
    setGridState({
      ...gridState,
      pageIndex: customerPaginationData?.pageNumber,
      totalRecords: customerPaginationData?.totalRecords,
      pageEndRecordNumber: customerPaginationData?.pageEndRecordNumber,
      pageStartRecordNumber: customerPaginationData?.pageStartRecordNumber,
    });

    return allCustomers || [];
  }, [allCustomers]);

  // const onTableSort = (column, sortDirection) => {
  //   setColumns(
  //     columns.map((col) => {
  //       if (col.id === column.id) {
  //         return {
  //           ...col,
  //           sortDirection,
  //         };
  //       } else {
  //         return {
  //           ...col,
  //           sortDirection: null,
  //         };
  //       }
  //     })
  //   );

  //   setDefaultSortFieldId(column.id);
  // };

  const updateGridState = (gridState) => {
    // getPaginatedInventoryHistory(currentStore?.id, gridState);
    getPaginationCustomers(
      currentStore?.companyId,
      currentStore?.id,
      getPaginationFilterParamsFromGridState(gridState)
    );
    setGridState(gridState);
  };

  //-------useEffects
  useEffect(() => {
    if (currentStore) {
      // setColumns(customerColDefs);
      getPaginationCustomers(
        currentStore?.companyId,
        currentStore?.id,
        getPaginationFilterParamsFromGridState(gridState)
      );
    }
    // eslint-disable-next-line
  }, [currentStore]);

  //-------Handle Add button click
  const handleExport = async () => {
    try {
      const data = await customerService.getAllCustomer(
        currentStore?.companyId,
        currentStore?.id
      );

      downloadCSV(
        data.map((item) => ({
          "Customer ID": item.companyCustomerId,
          "First Name": item.firstName,
          "Last Name": item.lastName,
          Email: item.email,
          "Phone Number": item.mobile,
          "Id Type":
            item?.driverLicenseIdType || customerConstants.ID_TYPES[0].value,
          "Id Number": item.driverLicense,
          "Street 1": beautifyStringForImport(item.line1),
          "Street 2": beautifyStringForImport(item.line2),
          City: item.city,
          State: item.state,
          Zip: item.zip,
          Notes: item?.note
            ? beautifyStringForImport(item.note)
            : globalConstants.EMPTY_STRING,
          "Customer Credit": item.currentBalance,
        })),
        inventoryConstants.CUSTOMER_EXPORT_FILE_NAME
      );
    } catch (e) {
      console.log(e);
      customToast(toastMessages.SOMETHING_WENT_WRONG, toastType.ERROR);
    }
  };

  //-------Return Customers Component
  return (
    <>
      <Topbar
        countChip={true}
        totalCustomers={gridState?.totalRecords ?? 0}
        title="Customers"
      ></Topbar>
      {isSpinnerEnabled(spinnerArray, customerConstants.GET_ALL_CUSTOMERS) ? (
        <Row className="m-0 w-100 user-screen-wrapper">
          <Col md={5}>
            <DropdownSkeleton width="100%" />
          </Col>
          <Col md={3}></Col>
          <Col
            md={4}
            className="d-flex justify-content-end align-items-center gap-3"
          >
            <DropdownSkeleton />
            <DropdownSkeleton />
            <DropdownSkeleton />
          </Col>
        </Row>
      ) : (
        <Row className="m-0 w-100 user-screen-wrapper">
          <Col md={5}>
            <ServerSearchTable
              gridState={gridState}
              updateGridState={updateGridState}
              serverCallConstant={customerConstants.GET_PAGINATION_CUSTOMERS}
            />
          </Col>
          <Col md={2}></Col>
          <Col
            md={5}
            className="d-flex justify-content-end align-items-end gap-3"
          >
            <>
              {!isEmployeeLogin(user) && (
                <>
                  <Button
                    label={buttonNameConstants.EXPORT_CUSTOMER}
                    IconImage={ExportCSVIcon}
                    className="w-20"
                    buttonType={buttonTypeConstants.LIGHT_PURPLE}
                    handleClick={() => handleExport()}
                  />
                  <Button
                    label={buttonNameConstants.IMPORT_CUSTOMER}
                    IconImage={ImportCSVIcon}
                    className="w-20"
                    buttonType={buttonTypeConstants.LIGHT_PURPLE}
                    handleClick={() => toggleImportCustomerModal()}
                  />
                </>
              )}
            </>
            <Button
              label={buttonNameConstants.ADD}
              IconImage={AddIcon}
              className="w-20"
              handleClick={handleAddClick}
            />
          </Col>
        </Row>
      )}
      <Row className="m-0 w-100 h-100 user-screen-wrapper pt-3">
        {/* <DataTableComponent
          className={"customers-table"}
          key={numOfRenders.current}
          columns={columns}
          data={searchText ? handleFilterData() : allCustomers}
          onSort={onTableSort}
          defaultSortFieldId={defaultSortFieldId}
          gridState={gridState}
          setGridState={updateGridState}
          total={searchText ? handleFilterData()?.length : allCustomers?.length}
        /> */}
        {/* {JSON.stringify(gridData[0])} */}
        <ServerPaginationTable
          colDefs={CustomerColDefs}
          gridRef={gridRef}
          gridData={
            isSpinnerEnabled(
              spinnerArray,
              customerConstants.GET_PAGINATION_CUSTOMERS
            )
              ? skeletonArray
              : gridData
          }
          gridState={gridState}
          getRowId={getRowId}
          getRowStyle={getRowStyle}
          updateGridState={updateGridState}
          paginationPageSize={gridState.pageSize}
          pageSizeOptions={gridState.pageSizeOptions}
          isDisablePaginationActions={isSpinnerEnabled(
            spinnerArray,
            customerConstants.GET_PAGINATION_CUSTOMERS
          )}
          tableHeight={`calc(100vh - 220px)`}
        />
      </Row>
      <ImportCustomer
        currentStore={currentStore}
        duplicateCustomers={duplicateCustomers}
        importCustomerModal={importCustomerModal}
        bulkUploadCustomers={bulkUploadCustomers}
        emptyDuplicateRecords={emptyDuplicateRecords}
        toggleImportCustomerModal={toggleImportCustomerModal}
      />
    </>
  );
}; //-------Customers component end here

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  customer: state.customer,
  allCustomers: state.customer.allCustomers,
  customerPaginationData: state.customer.customerPaginationData,
  duplicateCustomers: state.customer.duplicateCustomers,
  currentStore: state.store.currentStore,
  spinnerArray: state.system.spinnerArray,
  company: state.authentication.company,
  user: state.authentication.user,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  deleteCustomer: (id, companyId, storeId) => {
    dispatch(customerActions.deleteCustomer(id, companyId, storeId));
  },
  bulkUploadCustomers: (companyId, storeId, importCustomerMisc) =>
    dispatch(
      customerActions.bulkUploadCustomers(
        companyId,
        storeId,
        importCustomerMisc
      )
    ),
  emptyDuplicateRecords: () =>
    dispatch(customerActions.emptyDuplicateRecords()),
  getAllCustomer: (companyId, storeId) =>
    dispatch(customerActions.getAllCustomer(companyId, storeId)),
  getPaginationCustomers: (companyId, storeId, paginationFilter) =>
    dispatch(
      customerActions.getPaginationCustomers(
        companyId,
        storeId,
        paginationFilter
      )
    ),
  setIsServerSearchLoading: (data) =>
    dispatch({ type: systemConstants.SET_IS_SERVER_SEARCH_LOADING, data }),
});

//-------Export AddNewUser Component
export default connect(mapStateToProps, mapDispatchToProps)(Customer);

import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";

import { storeActions } from "../../../redux/actions";
import Topbar from "../../../shared/components/topbar/Topbar";
import Button from "../../../shared/components/Buttons/Button";
import {
  createCompanyDynamicUrl,
  customToast,
  isSpinnerEnabled,
} from "../../../shared/utility";
import SearchField from "../../../shared/components/searchField/SearchField";
import TableCustomHeader from "../../../shared/components/table/TableCustomHeader";
import CustomTooltip from "../../../shared/components/toolTipComponent/CustomTooltip";
import ConfirmationModal from "../../Inventory/Items/ConfirmationModal/ConfirmationModal";
import DataTableComponent from "../../../shared/components/table/ReactDataTableComponent";
import DropdownSkeleton from "../../../shared/Skeletons/DropdownSkeleton/DropdownSkeleton";
import {
  storeConstants,
  globalConstants,
  routesPathConstants,
  buttonTypeConstants,
  buttonNameConstants,
} from "../../../constants";
import {
  AddIcon,
  EditIcon,
  CrossIcon,
  DeleteIcon,
  SearchIcon,
  BlueBoxIcon,
  ActionDotsIcons,
} from "../../../assets/icons/iconsProvider";

const defaultGridState = {
  pageSize: 50,
  pageIndex: 0,
  sortBy: [],
};
const Stores = (props) => {
  const { stores, getStores, spinnerArray } = props;
  const navigate = useNavigate();
  const [defaultSortFieldId, setDefaultSortFieldId] = useState();
  const [gridState, setGridState] = useState(defaultGridState);
  const [searchText, setSearchText] = useState("");
  let numOfRenders = useRef(0);
  let [storeToBeDeleted, setStoreToBeDeleted] = useState({
    isModalOpen: false,
    rowData: {},
  });
  //-------User reducer

  const handleEditClick = (row) => {
    navigate(createCompanyDynamicUrl(routesPathConstants.ADMIN_STORES_EDIT), {
      state: { ...row },
    });
  };

  const handleDeleteClick = (row) => {
    setStoreToBeDeleted({ isModalOpen: true, rowData: row });
    // props.deleteStore(row.id);
    // reset pagination
    numOfRenders.current++;
  };

  const [columns, setColumns] = useState([
    {
      id: "storeName",
      name: (
        <TableCustomHeader
          id="storeName"
          label="Store..."
          tooltopText="Store Name"
        />
      ),
      cell: (row) => (
        <span className="store-cell-max-size">{row.storeName}</span>
      ),
      selector: (row) => row.storeName,
      sortable: true,
      sortDirection: null,
    },
    {
      id: "state",
      name: "State",
      selector: (row) => row.state,
      cell: (row) => <span className="store-cell-max-size">{row.state}</span>,
      sortable: true,
      sortDirection: null,
    },
    {
      id: "city",
      name: "City",
      selector: (row) => row.city,
      cell: (row) => <span className="store-cell-max-size">{row.city}</span>,
      sortable: true,
      sortDirection: null,
    },
    {
      id: "defaultTax",
      name: (
        <TableCustomHeader
          id="defaultTax"
          label="Default..."
          tooltopText="Default Tax"
        />
      ),
      selector: (row) => row.defaultTax,
      cell: (row) => (
        <span className="store-cell-max-size">{row.defaultTax} %</span>
      ),
      sortable: true,
      sortDirection: null,
    },
    {
      id: "phoneNumber",
      name: (
        <TableCustomHeader
          id="phoneNumber"
          label="Phone..."
          tooltopText="Phone Number"
        />
      ),
      selector: (row) => row.phoneNumber,
      cell: (row) => (
        <span className="store-cell-max-size">{row.phoneNumber}</span>
      ),
      sortable: true,
      sortDirection: null,
    },
    {
      id: "returnPolicy",
      name: (
        <TableCustomHeader
          id="returnPolicy"
          label="Return..."
          tooltopText="Return Policy"
        />
      ),
      selector: (row) => row.returnPolicy,
      cell: (row) => (
        <span className="store-cell-max-size">{row.returnPolicy}</span>
      ),
      sortable: true,
      sortDirection: null,
    },
    {
      id: "zip",
      name: "ZIP",
      selector: (row) => row?.zip ?? globalConstants.EMPTY_STRING,
      cell: (row) => <span className="store-cell-max-size">{row.zip}</span>,
      sortable: true,
      sortDirection: null,
      width: "10%",
    },
    {
      id: "logo",
      name: "Logo",
      cell: (row) =>
        row?.logo ? (
          <img src={row?.logo} className="logo-image-table" alt="logo" />
        ) : (
          "No Logo"
        ),
      sortable: true,
      sortDirection: null,
    },
    {
      id: "line1",
      name: "Address",
      selector: (row) => row.line1,
      cell: (row) => <span className="store-cell-max-size">{row.line1}</span>,
      sortable: true,
      sortDirection: null,
    },
    {
      id: "status",
      name: "Status",
      selector: (row) => row.isStoreActive,
      cell: (row) => (
        <span className="store-cell-max-size">
          {row?.isStoreActive ? storeConstants.ACTIVE : storeConstants.INACTIVE}
        </span>
      ),
      sortable: true,
      sortDirection: null,
    },
    {
      id: "action",
      name: "",
      cell: (row) => {
        return (
          <>
            <CustomTooltip anchorSelect={`#tool${row.id}`}>
              <Button
                label={buttonNameConstants.EDIT}
                className="w-100"
                buttonType={buttonTypeConstants.ACTION_BUTTON}
                IconImage={EditIcon}
                handleClick={() => handleEditClick(row)}
              />
              <Button
                label={buttonNameConstants.DELETE}
                className="w-100"
                buttonType={buttonTypeConstants.ACTION_BUTTON}
                IconImage={DeleteIcon}
                handleClick={() => handleDeleteClick(row)}
              />
            </CustomTooltip>
            <button id={`tool${row.id}`} className="action-Button-Wrapper">
              <ActionDotsIcons className="cursor-pointer" />
            </button>
          </>
        );
      },
      sortable: false,
      width: "5%",
    },
  ]);
  const onTableSort = (column, sortDirection) => {
    setColumns(
      columns.map((col) => {
        if (col.id === column.id) {
          return {
            ...col,
            sortDirection,
          };
        } else {
          return {
            ...col,
            sortDirection: null,
          };
        }
      })
    );

    setDefaultSortFieldId(column.id);
  };
  const updateGridState = (newGridState) => {
    setGridState(newGridState);
  };
  //-------Handle searchfield change
  const handleFilterData = (e) => {
    const filterList = stores.filter((row) => {
      return (
        String(row.storeName + row.phoneNumber + row.city + row.zip)
          .toLowerCase()
          .includes(String(searchText).toLowerCase()) &&
        searchText !== globalConstants.EMPTY_STRING
      );
    });
    numOfRenders.current++;
    return filterList;
  };
  const clearSearchText = () => {
    setSearchText("");
  };
  useEffect(() => {
    getStores();
    // eslint-disable-next-line
  }, []);
  //-------Return Users Component
  return (
    <>
      <Topbar title="Stores"></Topbar>
      <Row className="m-0 w-100 px-0 user-screen-wrapper">
        {isSpinnerEnabled(spinnerArray, storeConstants.GET_STORES) ? (
          <>
            <Col md={5}>
              <DropdownSkeleton width={"100%"} />
            </Col>
            <Col md={3}></Col>
            <Col
              md={4}
              className="d-flex justify-content-end align-items-center  gap-3"
            >
              <DropdownSkeleton />
            </Col>
          </>
        ) : (
          <>
            <Col md={5}>
              <SearchField
                onChange={(e) => setSearchText(e.target.value)}
                IconImage={SearchIcon}
                label="Search"
                placeHolder="Type or Scan"
                value={searchText}
                CrossIcon={searchText ? CrossIcon : ""}
                clearSearchText={clearSearchText}
                BlueBoxIcon={BlueBoxIcon}
              />
            </Col>
            <Col md={3}></Col>
            <Col
              md={4}
              className="d-flex justify-content-end align-items-end  gap-3"
            >
              <Button
                label={buttonNameConstants.ADD}
                IconImage={AddIcon}
                handleClick={() =>
                  navigate(
                    createCompanyDynamicUrl(
                      routesPathConstants.ADMIN_STORES_ADD
                    )
                  )
                }
              />
            </Col>
          </>
        )}
      </Row>
      <Row className="m-0 w-100 h-100 user-screen-wrapper pt-3">
        {/* <ReactDataTable columnHeaders={columnHeaders} data={stores} /> */}
        <DataTableComponent
          className="customers-table"
          key={numOfRenders.current}
          columns={columns}
          data={searchText ? handleFilterData() : [...stores].reverse()}
          onSort={onTableSort}
          defaultSortFieldId={defaultSortFieldId}
          gridState={gridState}
          setGridState={updateGridState}
          total={searchText ? handleFilterData().length : stores?.length}
        />
      </Row>
      <ConfirmationModal
        isConfirmationModal={storeToBeDeleted.isModalOpen}
        handleNoButtonClick={() => {
          setStoreToBeDeleted({ ...storeToBeDeleted, isModalOpen: false });
        }}
        handleYesButtonClick={() => {
          setStoreToBeDeleted({ ...storeToBeDeleted, isModalOpen: false });
          props.deleteStore(storeToBeDeleted.rowData.id);
        }}
        message={
          <p className="px-2">
            Are you sure you want to permanently delete "
            {storeToBeDeleted.rowData?.storeName}" store and all of its data ?
            <br /> All of the inventory/transaction data and users/store-owners
            who only have access to this store will be deleted, along with all
            other store data.
          </p>
        }
      />
    </>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  stores: state.store.stores,
  spinnerArray: state.system.spinnerArray,
  company: state.authentication.company,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getStores: () => dispatch(storeActions.getStores()),
  deleteStore: (storeId) => dispatch(storeActions.deleteStore(storeId)),
});

//-------Export AddNewUser Component
export default connect(mapStateToProps, mapDispatchToProps)(Stores);

import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
} from "@tanstack/react-table";
import {
  AsendingSortingIcon,
  DefaultSortingIcon,
  DesendingSortingIcon,
} from "../../../assets/icons/iconsProvider";
import { EMPTY_STRING } from "../../../constants";

const InfiniteScrollTable = ({
  columns,
  data,
  update,
  hasMore,
  isLoading,
  handleRowClick = false,
  endMessage = EMPTY_STRING,
}) => {
  const table = useReactTable({
    data,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const { getHeaderGroups, getRowModel } = table;
  const ScrollLoadingConponent = () => {
    return (
      <div className="w-100 py-2 d-flex justify-content-center align-items-center">
        Loading...
      </div>
    );
  };
  // Render the UI for your table

  return (
    <InfiniteScroll
      dataLength={data?.length}
      next={update}
      hasMore={hasMore}
      endMessage={endMessage}
      loader={isLoading ? <ScrollLoadingConponent /> : ""}
      scrollableTarget={"scrollableDiv"}
    >
      <div
        className="react-data-table-wrapper scrollableWrapper"
        id="scrollableDiv"
      >
        <table className="react-data-table">
          <thead>
            {getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} className="react-table-th-con">
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort() ? "" : "",
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                        className="react-table-header-con"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {/* {{
                          asc: (
                            <AsendingSortingIcon className="ms-1 cursor-pointer" />
                          ),
                          desc: (
                            <DesendingSortingIcon className="ms-1 cursor-pointer" />
                          ),
                        }[header.column.getIsSorted()] ?? (
                          <span className="ms-1">
                            {header.id === "action" || (
                              <DefaultSortingIcon className="cursor-pointer" />
                            )}
                          </span>
                        )} */}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="react-data-table-body ">
            {getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                className={`infinite-scroll-table-row ${
                  handleRowClick ? "cursor-pointer" : ""
                }`}
                onClick={() => {
                  if (handleRowClick) {
                    handleRowClick(row.original);
                  }
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className="react-table-td-con">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </InfiniteScroll>
  );
};

export default InfiniteScrollTable;

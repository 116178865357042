import Select from "react-select";
import React, { useState } from "react";

import { inventoryModalPricesStatsSelectFieldStyle } from "../../../assets/scss/style";
import { getNetworkAvgBuySellPriceAccordingToSelectedRange } from "../../utility";
import {
  FALSE_STRING,
  NETWORK_AVERAGE_PRICE_RANGES,
  NETWORK_AVERAGE_PRICE_TYPES,
} from "../../../constants";

const NetworkAveragePriceSelect = (props) => {
  const {
    isModalChild = true,
    menuPlacement = "auto",
    networkAveragePriceType,
    networkAverageBuySellPrices,
  } = props;
  const heading = `Network Average ${
    NETWORK_AVERAGE_PRICE_TYPES.SELL_PRICE === networkAveragePriceType
      ? "Sell"
      : "Buy"
  } Price`;
  const [selectedPriceRange, setSelectedPriceRange] = useState(
    NETWORK_AVERAGE_PRICE_RANGES[0]
  );

  // helper functions
  const handlePriceRangeOnChange = (option) => {
    setSelectedPriceRange(option);
  };
  const NetworkAverageSelectWithPrice = (props) => {
    return (
      <div className="mt-2 d-flex flex-row align-items-center justify-content-between ">
        <Select
          id={networkAveragePriceType}
          name={networkAveragePriceType}
          options={NETWORK_AVERAGE_PRICE_RANGES}
          value={selectedPriceRange}
          isSearchable={false}
          placeholder={"Network Average..."}
          menuPlacement={menuPlacement}
          styles={inventoryModalPricesStatsSelectFieldStyle}
          onChange={(e) => {
            handlePriceRangeOnChange(e, networkAveragePriceType);
          }}
        />
        <span>
          $
          {getNetworkAvgBuySellPriceAccordingToSelectedRange(
            selectedPriceRange.label,
            networkAveragePriceType,
            networkAverageBuySellPrices
          )}
        </span>
      </div>
    );
  };

  return (
    process.env.REACT_APP_IS_PRICECHARTING === FALSE_STRING &&
    (isModalChild ? (
      <section className="multi-price-stat-con ">
        <span className="price-stat-heading">{heading}</span>
        <NetworkAverageSelectWithPrice />
      </section>
    ) : (
      <div className="detail-div flex-column">
        <p>{heading}</p>
        <NetworkAverageSelectWithPrice />
      </div>
    ))
  );
};

export default NetworkAveragePriceSelect;

import { PrintIcon, ShareIcon } from "../assets/icons/iconsProvider";
import { globalConstants } from "./global.constant";

export const transactionConstants = {
  CASH: "cash",
  SALE: "Sale",
  BATCH: "Batch",
  TRADE: "Trade",
  DRAFT: "Draft",
  TEMP_SKU: "TEMP_SKU",
  PARK_SALE: "pending",
  GIFT_CARD: "giftCard",
  INVENTORY: "Inventory",
  PROCESSED: "Processed",
  ADD_RETURN: "ADD_RETURN",
  GC_BARCODE_MIN_LENGTH: 5,
  CREDIT_CARD: "creditCard",
  CUSTOM_SALE: "Custom Sale",
  GIFT_CARD_ITEM: "Gift Card",
  STORE_CREDIT: "storeCredit",
  GET_TRADE_ID: "GET_TRADE_ID",
  CASH_PAYMENT: "CASH_PAYMENT",
  BATCH_TRADE: "Batch Trade-In",
  QUICK_TRADE: "Quick Trade-In",
  MISCELLANEOUS: "Miscellaneous",
  GET_ALL_DRAFT: "GET_ALL_DRAFT",
  SPLIT_PAYMENT: "SPLIT_PAYMENT",
  GET_RETURN_ID: "GET_RETURN_ID",
  CUSTOM_DEFAULT_TITLE: "Custom",
  DELETED_DRAFT: "DELETED_DRAFT",
  AUTO_GENERATED: "Auto Generated",
  EDIT_COGS_MODAL: "EDIT_COGS_MODAL",
  ADD_CUSTOM_ITEM: "ADD_CUSTOM_ITEM",
  TRADE_BATCH_DATA: "TRADE_BATCH_DATA",
  MAKE_TRANSACTION: "MAKE_TRANSACTION",
  PAYMENT_COMPLETE: "PAYMENT_COMPLETE",
  SEND_EMAIL_OR_SMS: "SEND_EMAIL_OR_SMS",
  TRADE_DRAFT_MODAL: "TRADE_DRAFT_MODAL",
  GIFT_CARD_BARCODE: "GIFT_CARD_BARCODE",
  GIFT_CARD_PAYMENT: "GIFT_CARD_PAYMENT",
  CANCEL_TRANSACTION: "CANCEL_TRANSACTION",
  GET_TRANSACTION_ID: "GET_TRANSACTION_ID",
  NEW_SALE_PAGE_DATA: "NEW_SALE_PAGE_DATA",
  SALE_ACTIVITY_LOGS: "SALE_ACTIVITY_LOGS",
  SET_NEW_TRADE_DATA: "SET_NEW_TRADE_DATA",
  QUICK_TRADE_PRODUCT: "QUICK_TRADE_PRODUCT",
  MISCELLANEOUS_SALE_ITEM: "Miscellaneous.",
  NEW_TRADE_PAGE_DATA: "NEW_TRADE_PAGE_DATA",
  TRADE_ACTIVITY_LOGS: "TRADE_ACTIVITY_LOGS",
  BULK_UPLOAD_HISTORY: "BULK_UPLOAD_HISTORY",
  GET_ALL_TRANSACTION: "GET_ALL_TRANSACTION",
  CREDIT_CARD_PAYMENT: "CREDIT_CARD_PAYMENT",
  CUSTOM_TRADE_PRODUCT: "CUSTOM_TRADE_PRODUCT",
  ADD_TRADE_BATCH_ITEM: "ADD_TRADE_BATCH_ITEM",
  STORE_CREDIT_PAYMENT: "STORE_CREDIT_PAYMENT",
  SET_TRANSACTION_DATA: "SET_TRANSACTION_DATA",
  PRICECHARTING_PRODUCT: "PRICECHARTING_PRODUCT",
  FIXED_AMOUNT_BUTTONS: [1, 5, 10, 20, 50, 100],
  GET_GIFT_CARD_DETAILS: "GET_GIFT_CARD_DETAILS",
  EDIT_CASH_OFFER_MODAL: "EDIT_CASH_OFFER_MODAL",
  EDIT_TRADE_OFFER_MODAL: "EDIT_TRADE_OFFER_MODAL",
  MAKE_TRADE_TRANSACTION: "MAKE_TRADE_TRANSACTION",
  GET_SEARCH_TRANSACTION: "GET_SEARCH_TRANSACTION",
  SET_DRAFT_CONFIRMATION: "SET_DRAFT_CONFIRMATION",
  SET_RETURN_TRANSACTION: "SET_RETURN_TRANSACTION",
  UPDATE_HISTORY_CUSTOMER: "UPDATE_HISTORY_CUSTOMER",
  TRANSACTION_DRAFT_MODAL: "TRANSACTION_DRAFT_MODAL",
  NO_GIFT_CARD_IS_SELECTED: "No Gift Card is Selected",
  SALE_TRADE_ACTIVITY_LOGS: "SALE_TRADE_ACTIVITY_LOGS",
  TOGGLE_ADD_CUSTOMER_MODAL: "TOGGLE_ADD_CUSTOMER_MODAL",
  ADD_BATCH_TRADE_DRAFT_MODAL: "ADD_BATCH_TRADE_DRAFT_MODAL",
  SET_IS_TRADE_BATCH_INVENTORY: "SET_IS_TRADE_BATCH_INVENTORY",
  SET_ADD_TRADE_BATCH_INVENTORY: "SET_ADD_TRADE_BATCH_INVENTORY",
  ADD_BATCH_INVENTORY_DRAFT_MODAL: "ADD_BATCH_INVENTORY_DRAFT_MODAL",
  GC_BARCODE_LENGTH_ERROR: "The barcode must consist of at least 5 characters.",
  SET_IS_TRANSFER_CUSTOMER_CREDIT_MODAL:
    "SET_IS_TRANSFER_CUSTOMER_CREDIT_MODAL",

  RECEIPT_OPTIONS: [
    {
      value: "print",
      label: (
        <div>
          <PrintIcon /> Print
        </div>
      ),
    },
    {
      value: "send",
      label: (
        <div>
          <ShareIcon /> Send
        </div>
      ),
    },
  ],

  EDIT_BATCH_CASH_OFFER_OPTIONS: [
    {
      value: 0,
      label: "Edit Total Cash Offer",
    },
    {
      value: 1,
      label: "Set Flat Rate Cash Offer",
    },
  ],
  EDIT_BATCH_TRADE_OFFER_OPTIONS: [
    {
      value: 0,
      label: "Edit Total Trade Offer",
    },
    {
      value: 1,
      label: "Set Flat Rate Trade Offer",
    },
  ],
  EDIT_COGS_OPTIONS: [
    {
      value: 0,
      label: "Edit Total Cost",
    },
    {
      value: 1,
      label: "Set Flat Rate COGS",
    },
  ],
};

export const tradeTransactionDefaultData = {
  inventory: [],
  note: globalConstants.EMPTY_STRING,
  tax: 0,
  SubTotal: 0,
  creditUsed: 0,
  taxPercentage: 0,
  discountedValue: 0,
  currentAmountToPay: 0,
  isNoteField: false,
  isTaxApplied: true,
  totalAmountToPay: 0,
  amountToPay: 0,
  discountMethod: false,
  isDiscountField: false,
  id: globalConstants.EMPTY_STRING,
  store: globalConstants.EMPTY_STRING,
  poiId: globalConstants.EMPTY_STRING,
  customer: globalConstants.EMPTY_STRING,
  PaymentType: globalConstants.CASH,
  paymentMethod: globalConstants.EMPTY_STRING,
  TransactionType: transactionConstants.DRAFT,
  paymentHistory: {
    totalPaidAmount: {
      [transactionConstants.CASH]: 0,
      [transactionConstants.CREDIT_CARD]: 0,
      [transactionConstants.GIFT_CARD]: 0,
      [transactionConstants.STORE_CREDIT]: 0,
    },
  },
  totalCashOffer: globalConstants.EMPTY_STRING,
  totalTradeOffer: globalConstants.EMPTY_STRING,
  isConsent: false,
};

export const allTradeConstants = {
  inventory: [],
  note: globalConstants.EMPTY_STRING,
  tax: 0,
  SubTotal: 0,
  creditUsed: 0,
  taxPercentage: 0,
  discountedValue: 0,
  currentAmountToPay: 0,
  isNoteField: false,
  isTaxApplied: true,
  totalAmountToPay: 0,
  amountToPay: 0,
  discountMethod: false,
  isDiscountField: false,
  id: globalConstants.EMPTY_STRING,
  store: globalConstants.EMPTY_STRING,
  poiId: globalConstants.EMPTY_STRING,
  customer: globalConstants.EMPTY_STRING,
  PaymentType: globalConstants.CASH,
  paymentMethod: globalConstants.EMPTY_STRING,
  TransactionType: transactionConstants.DRAFT,
  paymentHistory: {
    [transactionConstants.CASH]: 0,
    [transactionConstants.CREDIT_CARD]: 0,
    [transactionConstants.GIFT_CARD]: 0,
    [transactionConstants.STORE_CREDIT]: 0,
  },
  totalCashOffer: globalConstants.EMPTY_STRING,
  totalTradeOffer: globalConstants.EMPTY_STRING,
  isConsent: false,
};

export const transactionDefaultData = {
  tax: 0,
  discount: 0,
  subTotal: 0,
  inventory: [],
  creditUsed: 0,
  creditCard: {},
  taxPercentage: 0,
  cashChangeOwed: 0,
  discountedValue: 0,
  isNoteField: false,
  isTaxApplied: true,
  totalAmountToPay: 0,
  currentAmountToPay: 0,
  discountMethod: false,
  isDiscountField: false,
  cashTenderedAmount: 0,
  id: globalConstants.EMPTY_STRING,
  note: globalConstants.EMPTY_STRING,
  store: globalConstants.EMPTY_STRING,
  poiId: globalConstants.EMPTY_STRING,
  customer: globalConstants.EMPTY_STRING,
  PaymentType: transactionConstants.CASH,
  paymentDevice: globalConstants.EMPTY_STRING,
  paymentMethod: globalConstants.EMPTY_STRING,
  TransactionType: transactionConstants.DRAFT,
  paymentHistory: {
    totalPaidAmount: {
      [transactionConstants.CASH]: 0,
      [transactionConstants.CREDIT_CARD]: 0,
      [transactionConstants.GIFT_CARD]: 0,
      [transactionConstants.STORE_CREDIT]: 0,
    },
  },
};

export const TRADE_BATCH_DEFAULT_DATA = {
  id: 0,
  tags: [],
  skuNumber: [],
  maxBuyPrice: 0,
  marketPrice: 0,
  costOfGoods: 0,
  inStorePrice: 0,
  inStockPrice: 0,
  averageBuyPrice: 0,
  inStockQuantity: 1,
  isItemLocked: false,
  averageSellPrice: 0,
  gameStopBuyPrice: 0,
  suggestedSellPrice: 0,
  isDataFetching: false,
  cashPercentagePerItem: 0,
  tradePercentagePerItem: 0,
  upc: globalConstants.EMPTY_STRING,
  epid: globalConstants.EMPTY_STRING,
  genre: globalConstants.EMPTY_STRING,
  imgUrl: globalConstants.EMPTY_STRING,
  productId: globalConstants.EMPTY_STRING,
  productName: globalConstants.EMPTY_STRING,
  consoleName: globalConstants.EMPTY_STRING,
  tcgPlayerUrl: globalConstants.EMPTY_STRING,
};

export const TRADE_PRODUCT_DEFAULT_DATA_OBJECT = {
  skuNumber: [],
  cardNumber: 0,
  marketPrice: 0,
  maxBuyPrice: 0,
  costOfGoods: 0,
  inStorePrice: 0,
  inStockPrice: 0,
  totalUnitsSold: 0,
  averageBuyPrice: 0,
  inStockQuantity: 0,
  gameStopBuyPrice: 0,
  averageSellPrice: 0,
  suggestedSellPrice: 0,
  upc: globalConstants.EMPTY_STRING,
  epid: globalConstants.EMPTY_STRING,
  genre: globalConstants.EMPTY_STRING,
  imgUrl: globalConstants.EMPTY_STRING,
  productId: globalConstants.EMPTY_STRING,
  productName: globalConstants.EMPTY_STRING,
  consoleName: globalConstants.EMPTY_STRING,
  serialNumber: globalConstants.EMPTY_STRING,
  tcgPlayerUrl: globalConstants.EMPTY_STRING,
};

export const SALE_TRANSACTION_ACTIVITY_TYPES = {
  ADD_ITEM: "Add Item",
  UPDATE_TAX: "Update Tax",
  DELETE_ITEM: "Delete Item",
  ADD_CUSTOMER: "Add Customer",
  RETURN_PAYMENT: "Return payment",
  APPLY_DISCOUNT: "Apply Discount",
  REMOVE_DISCOUNT: "Remove Discount",
  UPDATE_CUSTOMER: "Update Customer",
  DELETE_CUSTOMER: "Delete Customer",
  RESET_CART_ITEMS: "Reset cart items",
  SET_DEFAULT_DATA: "Set default data",
  ITEM_PRICE_CHANGE: "Item price change",
  RESET_TRANSACTION: "Reset Transaction",
  TRIGGER_NOTE_FIELD: "Trigger Note Field",
  AMOUNT_CALCULATION: "Amount Calculation",
  TRIGGER_TAX_BUTTON: "Trigger Tax Button",
  CHANGE_PAYMENT_TYPE: "Change payment type",
  PAYMENT_BUTTON_CLICK: "Payment Button Click",
  CHANGE_AMOUNT_TO_PAY: "Change amount to pay",
  CHANGE_ITEM_QUANTITY: "Change Item Quantity",
  UPDATE_PAYMENT_METHOD: "Update Payment Method",
  MOVED_TO_PAYEMNT_PAGE: "Moved To Payment Page",
  UPDATE_CASH_TRADE_OFFER: "Update Cash Trade Offer",
  APPLY_SINGLE_ITEM_DISCOUNT: "Apply Single Item Discount",
  SET_VERIFONE_PAYMENT_DEVICE: "Set Verifone Payment Device",
  AFTER_SUCCESSFULL_TRANSACTION: "After Successfull Transaction",
  CHANGE_CASH_TRADE_OFFER_VALUE: "Change cash or trade offer value",
};

import { Form, Formik } from "formik";
import { connect } from "react-redux";
import { Modal, Row, Col } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import Button from "../../../../shared/components/Buttons/Button";
import { DollarIcon } from "../../../../assets/icons/iconsProvider";
import ProductTagList from "../../../../shared/components/tags/ProductTagList";
import CheckboxField from "../../../../shared/components/checkBox/CheckboxField";
import InputTextArea from "../../../../shared/components/inputTextArea/InputTextArea";
import IconSelectField from "../../../../shared/components/selectField/IconSelectField";
import InputTextField from "../../../../shared/components/inputTextField/InputTextField";
import InputNumberField from "../../../../shared/components/inputNumberField/InputNumberField";
import ConsolidationModal from "../../../Inventory/Items/ConfirmationModal/ConsolidationModal";
import { addTradeCustomItemValidationSchema } from "../../../../shared/validationSchema/validationSchema";
import {
  formatDate,
  handleCashOfferValues,
  handleTradeOfferValues,
  getMarginDropdownValue,
  getTypeOrCategoryObject,
  getCustomTypesOptionList,
  getCustomCategoriesOptionList,
  getCustomSubCategoriesOptionList,
  getTypeOrCategoryIdByName,
} from "../../../../shared/utility";
import {
  EMPTY_STRING,
  globalConstants,
  inventoryConstants,
  buttonNameConstants,
  buttonTypeConstants,
  transactionConstants,
} from "../../../../constants";
import {
  systemActions,
  inventoryActions,
  transactionActions,
} from "../../../../redux/actions";
import {
  PRODUCT_TYPES_ENUMS,
  PRODUCT_CONDITIONS_ENUMS,
} from "../../../../system/globalEnums";

const AddTradeCustomItem = (props) => {
  const {
    inventory,
    transaction,
    currentStore,
    newTradeData,
    tradeDiscount,
    productToEdit,
    addItemInCart,
    handleTradeData,
    customItemModal,
    generateTempSku,
    itemOrganization,
    toggleCustomItemModal,
    getInventoryByProductMetaDataAndConsolidation,
  } = props;

  const { productTags } = inventory;

  const { tempSku } = transaction;

  const isProductEdit = productToEdit?.id ? true : false;

  //-------Reducer
  const { tradeinMargin } = tradeDiscount;
  const { customTypes, customCategories, customSubCategories } =
    itemOrganization;

  const [marginObject, setMarginObject] = useState(tradeinMargin?.globalMargin);
  const [ratio, setRatio] = useState(
    tradeinMargin?.globalMargin?.tradeinMarginPercentage /
      tradeinMargin?.globalMargin?.cashMarginPercentage
  );
  const [customProductSelectedTags, setCustomProductSelectedTags] = useState(
    isProductEdit
      ? productToEdit?.tags?.filter(
          (tag) => tag !== inventoryConstants.CUSTOM_ITEM_TAG
        )
      : []
  );

  //-------onselectedtagchange
  const onCustomProductSelectTagsChange = (selectedTags) => {
    const selectedTagList = selectedTags.map((tag) => tag.label);
    setCustomProductSelectedTags(selectedTagList);
  };
  //-------initial values for custom item
  const initialValues = {
    upc: isProductEdit ? productToEdit?.upc : globalConstants.EMPTY_STRING,
    quantity: isProductEdit ? productToEdit?.price?.quantity : 1,
    cashOffer: isProductEdit
      ? productToEdit?.cashOffer
      : globalConstants.EMPTY_STRING,
    condition: isProductEdit
      ? productToEdit?.price?.type
      : globalConstants.EMPTY_STRING,
    tradeOffer: isProductEdit
      ? productToEdit?.tradeOffer
      : globalConstants.EMPTY_STRING,
    subcategory: isProductEdit
      ? getTypeOrCategoryIdByName(
          customSubCategories,
          productToEdit.subcategory,
          productToEdit?.productType,
          productToEdit.category_name
        )
      : globalConstants.EMPTY_STRING,
    description: isProductEdit
      ? productToEdit?.description
      : globalConstants.EMPTY_STRING,
    productType: isProductEdit
      ? getTypeOrCategoryIdByName(customTypes, productToEdit?.productType)
      : globalConstants.EMPTY_STRING,
    product_name: isProductEdit
      ? productToEdit?.product_name
      : globalConstants.EMPTY_STRING,
    inStockPrice: isProductEdit
      ? productToEdit?.price?.unit_sell_price
      : globalConstants.EMPTY_STRING,
    category_name: isProductEdit
      ? getTypeOrCategoryIdByName(
          customCategories,
          productToEdit.category_name,
          productToEdit?.productType
        )
      : globalConstants.EMPTY_STRING,
    trackQuantity: isProductEdit ? productToEdit.trackQuantity : true,
  };

  //-------handle hide modal
  const handleHideModal = () => {
    toggleCustomItemModal();
  };

  const handleCustomItemSubmit = async (values) => {
    generateTempSku();
    const date_added = formatDate(new Date());
    // const skuNumber = await getSkuNumber();
    const { inStockPrice, quantity, ...otherValues } = values;
    let miscellaneousItem = {
      ...otherValues,
      upc: values.upc ?? EMPTY_STRING,
      tags: [
        ...(customProductSelectedTags || []),
        inventoryConstants.CUSTOM_ITEM_TAG,
      ],
      max: 9999,
      imgUrl: "",
      ratio: ratio,
      genre: "",
      cardNumber: "",
      cardRarity: "",
      serialNumber: "",
      date_added: date_added,
      additionalCheckList: [],
      epid: globalConstants.EMPTY_STRING,
      trackQuantity: values.trackQuantity,
      apiSource: inventoryConstants.CUSTOM_ITEM,
      product_id: inventoryConstants.CUSTOM_ITEM,
      tcgPlayerUrl: globalConstants.EMPTY_STRING,
      sku: [`${inventoryConstants.CUSTOM_ITEM} ${tempSku}`],
      store: { id: currentStore.id, name: currentStore.storeName },
      category_name: getTypeOrCategoryObject(
        customCategories,
        values.category_name
      )?.name,
      productType: getTypeOrCategoryObject(customTypes, values.productType)
        ?.productType,
      subcategory:
        getTypeOrCategoryObject(customSubCategories, values.subcategory)
          ?.name || "",
      price: {
        unit_sell_price: values.inStockPrice,
        quantity: values?.trackQuantity ? values.quantity : 0,
        type: values.condition,
        marketPrice: 0,
      },
      tradeProductMetaData: {
        isItemLocked: false,
        cashPercentagePerItem: 0,
        tradePercentagePerItem: 0,
        tradeProductType: transactionConstants.CUSTOM_TRADE_PRODUCT,
        tradeInMarginTypeObject: marginObject,
      },
    };
    //------Keep an initial State of the Item
    miscellaneousItem = {
      ...miscellaneousItem,
      productInitialState: miscellaneousItem,
    };

    getInventoryByProductMetaDataAndConsolidation(
      currentStore?.id,
      {
        product_name: miscellaneousItem.product_name,
        category_name: miscellaneousItem.category_name,
        subcategory: miscellaneousItem.subcategory,
        type: miscellaneousItem.price.type,
        tags: miscellaneousItem.tags,
        cardRarity: miscellaneousItem.cardRarity,
        productType: miscellaneousItem.productType,
      },
      {
        toggleCustomItemModal: toggleCustomItemModal,
        isProductEdit: isProductEdit,
        functionAttributes: isProductEdit
          ? {
              ...newTradeData,
              inventory: newTradeData?.inventory?.map((item) => {
                return item.id === productToEdit?.id
                  ? {
                      ...productToEdit,
                      ...miscellaneousItem,
                      productInitialState: productToEdit.productInitialState,
                    }
                  : item;
              }),
            }
          : [miscellaneousItem],
        functionToCall: isProductEdit ? handleTradeData : addItemInCart,
      }
    );
  };

  //------- handle Product Type Change
  const handleTypeChange = (option, setFieldValue, values) => {
    setFieldValue("productType", option.value);
    setFieldValue("condition", globalConstants.EMPTY_STRING);
    setFieldValue("category_name", globalConstants.EMPTY_STRING);
    setFieldValue("subcategory", globalConstants.EMPTY_STRING);

    let tempRes = getMarginDropdownValue(
      0,
      tradeinMargin,
      option.label,
      {
        consoleName: globalConstants.EMPTY_STRING,
        genre: globalConstants.EMPTY_STRING,
      },
      false
    );
    setRatio(
      tempRes.marginObject.tradeinMarginPercentage /
        tempRes.marginObject.cashMarginPercentage
    );
  };

  //------- handle Category Change
  const handleCategoryChange = (option, setFieldValue, values) => {
    setFieldValue("category_name", option.value);
    setFieldValue("subcategory", globalConstants.EMPTY_STRING);
    let tempRes = getMarginDropdownValue(
      0,
      tradeinMargin,
      getTypeOrCategoryObject(customTypes, values.type)?.productType,
      {
        consoleName: option.label,
        genre: globalConstants.EMPTY_STRING,
      },
      false
    );
    setMarginObject(tempRes);
    setRatio(
      tempRes.marginObject.tradeinMarginPercentage /
        tempRes.marginObject.cashMarginPercentage
    );
  };

  //------- handle Quantity Change
  const handleTrackQuantityChange = (name, value, setFieldValue) => {
    setFieldValue("quantity", value ? 1 : "");
  };

  //------- handle Sub-Category Change
  const handleSubCategoryChange = (option, setFieldValue, values) => {
    setFieldValue("subcategory", option.value);
    let tempRes = getMarginDropdownValue(
      0,
      tradeinMargin,
      getTypeOrCategoryObject(customTypes, values.type)?.productType,
      {
        consoleName: getTypeOrCategoryObject(
          customCategories,
          values.category_name
        )?.name,
        genre: option.label,
      },
      false
    );
    setMarginObject(tempRes);
    setRatio(
      tempRes.marginObject.tradeinMarginPercentage /
        tempRes.marginObject.cashMarginPercentage
    );
  };

  useEffect(() => {
    //------ calculate ratio between cash offer and trade offer
    //------ tradeOfferPercentage/cashOfferPercentage
    setRatio(
      tradeinMargin?.globalMargin?.tradeinMarginPercentage /
        tradeinMargin?.globalMargin?.cashMarginPercentage
    );
    setMarginObject(tradeinMargin?.globalMargin);
  }, [tradeinMargin]);

  return (
    <>
      <Modal
        show={customItemModal}
        size="lg"
        onHide={handleHideModal}
        animation={true}
        centered
        backdrop="static"
        className="add-inventory-modal"
      >
        <Modal.Header closeButton className="add-inventory-modal-header">
          <Modal.Title>
            <span className="add-inventory-modal-header-name">
              {isProductEdit ? "Edit Trade Custom item" : "Trade Custom Item"}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-inventory-modal-body p-1">
          <Formik
            initialValues={initialValues}
            onSubmit={handleCustomItemSubmit}
            validate={addTradeCustomItemValidationSchema}
          >
            {({ resetForm, setFieldValue, values }) => (
              <Form>
                <Row className="m-0">
                  <Col
                    md={12}
                    className="px-0 mb-2 tab-heading text-muted d-flex justify-content-between align-items-center"
                  >
                    Product Information
                    <CheckboxField
                      name="trackQuantity"
                      label="Track Quantity"
                      isLabelLeft={true}
                      handleChange={(name, value) => {
                        handleTrackQuantityChange(name, value, setFieldValue);
                      }}
                    />
                  </Col>
                  <Col md={9} className="mb-3 ps-md-0">
                    <InputTextField
                      name="product_name"
                      label="Title"
                      placeHolder="Enter Title"
                      className="border"
                      maxLength={100}
                    />
                  </Col>
                  <Col md={3} className="mb-3 pe-md-0">
                    <InputNumberField
                      label="Quantity"
                      name="quantity"
                      placeHolder={values.trackQuantity ? "0" : ""}
                      className="add-inventory-quantity-field"
                      isConvertToDecimal={false}
                      step={"1"}
                      isHandleKeyDown={true}
                      isBorder={true}
                      disabled={!values.trackQuantity}
                    />
                  </Col>
                  <Col md={12} className="mb-3 px-0">
                    <InputTextArea
                      name="description"
                      label="Description (Optional)"
                      placeHolder="Enter Description"
                      className={"border"}
                      maxLength={1500}
                    />
                  </Col>
                  <Col md={12} className="add-inventory-hero-wrapper mb-3">
                    <Row className="m-0">
                      <Col md={12} className="mb-2 tab-heading text-muted">
                        Product Categorization
                      </Col>
                      <Col md={3} className="mb-2">
                        <IconSelectField
                          label="Type"
                          options={getCustomTypesOptionList(customTypes).filter(
                            (item) => item.label !== PRODUCT_TYPES_ENUMS.ALL
                          )}
                          isClearable={false}
                          name="productType"
                          customOnChange={(option) =>
                            handleTypeChange(option, setFieldValue, values)
                          }
                        />
                      </Col>
                      <Col md={3} className="mb-2">
                        <IconSelectField
                          label="Category"
                          options={getCustomCategoriesOptionList(
                            customCategories,
                            values.productType
                          )}
                          name="category_name"
                          customOnChange={(option) =>
                            handleCategoryChange(option, setFieldValue, values)
                          }
                          isClearable={false}
                        />
                      </Col>
                      <Col md={3} className="mb-2">
                        <IconSelectField
                          label="Sub-Category"
                          options={getCustomSubCategoriesOptionList(
                            customSubCategories,
                            values.category_name,
                            null,
                            values.productType,
                            false,
                            customCategories,
                            customTypes
                          )}
                          name="subcategory"
                          isClearable={false}
                          customOnChange={(option) =>
                            handleSubCategoryChange(
                              option,
                              setFieldValue,
                              values
                            )
                          }
                        />
                      </Col>
                      <Col md={3} className="mb-2">
                        <IconSelectField
                          label="Condition"
                          options={
                            getTypeOrCategoryObject(
                              customTypes,
                              values.productType
                            )?.productType === PRODUCT_TYPES_ENUMS.VIDEO_GAME
                              ? [
                                  ...inventoryConstants.VIDEO_GAME_CONDITION,
                                  {
                                    value: PRODUCT_CONDITIONS_ENUMS.USED,
                                    label: PRODUCT_CONDITIONS_ENUMS.USED,
                                  },
                                ]
                              : getTypeOrCategoryObject(
                                  customTypes,
                                  values.productType
                                )?.productType ===
                                PRODUCT_TYPES_ENUMS.TRADING_CARD
                              ? [
                                  ...inventoryConstants.TRADING_CARD_CONDITION,
                                  ...inventoryConstants.OTHER_CONDITION,
                                ]
                              : inventoryConstants.OTHER_CONDITION
                          }
                          placeHolder="Condition..."
                          name="condition"
                          isClearable={false}
                        />
                      </Col>
                      <Col md={12} className="mb-3">
                        <InputTextField
                          name="upc"
                          label="UPC (Optional)"
                          placeHolder="111000294854"
                          className="border"
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12} className="add-inventory-hero-wrapper mb-3">
                    <Row className="m-0">
                      <Col md={12} className="mb-2 tab-heading text-muted">
                        Product Tags
                      </Col>
                      <Col md={12}>
                        <ProductTagList
                          productDetail={{
                            tags: [
                              ...(customProductSelectedTags?.map(
                                (tag, index) => ({
                                  id: index,
                                  label: tag,
                                  selected: true,
                                })
                              ) || []),
                            ],
                          }}
                          className={"align-items-center"}
                          tagsList={[]}
                          suggestedArrayList={productTags}
                          onSelectTagsChange={onCustomProductSelectTagsChange}
                          customProductTags={true}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12} className="add-inventory-hero-wrapper mb-3">
                    <Row className="m-0">
                      <Col md={6}>
                        <Row className="m-0 w-100 h-100">
                          <Col md={12} className="mb-2 tab-heading text-muted">
                            Pricing
                          </Col>
                          <Col md={12} className="mb-2 mt-auto">
                            <InputNumberField
                              label="Stock Price"
                              name="inStockPrice"
                              placeHolder="0.00"
                              className="add-inventory-quantity-field"
                              isBorder={true}
                              IconImage={DollarIcon}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col md={6}>
                        <Row className="m-0 h-100 w-100">
                          <Col
                            md={12}
                            className="mb-2 d-flex justify-content-between align-items-center w-100 gap-3"
                          >
                            <span
                              className="text-nowrap"
                              style={{ width: "100px" }}
                            >
                              Cash Offer
                            </span>
                            <InputNumberField
                              name="cashOffer"
                              placeHolder="0.00"
                              className="add-inventory-quantity-field w-100"
                              isBorder={true}
                              IconImage={DollarIcon}
                              handleChange={(e) =>
                                handleCashOfferValues(e, setFieldValue, ratio)
                              }
                              outerWrapperClassName="w-100"
                            />
                          </Col>
                          <Col
                            md={12}
                            className="mb-2 d-flex mt-auto justify-content-between align-items-center w-100 gap-3"
                          >
                            <span
                              className="text-nowrap"
                              style={{ width: "100px" }}
                            >
                              Trade Offer
                            </span>
                            <InputNumberField
                              name="tradeOffer"
                              placeHolder="0.00"
                              className="add-inventory-quantity-field w-100"
                              isBorder={true}
                              IconImage={DollarIcon}
                              handleChange={(e) =>
                                handleTradeOfferValues(e, setFieldValue, ratio)
                              }
                              outerWrapperClassName="w-100"
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    md={6}
                    className="d-flex align-items-end gap-2 ms-auto px-0"
                  >
                    <Button
                      label={buttonNameConstants.CANCEL}
                      className="w-100"
                      type="button"
                      handleClick={() => handleHideModal()}
                      buttonType={buttonTypeConstants.GHOST_BUTTON}
                    />
                    <Button
                      label={
                        isProductEdit
                          ? buttonNameConstants.SAVE
                          : buttonNameConstants.ADD_TO_CART
                      }
                      className="w-100"
                      type="submit"
                    />
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      <ConsolidationModal />
    </>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  inventory: state.inventory,
  transaction: state.transaction,
  currentStore: state.store.currentStore,
  itemOrganization: state.itemOrganization,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  activateSpinner: (data) => dispatch(systemActions.activateSpinner(data)),
  deactivateSpinner: (data) => dispatch(systemActions.deactivateSpinner(data)),
  generateTempSku: () => dispatch(transactionActions.generateTempSku()),
  getInventoryByProductMetaDataAndConsolidation: (
    storeId,
    productMetaData,
    handleYesButtonClick,
    handleNoButtonClick
  ) =>
    dispatch(
      inventoryActions.getInventoryByProductMetaDataAndConsolidation(
        storeId,
        productMetaData,
        handleYesButtonClick,
        handleNoButtonClick
      )
    ),
});

//-------Export transaction Component
export default connect(mapStateToProps, mapDispatchToProps)(AddTradeCustomItem);

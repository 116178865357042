import { GLOBAL_STATUS } from "../system/globalEnums";

//-------Export Authentication Constants
export const authenticationConstants = {
  BEARER: "Bearer",
  COMPANY_LOGIN: "COMPANY_LOGIN",
  EMPLOYEE_LOGIN: "EMPLOYEE_LOGIN",
  USER_LOGOUT: "USER_LOGOUT",
  COMPANY_LOGOUT: "COMPANY_LOGOUT",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  RESET_PASSWORD: "RESET_PASSWORD",
  LOGIN_STATUS: {
    ...GLOBAL_STATUS,
  },
};

import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

import Button from "../../../../shared/components/Buttons/Button";
import SelectDropDown from "../../../../shared/components/selectDropdown/SelectDropdown";
import SimpleNumberField from "../../../../shared/components/inputNumberField/SimpleNumberField";
import ToggleButtonWithText from "../../../../shared/components/toggleButtons/ToggleButtonWithText";
import { editTradeBatchModalDropdownStyle } from "../../../../assets/scss/style/editTradeBatchModalDropdownStyle";
import {
  DollarIcon,
  PercentageIcon,
} from "../../../../assets/icons/iconsProvider";
import {
  EMPTY_STRING,
  buttonNameConstants,
  buttonTypeConstants,
  transactionConstants,
} from "../../../../constants";
import { parseToDecimalNumber } from "../../../../shared/utility";

const EditTradeBatchOfferModal = ({
  data,
  value,
  className,
  percentage,
  handleSubmit,
  toggleEditOfferModal,
  isEditOfferModalOpen = EMPTY_STRING,
}) => {
  const [isPercentageOffer, setIsPercentageOffer] = useState(true);

  const togglePercentageOffer = () => setIsPercentageOffer(!isPercentageOffer);
  const [inputValue, setInputValue] = useState(value);
  const [offerBy, setOfferBy] = useState(
    isEditOfferModalOpen === transactionConstants.EDIT_CASH_OFFER_MODAL
      ? transactionConstants.EDIT_BATCH_CASH_OFFER_OPTIONS[0]
      : isEditOfferModalOpen === transactionConstants.EDIT_COGS_MODAL
      ? transactionConstants.EDIT_COGS_OPTIONS[0]
      : transactionConstants.EDIT_BATCH_TRADE_OFFER_OPTIONS[0]
  );

  const getValue = () => {
    if (isPercentageOffer || offerBy?.value === 1) {
      return parseToDecimalNumber(inputValue);
    } else {
      return parseToDecimalNumber(
        (Number(data?.totalMarketPrice) * Number(inputValue)) / 100
      );
    }
  };

  useEffect(() => {
    if (!offerBy?.value) {
      setInputValue(
        parseToDecimalNumber(isPercentageOffer ? value : percentage)
      );
    } else {
      setInputValue(EMPTY_STRING);
    }
  }, [value, isPercentageOffer]);

  // hide modal
  const handleHideModal = () => {
    toggleEditOfferModal(EMPTY_STRING);
  };

  return (
    <div className={`edit-offer-modal py-2 ${className}`}>
      <Row className="m-0">
        <Col md={12} className="mb-2">
          <SelectDropDown
            menuPlacement="bottom"
            options={
              isEditOfferModalOpen ===
              transactionConstants.EDIT_CASH_OFFER_MODAL
                ? transactionConstants.EDIT_BATCH_CASH_OFFER_OPTIONS
                : isEditOfferModalOpen === transactionConstants.EDIT_COGS_MODAL
                ? transactionConstants.EDIT_COGS_OPTIONS
                : transactionConstants.EDIT_BATCH_TRADE_OFFER_OPTIONS
            }
            defaultPageSize={offerBy}
            menuPortalTarget={""}
            handleChange={(e) => {
              setOfferBy(e);
              if (e.value) {
                setInputValue(EMPTY_STRING);
              } else {
                setInputValue(
                  parseToDecimalNumber(isPercentageOffer ? value : percentage)
                );
              }
            }}
            isDisabled={false}
            styles={editTradeBatchModalDropdownStyle}
          />
        </Col>
        <Col md={7} className="mb-2">
          <SimpleNumberField
            // disabled={true}
            name="tradeOffer"
            placeHolder="0"
            className="add-inventory-price-field input-background"
            IconImage={isPercentageOffer ? DollarIcon : PercentageIcon}
            onChange={(e) => setInputValue(e)}
            value={inputValue}
          />
        </Col>
        <Col
          md={5}
          className="d-flex justify-content-end align-items-center mb-1"
        >
          <ToggleButtonWithText
            isToggle={isPercentageOffer}
            handleLeftButtonClick={() => togglePercentageOffer()}
            handleRightButtonClick={() => togglePercentageOffer()}
            leftButtonLabel="%"
            rightButtonLabel="$"
            className="discount-toggle-button dashboard-card-button edit-offer-modal-toggle-button opacity-100"
          />
        </Col>
        <Col
          md={12}
          className="d-flex justify-content-center align-items-center gap-2 border-top pt-2"
        >
          <Button
            label={buttonNameConstants.CLOSE}
            className="w-100 edit-offer-modal-button"
            type="button"
            handleClick={() => handleHideModal()}
            buttonType={buttonTypeConstants.GHOST_BUTTON}
            isDisabled={false}
          />
          <Button
            label={buttonNameConstants.APPLY}
            className="w-100 edit-offer-modal-button"
            type="button"
            handleClick={() => {
              handleSubmit({
                offerBy: offerBy,
                value: getValue(),
                isPercentageOffer: isPercentageOffer,
              });
              handleHideModal();
            }}
            isDisabled={false}
          />
        </Col>
      </Row>
    </div>
  );
};

export default EditTradeBatchOfferModal;

import pokemon from "pokemontcgsdk";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";

import AddTrade from "./AddTrade/AddTrade";
import AddTradeBatch from "./AddTradeBatch/AddTradeBatch";
import Topbar from "../../../shared/components/topbar/Topbar";
import Button from "../../../shared/components/Buttons/Button";
import AddQuickTradeIn from "./AddQuickTradeIn/AddQuickTradeIn";
import CustomerImage from "../../../assets/images/customerImage.svg";
import AddTradeCustomItem from "./AddTradeCustomItem/AddTradeCustomItem";
import EditTotalCashTrade from "./EditCashTradeOffer/EditTotalCashTrade";
import { inventoryActions, transactionActions } from "../../../redux/actions";
import SearchTradeInventory from "./SearchTrandeInventory/SearchTradeInventory";
import AddNewCustomerModal from "../NewTransaction/AddNewCustomer/AddNewCustomerModal";
import CustomerDropdown from "../../../shared/components/selectDropdown/CustomerDropdown";
import ConfirmationModal from "../../Inventory/Items/ConfirmationModal/ConfirmationModal";
import SelectedCustomerCard from "../../../shared/components/shared/SelectedCustomerCard";
import ProductDetailCard from "../../../shared/components/productDetailCard/ProductDetailCard";
import ProductCartCardTrade from "../../../shared/components/ProductDetailCardTrade/ProductCartCardTrade";
import {
  customToast,
  parseToNumber,
  calculateRatio,
  stringifyObject,
  parseJsonObject,
  parseToDecimalNumber,
  getSumOfPaymentHistory,
  convertToFixedPrecision,
  getCountOfTradeTotalItems,
  handleLockOrUnlockTradeItem,
  handleItemCashTradePercentage,
  getTotalTradeOfferOfBatchProducts,
  parseToThousandSeperatorDecimalNumber,
  createCompanyDynamicUrl,
  handleCalculateTotalCogsAndStockPrice,
  getUpdatedOfferValues,
  handleRemoveGuestCustomerBeforeTradeDraft,
  handleTradeTransactionData,
} from "../../../shared/utility";
import {
  toastType,
  toastMessages,
  globalConstants,
  customerConstants,
  inventoryConstants,
  routesPathConstants,
  buttonTypeConstants,
  buttonNameConstants,
  transactionConstants,
  categoryDropdownConstants,
  tradeTransactionDefaultData,
  toggleSwitchButtonConstants,
  SALE_TRANSACTION_ACTIVITY_TYPES,
} from "../../../constants";

import {
  AddIcon,
  CashIcon,
  DeleteIcon,
  ParkSaleIcon,
  ResetCartIcon,
  RetriveSaleIcon,
} from "../../../assets/icons/iconsProvider";

//-------NewTransaction component start here
const NewTrade = (props) => {
  const {
    user,
    getTags,
    customTypes,
    allCustomers,
    newTradeData,
    newTradePark,
    currentStore,
    tradeDiscount,
    toggleAddModal,
    setNewTradeData,
    customCategories,
    isAddCustomerModal,
    getScryFallProductById,
    toggleAddCustomerModal,
    addTradeBatchInventory,
    setAddTradeBatchInventory,
    setPokemonProductInReducer,
    isTradeBatchInventoryModal,
    getPricechartingProductById,
    setIsTradeBatchInventoryModal,
  } = props;

  //-------usestate
  const cartRef = useRef(null);
  const navigate = useNavigate();
  const [isLink, setIsLink] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [productsList, setProductsList] = useState([]);
  const [isNoteField, setIsNoteField] = useState(false);
  const [productToEdit, setProductToEdit] = useState({});
  const [customerToEdit, setCustomerToEdit] = useState({});
  const [addTradeModal, setAddTradeModal] = useState(false);
  const [customItemModal, setCustomItemModal] = useState(false);
  const [productReturnList, setProductReturnList] = useState([]);
  const [addCustomerModal, setAddCustomerModal] = useState(false);
  const [quickTradeInModal, setQuickTradeInModal] = useState(false);
  const [isConfirmationModal, setIsConfirmationModal] = useState(false);
  const [selectedCustomerFlag, setSelectedCustomerFlag] = useState(false);
  let cartItemsCount = useRef(
    newTradeData?.inventory?.length || addTradeBatchInventory.length || 0
  );
  const asyncSelectRef = useRef();
  const calculateTax = (subtotal) => {
    return 0;
    // return (subtotal * taxRate) / 100;
  };
  //-------Toggle confirmation modal
  const toggleConfirmationModal = () => {
    setIsConfirmationModal(!isConfirmationModal);
  };

  //-------handle conformation actions
  const handleConfirmYesButtonClick = () => {
    resetTradePage();
    toggleConfirmationModal();
  };

  const handleConfirmNoButtonClick = () => {
    toggleConfirmationModal();
  };

  //------Toggle custom item modal
  const toggleCustomItemModal = () => {
    setCustomItemModal(!customItemModal);
  };

  //-------handle custom item click
  const handleCustomItemClick = () => {
    setProductToEdit({});
    toggleCustomItemModal();
  };
  //------Toggle quick trade in modal
  const toggleQuickTradeInModal = () => {
    setQuickTradeInModal(!quickTradeInModal);
  };

  //-------handle  quick trade in click
  const handleQuickTradeInClick = () => {
    setProductToEdit({});
    toggleQuickTradeInModal();
  };

  const handleResetCart = () => {
    if (newTradeData?.inventory?.length || addTradeBatchInventory?.length) {
      toggleConfirmationModal();
    }
  };

  //-------handle card click
  const handleProductCardClick = (product) => {
    if (product) {
      if (product?.sourceApi === inventoryConstants.PRICECHARTING_API) {
        getPricechartingProductById(product, product?.id);
      }
      if (product?.sourceApi === inventoryConstants.POKEMON_API) {
        pokemon.card.find(product?.id).then((card) => {
          setPokemonProductInReducer(product, card || "");
        });
      }
      if (product?.sourceApi === inventoryConstants.SCRYFALL_API) {
        getScryFallProductById(product, product?.id);
      }
    }

    setProductToEdit({});
    handleAddTradeModalOpen();
  };

  // Function to scroll the container to the bottom
  const scrollToBottom = () => {
    if (cartRef.current) {
      cartRef.current.scrollTop = cartRef.current.scrollHeight;
    }
  };

  const updateTradeData = (data, activityName) => {
    handleTradeTransactionData(
      data,
      user,
      currentStore,
      activityName,
      newTradeData,
      setNewTradeData
    );
  };

  const handleDeleteCartItem = (id) => {
    // Filter out the item with the matching id
    const updatedInventory = newTradeData?.inventory?.filter(
      (item) => item.id !== id
    );

    // Update the ids of the remaining items
    const updatedInventoryWithNewIds = updatedInventory.map((item, index) => ({
      ...item,
      id: index + 1, // Assign new id based on the index, starting from 1
    }));

    // Update the trade data with the new inventory
    updateTradeData(
      {
        ...newTradeData,
        inventory: updatedInventoryWithNewIds,
      },
      SALE_TRANSACTION_ACTIVITY_TYPES.DELETE_ITEM
    );
  };

  //------- handle lock or unlock cart item
  const handleLockOrUnlockItem = (itemId) => {
    handleLockOrUnlockTradeItem(
      currentStore,
      newTradeData,
      setNewTradeData,
      itemId,
      true
    );
  };

  //------- handle edit cart item
  const handleEditCartItem = (productObject) => {
    const itemToEdit = productObject;

    if (
      productObject?.tradeProductMetaData?.tradeProductType ===
      transactionConstants.PRICECHARTING_PRODUCT
    ) {
      if (itemToEdit) {
        const metadata = {
          id: itemToEdit?.product_id,
          sourceApi: itemToEdit?.apiSource,
          genre: itemToEdit?.genre,
          name: itemToEdit?.product_name,
          price: itemToEdit?.price?.unit_sell_price,
          productType: itemToEdit?.productType,
          category_name: itemToEdit?.category_name,
          imgUrl: itemToEdit?.imgUrl,
          upc: itemToEdit?.upc,
        };
        setProductToEdit(itemToEdit);
        if (metadata?.sourceApi === inventoryConstants.PRICECHARTING_API) {
          getPricechartingProductById(metadata, metadata?.id);
        }
        if (metadata?.sourceApi === inventoryConstants.POKEMON_API) {
          pokemon.card.find(metadata?.id).then((card) => {
            setPokemonProductInReducer(metadata, card || "");
          });
        }
        if (metadata?.sourceApi === inventoryConstants.SCRYFALL_API) {
          getScryFallProductById(metadata, metadata?.id);
        }
      }
      handleAddTradeModalOpen();
    } else if (
      productObject?.tradeProductMetaData?.tradeProductType ===
      transactionConstants.QUICK_TRADE_PRODUCT
    ) {
      setProductToEdit(productObject);
      toggleQuickTradeInModal();
    } else if (
      productObject?.tradeProductMetaData?.tradeProductType ===
      transactionConstants.CUSTOM_TRADE_PRODUCT
    ) {
      setProductToEdit(productObject);
      toggleCustomItemModal();
    }
  };

  const handleQuantityChange = (skuNumber, newQuantity) => {
    const updatedCartItems = newTradeData?.inventory?.map((item) => {
      if (item.sku === skuNumber) {
        // Assuming price is an object and has a quantity property you want to update
        return { ...item, price: { ...item.price, quantity: newQuantity } };
      }
      return item;
    });

    updateTradeData(
      {
        ...newTradeData,
        inventory: updatedCartItems,
      },
      SALE_TRANSACTION_ACTIVITY_TYPES.CHANGE_ITEM_QUANTITY
    );
  };

  const handleAddTradeModalOpen = () => {
    toggleAddModal(true);
    toggleAddTradeModal();
  };

  //------ handle customer edit
  const handleCustomerEditClick = () => {
    const customer = allCustomers?.find(
      (customer) => customer.id === newTradeData?.customer?.id
    );
    if (customer?.id) {
      setCustomerToEdit(customer);
      toggleAddCustomerModal(true);
    }
  };

  const resetTradePage = () => {
    setSelectedCustomerFlag(false);
    updateTradeData(
      tradeTransactionDefaultData,
      SALE_TRANSACTION_ACTIVITY_TYPES.RESET_TRANSACTION
    );
    setAddTradeBatchInventory([], currentStore?.id);
    setProductsList([]);
  };

  //-------Toggle Add Inventoty Item Modal
  const toggleAddTradeModal = () => setAddTradeModal(!addTradeModal);
  const addToCart = async (addToCartData, isBatchTrade = false) => {
    if (isBatchTrade) {
      updateTradeData(
        {
          ...addToCartData,
          inventory: handleItemCashTradePercentage(
            addToCartData?.inventory,
            true
          ),
        },
        SALE_TRANSACTION_ACTIVITY_TYPES.ADD_ITEM
      );
    } else {
      const updatedData = await getUpdatedCartItems(
        newTradeData,
        addToCartData
      );
      updateTradeData(
        {
          ...updatedData,
          inventory: handleItemCashTradePercentage(
            updatedData?.inventory,
            true
          ),
        },
        SALE_TRANSACTION_ACTIVITY_TYPES.ADD_ITEM
      );
      setCustomItemModal(false);
    }

    getTags(currentStore?.id);
  };

  const handleButtonClick = (buttonName) => {
    updateTradeData(
      { ...newTradeData, PaymentType: buttonName },
      SALE_TRANSACTION_ACTIVITY_TYPES.CHANGE_PAYMENT_TYPE
    );
  };
  const subtotal = () => {
    if (
      Array.isArray(newTradeData?.inventory) &&
      newTradeData?.inventory.length > 0
    ) {
      return newTradeData?.inventory.reduce((acc, item) => {
        const offer =
          newTradeData.PaymentType === globalConstants.CASH
            ? item.cashOffer
            : item.tradeOffer;
        const quantity = item.trackQuantity ? item.price?.quantity : 1;
        return acc + offer * (quantity || 0);
      }, 0);
    }
    return 0;
  };
  const subtotalCash = () => {
    if (
      Array.isArray(newTradeData?.inventory) &&
      newTradeData?.inventory.length > 0
    ) {
      return newTradeData?.inventory.reduce((acc, item) => {
        const offer = item.cashOffer;
        const quantity = item.trackQuantity ? item.price?.quantity : 1;
        return acc + offer * (quantity || 0);
      }, 0);
    }
    return 0;
  };
  const subtotalTrade = () => {
    if (
      Array.isArray(newTradeData?.inventory) &&
      newTradeData?.inventory.length > 0
    ) {
      return newTradeData?.inventory.reduce((acc, item) => {
        const offer = item.tradeOffer;
        const quantity = item.trackQuantity ? item.price?.quantity : 1;
        return acc + offer * (quantity || 0);
      }, 0);
    }
    return 0;
  };

  // get total market value of trade cart items
  const getTotalMarketValue = () => {
    if (
      Array.isArray(newTradeData?.inventory) &&
      newTradeData?.inventory?.length > 0
    ) {
      return newTradeData?.inventory.reduce((acc, item) => {
        return acc + item?.price?.marketPrice ?? 0;
      }, 0);
    }
    return 0;
  };

  // Check if the ID type is "US Driver's License" and ID number is valid (non-empty)
  const isValidCustomerID = (customer) => {
    return (
      customer.driverLicenseIdType === customerConstants.ID_TYPES[0]?.value &&
      customer.driverLicense?.trim().length > 0
    );
  };

  const handlePayClick = async (trasactionTypeCondition) => {
    const isIdRequiredForTradeIn =
      currentStore[toggleSwitchButtonConstants.IS_ID_REQUIRED_TRADE_IN];
    const isIdRequiredForTradeInStoreCredit =
      currentStore[
        toggleSwitchButtonConstants.IS_ID_REQUIRED_TRADE_IN_STORE_CREDIT
      ];
    const hasCustomer = Boolean(newTradeData.customer?.id);
    const isCash = newTradeData.PaymentType === globalConstants.CASH;
    const isCredit = newTradeData.PaymentType === globalConstants.CREDIT;

    // Show error if no customer is selected and required by payment type
    if (
      (!hasCustomer && isCredit) ||
      (!hasCustomer && isCash && isIdRequiredForTradeIn)
    ) {
      customToast(toastMessages.PLEASE_SELECT_A_CUSTOMER, toastType.ERROR);
      return;
    }

    // Validate customer ID based on payment type and requirements
    if (
      hasCustomer &&
      ((!isValidCustomerID(newTradeData.customer) &&
        isCash &&
        isIdRequiredForTradeIn) ||
        (!isValidCustomerID(newTradeData.customer) &&
          isCredit &&
          isIdRequiredForTradeInStoreCredit))
    ) {
      customToast(toastMessages.VALID_CUSTOMER_REQUIRED, toastType.ERROR);
      return;
    }

    // handle component unmounting
    cartItemsCount.current = 0;
    // let isInvalid = newTradeData?.inventory?.some((inv) => {
    //   return Number(inv.cashOffer) === 0 || Number(inv.tradeOffer) === 0;
    // });

    // if (isInvalid) {
    //   customToast("Trade Offer & Cash Offer Cannot be 0", toastType.ERROR);
    //   return;
    // }

    if (
      newTradeData?.inventory?.length > 0 ||
      (addTradeBatchInventory.length &&
        trasactionTypeCondition === transactionConstants.DRAFT)
    ) {
      newTradeData.inventory.forEach((item) => {
        const offerValue =
          newTradeData.PaymentType === globalConstants.CASH
            ? item.cashOffer
            : item.tradeOffer;
        if (item.price) {
          item.price.unit_purchase_price = offerValue;
        } else {
          item.price = { unit_purchase_price: offerValue };
        }
      });
      const { initialTotalCogs, initialTotalStockPrice } =
        handleCalculateTotalCogsAndStockPrice(newTradeData.inventory, true);

      const dataToSend = {
        ...newTradeData,
        userPaymentType: newTradeData.PaymentType,
        customer: newTradeData.customer.id
          ? newTradeData.customer
          : { id: "", name: "" },
        store: currentStore || "",
        paymentMethod: globalConstants.EMPTY_STRING,
        totalCashOffer: newTradeData?.totalCashOffer,
        totalTradeOffer: newTradeData?.totalTradeOffer,
        amountToPay: parseToNumber(newTradeData.amountToPay),
        initialTotalCogs: initialTotalCogs,
        initialTotalStockPrice: initialTotalStockPrice,
      };
      if (trasactionTypeCondition === transactionConstants.DRAFT) {
        const tradeId =
          newTradeData?.id || (await transactionActions.getTradeId());

        const resOfRemoveGuestCustomer =
          handleRemoveGuestCustomerBeforeTradeDraft(newTradeData, currentStore);

        const parkTradeData = {
          ...dataToSend,
          id: tradeId,
          store: { id: currentStore?.id, name: currentStore?.storeName },
          customer: resOfRemoveGuestCustomer.customer,
          PaymentType: resOfRemoveGuestCustomer.PaymentType,
          userPaymentType: resOfRemoveGuestCustomer.PaymentType,
          addBatchInventory: addTradeBatchInventory,
          initialTotalCogs: initialTotalCogs,
          initialTotalStockPrice: initialTotalStockPrice,
        };
        newTradePark(parkTradeData, currentStore?.id);
        resetTradePage();
      } else {
        updateTradeData(
          dataToSend,
          SALE_TRANSACTION_ACTIVITY_TYPES.PAYMENT_BUTTON_CLICK
        );
        navigate(
          createCompanyDynamicUrl(
            routesPathConstants.SELL_AND_TRADE_NEW_TRADE_PAYMENT
          ),
          {
            state: {
              currentStore: currentStore,
            },
          }
        );
      }
    } else {
      customToast(toastMessages.PLEASE_SELECT_SOME_PRODUCT, toastType.ERROR);
    }
  };
  const handleAddCustomerClick = (customerToEdit = {}) => {
    setCustomerToEdit(customerToEdit);
    toggleAddCustomerModal(true);
  };
  const updateCashTradeOffer = (
    skuNumber,
    activeButton,
    newPrice,
    marketPrice
  ) => {
    const updatedCartItems = newTradeData?.inventory?.map((item) => {
      if (item.sku === skuNumber) {
        if (activeButton === globalConstants.CASH) {
          if (marketPrice) {
            const TradeOfferCalculated = Number(newPrice * item.ratio).toFixed(
              2
            );
            return {
              ...item,
              tradeOffer: TradeOfferCalculated,
              cashOffer: newPrice,
            };
          } else {
            const applyRatio = convertToFixedPrecision(newPrice * item.ratio);
            return { ...item, tradeOffer: applyRatio, cashOffer: newPrice };
          }
        } else if (activeButton === globalConstants.CREDIT) {
          if (marketPrice) {
            const cashOfferCalculated = Number(newPrice / item.ratio).toFixed(
              2
            );

            return {
              ...item,
              cashOffer: cashOfferCalculated,
              tradeOffer: newPrice,
            };
          } else {
            const applyRatio = convertToFixedPrecision(newPrice / item.ratio);
            return { ...item, cashOffer: applyRatio, tradeOffer: newPrice };
          }
        }
      }
      return item;
    });
    updateTradeData(
      {
        ...newTradeData,
        inventory: updatedCartItems,
      },
      SALE_TRANSACTION_ACTIVITY_TYPES.UPDATE_CASH_TRADE_OFFER
    );
  };
  const selectedCustomer = (selectedCustomerFlag) => {
    setSelectedCustomerFlag(selectedCustomerFlag);
  };

  //------- handle Reset Cart
  const handleResetCartItems = () => {
    if (newTradeData?.inventory?.length) {
      let updatedNewTradeTransactionData = {
        ...newTradeData,
        inventory: newTradeData?.inventory?.map((item) => ({
          ...item,
          ...item.productInitialState,
        })),
      };
      updateTradeData(
        updatedNewTradeTransactionData,
        SALE_TRANSACTION_ACTIVITY_TYPES.RESET_CART_ITEMS
      );
    }
  };

  //------ handle offer value change
  const handleOfferValueChange = (data, isCashOfferValue) => {
    const value = Number(data.value);
    let updatedCartInventory = newTradeData.inventory;
    // Step 1: Check if all items are locked
    const hasUnlockedItems = updatedCartInventory.some(
      (item) => !item.tradeProductMetaData.isItemLocked
    );
    if (!hasUnlockedItems) {
      customToast(toastMessages.ALL_ITEMS_LOCKED_ERROR, toastType.ERROR);
      return; // Exit early if no unlocked items
    }

    // Step 3: Validate the inventory data for 0 or empty values
    let isInvalid = updatedCartInventory.some((inv) => {
      return (
        Number(inv.price.quantity) === 0 || // Check if inStockQuantity is 0
        Number(inv.cashOffer) === 0 || // Check if cashOffer is 0
        inv.cashOffer === "" || // Check if cashOffer is empty
        Number(inv.tradeOffer) === 0 || // Check if tradeOffer is 0
        inv.tradeOffer === "" // Check if tradeOffer is empty
      );
    });
    if (isInvalid) {
      customToast(
        "Quantity & Stock Price & cash or trade Offer Cannot be 0",
        toastType.ERROR
      );
      return; // Exit early if invalid fields are found
    }

    // Calculate total offer for locked items based on the type of offer (cash or trade)
    const lockedTotalOffer = parseToDecimalNumber(
      updatedCartInventory.reduce((total, item) => {
        if (item.tradeProductMetaData.isItemLocked) {
          return (
            total +
            (isCashOfferValue
              ? Number(item.cashOffer)
              : Number(item.tradeOffer))
          );
        }
        return total;
      }, 0) ?? 0
    );
    // Step 4: Check if the incoming value is less than or equal to the total offer of locked items
    if (value <= Number(lockedTotalOffer)) {
      customToast(toastMessages.VALUE_LESS_THAN_LOCKED_ITEMS, toastType.ERROR);
      return; // Exit early if the value is invalid
    }

    // Step 4: Parse the input value
    const inputValue = parseToDecimalNumber(value - Number(lockedTotalOffer));

    // Step 5: Update the batch inventory based on whether it's a cash offer or trade offer
    let finalUpdatedBatchInventory = getUpdatedOfferValues(
      inputValue,
      isLink,
      isCashOfferValue,
      true,
      updatedCartInventory,
      true
    );

    // Step 6: Handle the difference in the updated inventory
    const totalOffer = getTotalTradeOfferOfBatchProducts(
      finalUpdatedBatchInventory,
      true
    );

    const difference = parseToNumber(
      Number(inputValue) -
        Number(
          isCashOfferValue
            ? totalOffer.cashOfferValue
            : totalOffer.tradeOfferValue
        )
    );

    if (difference < 0 || difference > 0) {
      let productIndex = 0;
      // Step 7: Filter out locked items completely when calculating the product index
      const nonLockedBatchInventory = finalUpdatedBatchInventory.filter(
        (item) => !item.tradeProductMetaData.isItemLocked
      );
      // Step 8: Handle positive or negative difference in the respective offer (cashOffer or tradeOffer)
      if (difference < 0) {
        productIndex = nonLockedBatchInventory.reduce(
          (maxIndex, obj, idx, array) => {
            return obj[isCashOfferValue ? "cashOffer" : "tradeOffer"] >
              array[maxIndex][isCashOfferValue ? "cashOffer" : "tradeOffer"]
              ? idx
              : maxIndex;
          },
          0
        );
      } else {
        productIndex = nonLockedBatchInventory.reduce(
          (minIndex, obj, idx, array) => {
            return obj[isCashOfferValue ? "cashOffer" : "tradeOffer"] <
              array[minIndex][isCashOfferValue ? "cashOffer" : "tradeOffer"]
              ? idx
              : minIndex;
          },
          0
        );
      }
      // Step 9: Update the offer for the selected product based on the calculated difference
      let newOfferValue = parseToDecimalNumber(
        Number(
          nonLockedBatchInventory[productIndex][
            isCashOfferValue ? "cashOffer" : "tradeOffer"
          ]
        ) + difference
      );
      // Ensure offer doesn't go below the minimum threshold (0.01)
      if (newOfferValue < inventoryConstants.DEFAULT_OFFER_VALUE) {
        let remainingDifference =
          difference -
          (inventoryConstants.DEFAULT_OFFER_VALUE -
            nonLockedBatchInventory[productIndex][
              isCashOfferValue ? "cashOffer" : "tradeOffer"
            ]);
        nonLockedBatchInventory[productIndex][
          isCashOfferValue ? "cashOffer" : "tradeOffer"
        ] = inventoryConstants.DEFAULT_OFFER_VALUE;
        // If there's still remaining difference, redistribute it across other unlocked products
        for (let i = 0; i < nonLockedBatchInventory.length; i++) {
          if (i === productIndex) continue;

          let otherProduct = nonLockedBatchInventory[i];
          let potentialNewOfferValue =
            otherProduct[isCashOfferValue ? "cashOffer" : "tradeOffer"] -
            remainingDifference;

          if (
            potentialNewOfferValue >= inventoryConstants.DEFAULT_OFFER_VALUE
          ) {
            otherProduct[isCashOfferValue ? "cashOffer" : "tradeOffer"] =
              parseToDecimalNumber(potentialNewOfferValue);
            remainingDifference = 0;
            break;
          } else {
            remainingDifference -=
              otherProduct[isCashOfferValue ? "cashOffer" : "tradeOffer"] -
              inventoryConstants.DEFAULT_OFFER_VALUE;
            otherProduct[isCashOfferValue ? "cashOffer" : "tradeOffer"] =
              inventoryConstants.DEFAULT_OFFER_VALUE;
          }
        }
      } else {
        nonLockedBatchInventory[productIndex][
          isCashOfferValue ? "cashOffer" : "tradeOffer"
        ] = newOfferValue;
      }
      // Step 10: Rebuild the final updated batch inventory without modifying locked items
      finalUpdatedBatchInventory = finalUpdatedBatchInventory.map((item) => {
        if (item.isItemLocked) {
          return item; // Return the locked items unchanged
        }

        const updatedItem = nonLockedBatchInventory.find(
          (updatedItem) => updatedItem.id === item.id // Assuming each item has a unique 'id'
        );

        return updatedItem ? updatedItem : item; // Replace the unlocked item with the updated one
      });
    }
    updateTradeData(
      {
        ...newTradeData,
        inventory: finalUpdatedBatchInventory,
      },
      SALE_TRANSACTION_ACTIVITY_TYPES.CHANGE_CASH_TRADE_OFFER_VALUE
    );
  };

  useEffect(() => {
    if (
      productReturnList?.length === 1 &&
      productReturnList[0].value.upc === searchText &&
      !isTradeBatchInventoryModal
    ) {
      handleProductCardClick(productReturnList[0].value);
    } else {
      const productValues =
        productReturnList?.map((product) => product.value) || [];
      // Now you can use productValues to set your products list
      setProductsList(productValues);
    } // eslint-disable-next-line
  }, [productReturnList]);

  useEffect(() => {
    updateTradeData(
      {
        ...newTradeData,
        SubTotal: convertToFixedPrecision(subtotal()),
        tax:
          newTradeData.PaymentType === globalConstants.CREDIT
            ? convertToFixedPrecision(calculateTax(subtotal()))
            : 0,
        totalAmountToPay:
          newTradeData.PaymentType === globalConstants.CASH
            ? convertToFixedPrecision(subtotal())
            : convertToFixedPrecision(
                Number(subtotal() + calculateTax(subtotal())).toFixed(2)
              ),
        amountToPay:
          newTradeData.amountToPay > 0
            ? newTradeData.PaymentType === globalConstants.CASH
              ? convertToFixedPrecision(subtotal()) -
                getSumOfPaymentHistory(newTradeData.paymentHistory)
              : convertToFixedPrecision(
                  Number(subtotal() + calculateTax(subtotal())).toFixed(2)
                ) - getSumOfPaymentHistory(newTradeData.paymentHistory)
            : newTradeData.PaymentType === globalConstants.CASH
            ? convertToFixedPrecision(subtotal())
            : convertToFixedPrecision(
                Number(subtotal() + calculateTax(subtotal())).toFixed(2)
              ),
        totalCashOffer: convertToFixedPrecision(subtotalCash()),
        totalTradeOffer: convertToFixedPrecision(
          Number(subtotalTrade() + calculateTax(subtotalTrade())).toFixed(2)
        ),
        totalMarketPrice: parseToDecimalNumber(getTotalMarketValue()),
        taxForTrade: convertToFixedPrecision(calculateTax(subtotalTrade())),
      },
      SALE_TRANSACTION_ACTIVITY_TYPES.AMOUNT_CALCULATION
    );
    // handle component unmounting
    cartItemsCount.current =
      newTradeData?.inventory?.length || addTradeBatchInventory.length || 0;

    // eslint-disable-next-line
  }, [
    newTradeData.inventory,
    newTradeData.PaymentType,
    addTradeBatchInventory,
  ]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    // eslint-disable-next-line
  }, [currentStore]);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (allCustomers && allCustomers.length > 0 && selectedCustomerFlag) {
      updateTradeData(
        {
          ...newTradeData,
          customer: {
            ...allCustomers[0],
            name: allCustomers[0].firstName + " " + allCustomers[0].lastName,
          },
          PaymentType: globalConstants.CREDIT,
        },
        SALE_TRANSACTION_ACTIVITY_TYPES.ADD_CUSTOMER
      );
      setSelectedCustomerFlag(false);
    }

    // eslint-disable-next-line
  }, [allCustomers]);

  useEffect(() => {
    setAddCustomerModal(isAddCustomerModal);
  }, [isAddCustomerModal]);

  // handle component unmounting
  useEffect(() => {
    if (newTradeData.paymentMethod === transactionConstants.PAYMENT_COMPLETE) {
      updateTradeData(
        tradeTransactionDefaultData,
        SALE_TRANSACTION_ACTIVITY_TYPES.RESET_TRANSACTION
      );
    }

    // return () => {
    //   if (cartItemsCount.current > 0) {
    //     handleDraftModal(transactionConstants.TRADE_DRAFT_MODAL);
    //   }
    // };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [newTradeData?.inventory?.length]);

  //------return new transaction component
  return (
    <>
      <Row className="m-0">
        <Col md={7}>
          <Topbar title="New Transaction" sellTradeToggleButton={true} />
          <Row className="m-0">
            <Col md={12}>
              <div className="d-flex mb-3 justify-content-between align-items-center gap-2">
                <Button
                  label={buttonNameConstants.QUICK_TRADE_ID}
                  handleClick={handleQuickTradeInClick}
                  className="w-100"
                />
                {addTradeBatchInventory.length ? (
                  <Button
                    label={buttonNameConstants.CONTINUE_BATCH}
                    // IconImage={AddIcon}
                    handleClick={() => {
                      setIsTradeBatchInventoryModal(
                        !isTradeBatchInventoryModal
                      );
                    }}
                    className="w-100"
                  />
                ) : (
                  <Button
                    label={buttonNameConstants.ADD_BATCH}
                    // IconImage={AddIcon}
                    handleClick={() => {
                      setIsTradeBatchInventoryModal(
                        !isTradeBatchInventoryModal
                      );
                    }}
                    className="w-100"
                  />
                )}
                <Button
                  label={buttonNameConstants.CUSTOM_ITEM}
                  IconImage={AddIcon}
                  handleClick={handleCustomItemClick}
                  className="w-100"
                />
              </div>
            </Col>
            <Col
              md={12}
              className="d-flex justify-content-between align-items-center "
            >
              <span className="transaction-heading">Add Items</span>
            </Col>
            <Col md={12} className="mb-3 m-0 ">
              <SearchTradeInventory
                customTypes={customTypes}
                setSearchText={setSearchText}
                asyncSelectRef={asyncSelectRef}
                setProductsList={setProductsList}
                customCategories={customCategories}
                productReturn={setProductReturnList}
                isScanEnable={!addCustomerModal}
                isTradeBatchInventoryModal={isTradeBatchInventoryModal}
              />
            </Col>

            {productsList?.length > 0 && (
              <Col md={12} className="mt-2">
                <div className="d-flex flex-column gap-2 searched-products-wrapper-trade container-scrollbar">
                  {productsList?.map((product, index) => (
                    <ProductDetailCard
                      handleCardClick={handleProductCardClick}
                      productName={product.name}
                      skuNumber={product.id}
                      productType={product.productType}
                      price={product.price}
                      productObject={product}
                      key={index}
                    />
                  ))}
                </div>
              </Col>
            )}
          </Row>
        </Col>
        <Col md={5} className="sale-cart-section px-3 py-3 overflow-hidden">
          <Row className="m-0 px-0 h-100 d-flex flex-column align-items-center">
            <Row className="m-0 px-0">
              <Col
                md={12}
                className="d-flex justify-content-between align-items-center px-0"
              >
                <span className="transaction-heading">Cart</span>
                <div className=" d-flex justify-content-center align-items-center">
                  <Button
                    IconImage={ResetCartIcon}
                    label={buttonNameConstants.RESET}
                    buttonType={buttonTypeConstants.TRANSPARENT_BUTTON_PURPLE}
                    handleClick={handleResetCart}
                  />
                  <Button
                    label={buttonNameConstants.PARK_TRADE}
                    buttonType={buttonTypeConstants.TRANSPARENT_BUTTON_PURPLE}
                    IconImage={ParkSaleIcon}
                    handleClick={() =>
                      handlePayClick(transactionConstants.DRAFT)
                    }
                  />
                  <Button
                    label={buttonNameConstants.RETRIVE_TRADE}
                    buttonType={buttonTypeConstants.TRANSPARENT_BUTTON_GHOST}
                    IconImage={RetriveSaleIcon}
                    handleClick={() => {
                      navigate(
                        createCompanyDynamicUrl(
                          routesPathConstants.SELL_AND_TRADE_DRAFTS
                        )
                      );
                    }}
                    className="pe-1"
                  />
                </div>
              </Col>
              {newTradeData?.customer?.id ? (
                <Col md={12} className="my-3 rounded-2 py-2 bg-white">
                  <SelectedCustomerCard
                    customer={newTradeData?.customer}
                    isStoreCredit={true}
                    isLockCustomer={true}
                    isDeleteEditOptions={true}
                    handleCustomerDeleteClick={() => {
                      updateTradeData(
                        {
                          ...newTradeData,
                          PaymentType: globalConstants.CASH,
                          customer: { id: "", name: "" },
                        },
                        SALE_TRANSACTION_ACTIVITY_TYPES.DELETE_CUSTOMER
                      );
                    }}
                    handleAddCustomerClick={handleAddCustomerClick}
                    handleCustomerEditClick={handleCustomerEditClick}
                  />
                </Col>
              ) : (
                <Col md={12} className="mt-1 px-0">
                  <CustomerDropdown
                    handleChange={(selectedOption) => {
                      if (!selectedOption || selectedOption === "") {
                        updateTradeData(
                          {
                            ...newTradeData,
                            PaymentType: globalConstants.CASH,
                            customer: { id: "", name: "" },
                          },
                          SALE_TRANSACTION_ACTIVITY_TYPES.DELETE_CUSTOMER
                        );
                      } else {
                        updateTradeData(
                          {
                            ...newTradeData,
                            PaymentType: globalConstants.CREDIT,
                            customer: selectedOption?.value
                              ? parseJsonObject(selectedOption?.value)
                              : { id: "", name: "" },
                          },
                          SALE_TRANSACTION_ACTIVITY_TYPES.ADD_CUSTOMER
                        );
                        // setActiveButton(globalConstants.CREDIT);
                      }
                    }}
                    value={
                      newTradeData?.customer?.id
                        ? {
                            value: stringifyObject(newTradeData?.customer),
                            label: (
                              <div className="d-flex align-items-center gap-2 ">
                                <span>
                                  <img
                                    src={CustomerImage}
                                    className="new-sale-customer-image"
                                    alt="customer"
                                  />
                                </span>
                                {`${newTradeData?.customer.firstName} ${newTradeData?.customer.lastName}`}
                              </div>
                            ),
                          }
                        : ""
                    }
                    isLockCustomer={true}
                    isGuestCustomers={true}
                    dropdownIndicator={false}
                    SelectIconImage={categoryDropdownConstants.SEARCH_ICON}
                    handleAddCustomerClick={handleAddCustomerClick}
                    selectedCustomerFlag={selectedCustomerFlag}
                  />
                </Col>
              )}
            </Row>
            <Col md={12} className="mt-1 px-0">
              <div
                ref={cartRef}
                className={`trade-cart-cards-wrapper container-scrollbar ${
                  isNoteField && newTradeData?.customer?.id
                    ? "trade-both-fields-open"
                    : isNoteField || newTradeData?.customer?.id
                    ? "trade-one-fields-open"
                    : ""
                }`}
              >
                {newTradeData?.inventory?.map((cartItem) => (
                  <ProductCartCardTrade
                    productObject={cartItem}
                    handleEditCartItem={handleEditCartItem}
                    activeButton={newTradeData.PaymentType}
                    totalItems={cartItem?.price?.totalItems}
                    handleDeleteCartItem={handleDeleteCartItem}
                    handleQuantityChange={handleQuantityChange}
                    updateCashTradeOffer={updateCashTradeOffer}
                    handleLockOrUnlockItem={handleLockOrUnlockItem}
                  />
                ))}
              </div>
            </Col>
            <Row className="m-0 px-0 mt-auto">
              <Col
                md={12}
                className="d-flex justify-content-between align-items-center mt-2 px-0"
              >
                <span className="opacity-50">ADD</span>
                <div className="d-flex justify-content-center align-items-center gap-3">
                  <button
                    type="button"
                    className={`sale-extra-button ${
                      isNoteField && "active-link"
                    }`}
                    onClick={() => {
                      setIsNoteField(true);
                    }}
                  >
                    Note
                  </button>
                </div>
              </Col>

              {isNoteField && (
                <Col md={12} className="mt-2 d-flex justify-content-between">
                  <textarea
                    value={newTradeData.note}
                    onChange={(e) => {
                      updateTradeData(
                        {
                          ...newTradeData,
                          note: e.target.value,
                        },
                        SALE_TRANSACTION_ACTIVITY_TYPES.TRIGGER_NOTE_FIELD
                      );
                    }}
                    className="input-textfield text-area-field note-area-field"
                  />
                  <button
                    type="button"
                    className="cart-card-delete-button"
                    onClick={() => {
                      updateTradeData(
                        { ...newTradeData, note: "" },
                        SALE_TRANSACTION_ACTIVITY_TYPES.TRIGGER_NOTE_FIELD
                      );
                      setIsNoteField(false);
                    }}
                  >
                    <DeleteIcon />
                  </button>
                </Col>
              )}
              <Col className="mt-2  m-0 px-0" md={12}>
                <EditTotalCashTrade
                  isLink={isLink}
                  data={newTradeData}
                  setIsLink={setIsLink}
                  handleButtonClick={handleButtonClick}
                  activeButton={newTradeData.PaymentType}
                  handleResetCartItems={handleResetCartItems}
                  handleOfferValueChange={handleOfferValueChange}
                  totalCashTrade={{
                    cashOfferValue: newTradeData?.totalCashOffer,
                    tradeOfferValue: newTradeData?.totalTradeOffer,
                  }}
                />
              </Col>
              <Col
                md={12}
                className="d-flex justify-content-between align-items-center my-2 px-0 trade-cart-market-value"
              >
                <span>{`Market Value`}</span>
                <span>
                  {`$${parseToThousandSeperatorDecimalNumber(
                    newTradeData?.totalMarketPrice ?? 0
                  )}`}
                </span>
              </Col>
              <Col md={12} className="px-0">
                <button
                  type="button"
                  className="d-flex justify-content-between align-items-center w-100 main-purple-button"
                  onClick={() => handlePayClick()}
                >
                  <div>
                    <CashIcon />
                    <span className="ms-1">Pay</span>
                    <span className="ms-1">
                      {getCountOfTradeTotalItems(
                        newTradeData?.inventory || 0,
                        "quantity"
                      )}{" "}
                      items
                    </span>
                  </div>
                  <span>
                    $
                    {parseToThousandSeperatorDecimalNumber(
                      newTradeData.SubTotal || 0
                    )}
                  </span>
                </button>
              </Col>
            </Row>
          </Row>
        </Col>
      </Row>

      {addTradeModal && (
        <AddTrade
          addToCart={addToCart}
          customTypes={customTypes}
          productToEdit={productToEdit}
          tradeDiscount={tradeDiscount}
          addTradeModal={addTradeModal}
          handleTradeData={updateTradeData}
          customCategories={customCategories}
          toggleAddTradeModal={toggleAddTradeModal}
        />
      )}
      {customItemModal && (
        <AddTradeCustomItem
          addItemInCart={addToCart}
          newTradeData={newTradeData}
          tradeDiscount={tradeDiscount}
          productToEdit={productToEdit}
          handleTradeData={updateTradeData}
          customItemModal={customItemModal}
          toggleCustomItemModal={toggleCustomItemModal}
        />
      )}
      {quickTradeInModal && (
        <AddQuickTradeIn
          addItemInCart={addToCart}
          newTradeData={newTradeData}
          productToEdit={productToEdit}
          tradeDiscount={tradeDiscount}
          handleTradeData={updateTradeData}
          quickTradeInModal={quickTradeInModal}
          toggleQuickTradeInModal={toggleQuickTradeInModal}
        />
      )}
      {isTradeBatchInventoryModal && (
        <AddTradeBatch
          addToCart={addToCart}
          customTypes={customTypes}
          tradeDiscount={tradeDiscount}
          customCategories={customCategories}
        />
      )}
      {addCustomerModal && (
        <AddNewCustomerModal
          selectedCustomer={selectedCustomer}
          addCustomerModal={addCustomerModal}
          customerToEdit={customerToEdit}
        />
      )}
      <ConfirmationModal
        message={toastMessages.RESET_CART_WARNING}
        isConfirmationModal={isConfirmationModal}
        handleNoButtonClick={handleConfirmNoButtonClick}
        handleYesButtonClick={handleConfirmYesButtonClick}
        toggleConfirmationModal={toggleConfirmationModal}
      />
    </>
  );
}; //-------NewTransaction component end here

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  customer: state.customer,
  inventory: state.inventory,
  transaction: state.transaction,
  user: state.authentication.user,
  tradeDiscount: state.tradeDiscount,
  currentStore: state.store.currentStore,
  itemOrganization: state.itemOrganization,
  allCustomers: state.customer.allCustomers,
  newTradeData: state.transaction.newTradeData,
  customTypes: state.itemOrganization.customTypes,
  isAddCustomerModal: state.transaction.isAddCustomerModal,
  customCategories: state.itemOrganization.customCategories,
  addTradeBatchInventory: state.transaction.addTradeBatchInventory,
  isTradeBatchInventoryModal: state.transaction.isTradeBatchInventoryModal,
  company: state.authentication.company,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  toggleAddModal: (value) => dispatch(inventoryActions.toggleAddModal(value)),
  getPricechartingProductById: (productMetaData, productId) =>
    dispatch(
      inventoryActions.getPricechartingProductById(productMetaData, productId)
    ),
  setPokemonProductInReducer: (productMetaData, value) =>
    dispatch(
      inventoryActions.setPokemonProductInReducer(productMetaData, value)
    ),
  getScryFallProductById: (productMetaData, productId) =>
    dispatch(
      inventoryActions.getScryFallProductById(productMetaData, productId)
    ),
  setNewTradeData: (data, storeId) =>
    dispatch(transactionActions.setNewTradeData(data, storeId)),
  toggleAddCustomerModal: (value) =>
    dispatch(transactionActions.toggleAddCustomerModal(value)),
  newTradePark: (data, storeId) =>
    dispatch(transactionActions.newTradePark(data, storeId)),
  setIsTradeBatchInventoryModal: (isModalOpen) =>
    dispatch({
      type:
        transactionConstants.SET_IS_TRADE_BATCH_INVENTORY +
        globalConstants.SUCCESS,
      data: isModalOpen,
    }),
  setAddTradeBatchInventory: (data, storeId) =>
    dispatch(transactionActions.setAddTradeBatchInventory(data, storeId)),
  getTags: (storeId) => dispatch(inventoryActions.getTags(storeId)),
});

//-------Export transaction Component
export default connect(mapStateToProps, mapDispatchToProps)(NewTrade);

export const getUpdatedCartItems = (prevCartItems, addToCartData) => {
  let addToCartDataWithMax = {
    ...addToCartData,
    max: 9999,
    minCashOffer: addToCartData.cashOffer,
    minTradeOffer: addToCartData.tradeOffer,
    sku: [transactionConstants.AUTO_GENERATED],
    id: prevCartItems?.inventory.length + 1, // Assign a new ID starting from 1
  };

  return {
    ...prevCartItems,
    inventory: [...prevCartItems?.inventory, addToCartDataWithMax],
  };
};

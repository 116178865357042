import React from "react";
import { Col } from "react-bootstrap";

import Button from "../../../../shared/components/Buttons/Button";
import { DollarIcon } from "../../../../assets/icons/iconsProvider";
import InputNumberField from "../../../../shared/components/inputNumberField/InputNumberField";
import {
  buttonNameConstants,
  buttonTypeConstants,
} from "../../../../constants";
import { isEmployeeLogin } from "../../../../shared/utility";

const CustomerCreditAndSubmitButtons = (props) => {
  const {
    user,
    resetForm,
    handleHideModal,
    customerToEdit,
    mdColSize = 6,
  } = props;

  return (
    <Col md={mdColSize} className="mb-3 mx-auto">
      <p className="form-portion-heading mb-1">Customer Credit</p>
      <InputNumberField
        name="currentBalance"
        label="Current Balance"
        placeHolder="Current Balance"
        IconImage={DollarIcon}
        disabled={isEmployeeLogin(user)}
      />
      <div className="d-flex justify-content-center align-items-center gap-2 mt-3">
        <Button
          type="button"
          label={buttonNameConstants.CANCEL}
          buttonType={buttonTypeConstants.GHOST_BUTTON}
          className="w-100"
          handleClick={() => {
            resetForm();
            handleHideModal();
          }}
        ></Button>
        <Button
          type="submit"
          className="w-100"
          label={
            customerToEdit?.id
              ? buttonNameConstants.UPDATE
              : buttonNameConstants.SAVE
          }
        ></Button>
      </div>
    </Col>
  );
};

export default CustomerCreditAndSubmitButtons;

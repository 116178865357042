import { transactionConstants } from "../../../constants";
import {
  getItemsList,
  prepareItemRow,
  getTotalItemsQuantity,
  alignValueLeftAndRight,
  getPrintReceiptHeaderSection,
} from "./prepareReceiptCommon";

export const prepareReceiptForPrinterTrade = (
  user,
  id,
  type,
  storeDetails,
  selectedCustomer,
  receiptItems,
  discountAmount,
  subTotal,
  taxAmount,
  totalPrice,
  cashTenderedAmount,
  cashOwedAmount,
  openCashDrawer,
  partialPayment,
  giftReceipt,
  printTime,
  isReprint = false
) => {
  const dateTime = printTime;
  const currency = "$";

  const receiptData = [
    ...getPrintReceiptHeaderSection({
      id,
      type,
      user,
      dateTime,
      isReprint,
      storeDetails,
      openCashDrawer,
      selectedCustomer,
    }),
    //Items List
    `${prepareItemRow("Item", "Qty", !giftReceipt ? "Price" : "")}`,
    "\x1B" + "\x45" + "\x0A", // bold off
    "\x0A", // line break
    `${getItemsList(receiptItems, giftReceipt).join("")}`,
    // `${getItemsList(receiptItems).join("")}`, // line break
    "__________________________________________" + "\x0A",
    "\x0A", // line break
    //Total Items
    giftReceipt
      ? ""
      : `${alignValueLeftAndRight(
          "Total Items :",
          `${getTotalItemsQuantity(receiptItems)}`
        )}` +
        "\x0A" +
        // Subtotal
        `${
          !partialPayment
            ? alignValueLeftAndRight(
                "Subtotal :",
                `${currency}${Number(subTotal).toFixed(2)}`
              )
            : ""
        }` +
        "\x0A" +
        // Discount
        `${alignValueLeftAndRight(
          `Discount :`,
          `${currency}${Number(discountAmount).toFixed(2)}`
        )}` +
        "\x0A" +
        // Tax
        `${alignValueLeftAndRight(
          `Tax(${storeDetails.defaultTax}%) :`,
          `${currency}${Number(taxAmount).toFixed(2)}`
        )}` +
        "\x0A" +
        // Total Amount
        `${alignValueLeftAndRight(
          "Total Amount :",
          `${currency}${Number(totalPrice).toFixed(2)}`
        )}` +
        "\x0A" +
        "__________________________________________" +
        "\x0A",

    //Paymnet History
    giftReceipt
      ? ""
      : `Payment :` +
        "\x0A" +
        `${
          type?.totalPaidAmount[transactionConstants.CREDIT_CARD] > 0
            ? `${alignValueLeftAndRight(
                "Paid Credit Card:",
                `${currency}${
                  type?.totalPaidAmount[transactionConstants.CREDIT_CARD]
                }`
              )}` + "\x0A"
            : ""
        }` +
        `${
          type?.totalPaidAmount[transactionConstants.GIFT_CARD] > 0
            ? `${alignValueLeftAndRight(
                "Paid Gift Card:",
                `${currency}${
                  type?.totalPaidAmount[transactionConstants.GIFT_CARD]
                }`
              )}` + "\x0A"
            : ""
        }` +
        `${
          type?.totalPaidAmount[transactionConstants.STORE_CREDIT] > 0
            ? `${alignValueLeftAndRight(
                "Paid Store Credit:",
                `${currency}${
                  type?.totalPaidAmount[transactionConstants.STORE_CREDIT]
                }`
              )}` + "\x0A"
            : ""
        }` +
        `${
          type?.totalPaidAmount[transactionConstants.CASH] > 0
            ? `${alignValueLeftAndRight(
                "Paid Cash:",
                `${currency}${type?.totalPaidAmount[transactionConstants.CASH]}`
              )}` + "\x0A"
            : ""
        }` +
        "__________________________________________" +
        "\x0A",

    "\x0A", // line break

    "\x1B" + "\x61" + "\x31", // center align
    "\x1D\x68\x64", // Set barcode height (104 dots)
    "\x1D\x77\x02", // Set barcode width (module 2)
    `\x1D\x6B\x45\x0B${id}\x00`,
    "\x0A", // line break
    `${id}` + "\x0A" + "\x0A",
    //Return Policy
    // `${storeDetails?.returnPolicy ? storeDetails.returnPolicy : ""}`,
    "\x0A" + "\x0A" + "\x0A" + "\x0A" + "\x0A" + "\x0A" + "\x0A",
    "\x1B" + "\x69", // cut paper (old syntax)
    //"\x1D" + "\x56" + "\x01", // partial cut (new syntax)
  ];

  return receiptData;
};

import { getDecryptCompanyPayload, getDecryptPayload } from "./encryption";
import { authenticationConstants, globalConstants } from "../constants";

export const authenticationHeader = () => {
  const payload = getDecryptPayload();
  const companyPayload = getDecryptCompanyPayload();

  const token = payload?.accessToken || companyPayload.accessToken;

  // Check for valid tokens and return the appropriate authorization header
  if (token) {
    return { Authorization: `${authenticationConstants.BEARER} ${token}` };
  }

  return globalConstants.EMPTY_OBJECT;
};

import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router";
import React, { useEffect, useState, useRef } from "react";

import { companyActions } from "../../../redux/actions";
import Button from "../../../shared/components/Buttons/Button";
import Topbar from "../../../shared/components/topbar/Topbar";
import SearchField from "../../../shared/components/searchField/SearchField";
import CustomTooltip from "../../../shared/components/toolTipComponent/CustomTooltip";
import SelectDropDown from "../../../shared/components/selectDropdown/SelectDropdown";
import DataTableComponent from "../../../shared/components/table/ReactDataTableComponent";
import DropdownSkeleton from "../../../shared/Skeletons/DropdownSkeleton/DropdownSkeleton";
import { editableTableSelectFieldStyle } from "../../../assets/scss/style/editableTableSelectField";
import {
  globalConstants,
  buttonNameConstants,
  buttonTypeConstants,
  routesPathConstants,
  companyContants,
} from "../../../constants";
import {
  AddIcon,
  EditIcon,
  CrossIcon,
  DeleteIcon,
  SearchIcon,
  BlueBoxIcon,
  ActionDotsIcons,
} from "../../../assets/icons/iconsProvider";
import {
  createCompanyDynamicUrl,
  isSpinnerEnabled,
} from "../../../shared/utility";

const defaultGridState = {
  pageSize: 50,
  pageIndex: 0,
  sortBy: [],
};

//-------AdminDevices component start here
const Companies = (props) => {
  const { store, company, deleteCompany, getAllCompanies, spinnerArray } =
    props;
  //------use Navigate to navigate to other screens
  const navigate = useNavigate();
  const [columns, setColumns] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [gridState, setGridState] = useState(defaultGridState);
  const [defaultSortFieldId, setDefaultSortFieldId] = useState();

  //-------useRef
  let numOfRenders = useRef(0);

  //-------store Reducer
  const { stores } = store;

  //-------store Reducer
  const { companies } = company;
  //-------Handle AdminDevices edit button click
  const handleEditClick = (row) => {
    navigate(
      createCompanyDynamicUrl(routesPathConstants.ADMIN_COMPANIES_EDIT),
      {
        state: {
          data: row,
          isEdit: true,
        },
      }
    );
  };

  //-------Handle AdminDevices delete button click
  const handleDeleteClick = (row) => {
    if (row?.id) {
      deleteCompany(row.id);
      // reset pagination
      numOfRenders.current++;
    }
  };

  //-------Handle add button click
  const handleAddClick = () => {
    navigate(createCompanyDynamicUrl(routesPathConstants.ADMIN_COMPANIES_ADD), {
      state: {
        isEdit: false,
      },
    });
  };

  //-------Hanlde Table Sorting
  const onTableSort = (column, sortDirection) => {
    setColumns(
      columns.map((col) => {
        if (col.id === column.id) {
          return {
            ...col,
            sortDirection,
          };
        } else {
          return {
            ...col,
            sortDirection: null,
          };
        }
      })
    );
    setDefaultSortFieldId(column.id);
  };

  //-------Update GridState
  const updateGridState = (newGridState) => {
    setGridState(newGridState);
  };

  //-------Handle searchfield
  const handleFilterData = (e) => {
    const filterList = companies.filter((row) => {
      return (
        String(
          row.companyName +
            row.domainUrl +
            row.ownerName +
            row.email +
            row.phoneNumber +
            row.line1 +
            row.city +
            row.state
        )
          .toLowerCase()
          .includes(String(searchText).toLowerCase()) &&
        searchText !== globalConstants.EMPTY_STRING
      );
    });
    // reset pagination
    numOfRenders.current++;
    return filterList;
  };
  const clearSearchText = () => {
    setSearchText("");
  };
  //-------define column headers for the devices table
  const columnHeaders = [
    {
      id: "companyName",
      name: "Company Name",
      selector: (row) => row.companyName,
      cell: (row) => {
        return (
          <span className="inventory-cell-max-size">{row?.companyName}</span>
        );
      },
      sortable: true,
      sortDirection: null,
    },
    {
      id: "domainUrl",
      name: "Domain Url",
      selector: (row) => row.domainUrl,
      cell: (row) => {
        return (
          <span className="inventory-cell-max-size">{row?.domainUrl}</span>
        );
      },
      sortable: true,
      sortDirection: null,
    },
    {
      id: "ownerName",
      name: "Owner Name",
      selector: (row) => row.ownerNamePlaceholder,
      cell: (row) => {
        return (
          <span className="inventory-cell-max-size">
            {row?.ownerNamePlaceholder}
          </span>
        );
      },
      sortable: true,
      sortDirection: null,
    },
    {
      id: "email",
      name: "Email",
      selector: (row) => row.email,
      cell: (row) => {
        return <span className="inventory-cell-max-size">{row?.email}</span>;
      },
      sortable: true,
      sortDirection: null,
    },
    {
      id: "phoneNumber",
      name: "Phone Number",
      selector: (row) => row.phoneNumber,
      cell: (row) => {
        return (
          <span className="inventory-cell-max-size">{row?.phoneNumber}</span>
        );
      },
      sortable: true,
      sortDirection: null,
    },
    {
      id: "address",
      name: "Address",
      selector: (row) => row.line1,
      cell: (row) => {
        return (
          <span className="inventory-cell-max-size">{`${row?.line1} ${row?.line2}`}</span>
        );
      },
      sortable: true,
      sortDirection: null,
    },
    {
      id: "city",
      name: "City",
      selector: (row) => row.city,
      cell: (row) => {
        return <span className="inventory-cell-max-size">{row?.city}</span>;
      },
      sortable: true,
      sortDirection: null,
    },
    {
      id: "state",
      name: "State",
      selector: (row) => row.state,
      cell: (row) => {
        return <span className="inventory-cell-max-size">{row?.state}</span>;
      },
      sortable: true,
      sortDirection: null,
    },
    {
      id: "action",
      name: "",
      cell: (row) => {
        return (
          <>
            <CustomTooltip anchorSelect={`#tool${row.id}`}>
              <Button
                className="w-100"
                IconImage={EditIcon}
                label={buttonNameConstants.EDIT}
                handleClick={() => handleEditClick(row)}
                buttonType={buttonTypeConstants.ACTION_BUTTON}
              />
              <Button
                className="w-100"
                IconImage={DeleteIcon}
                label={buttonNameConstants.DELETE}
                handleClick={() => handleDeleteClick(row)}
                buttonType={buttonTypeConstants.ACTION_BUTTON}
              />
            </CustomTooltip>
            <button id={`tool${row.id}`} className="action-Button-Wrapper">
              <ActionDotsIcons className="cursor-pointer" />
            </button>
          </>
        );
      },
      sortable: false,
      width: "5%",
    },
  ];
  //-------useEffects
  useEffect(() => {
    getAllCompanies();
    setColumns(columnHeaders);
    // eslint-disable-next-line
  }, []);

  //-------Return AdminDevices Component
  return (
    <>
      <Topbar title="Companies"></Topbar>
      <Row className="m-0 w-100 user-screen-wrapper">
        {isSpinnerEnabled(spinnerArray, companyContants.GET_ALL_COMPANIES) ? (
          <>
            <Col md={5}>
              <DropdownSkeleton width={"100%"} />
            </Col>
            <Col md={3}></Col>
            <Col
              md={4}
              className="d-flex justify-content-end align-items-center gap-3"
            >
              <DropdownSkeleton />
            </Col>
          </>
        ) : (
          <>
            <Col md={5}>
              <SearchField
                label="Search"
                placeHolder="Type or Scan"
                IconImage={SearchIcon}
                onChange={(e) => setSearchText(e.target.value)}
                value={searchText}
                CrossIcon={searchText ? CrossIcon : ""}
                clearSearchText={clearSearchText}
                BlueBoxIcon={BlueBoxIcon}
              />
            </Col>
            <Col md={3}></Col>
            <Col
              md={4}
              className="d-flex justify-content-end align-items-end gap-3"
            >
              <Button
                IconImage={AddIcon}
                handleClick={handleAddClick}
                label={buttonNameConstants.ADD}
              />
            </Col>
          </>
        )}
      </Row>
      <Row className="m-0 w-100 h-100 user-screen-wrapper pt-3">
        <DataTableComponent
          columns={columns}
          onSort={onTableSort}
          gridState={gridState}
          className="editable-table customers-table"
          key={numOfRenders.current}
          setGridState={updateGridState}
          defaultSortFieldId={defaultSortFieldId}
          data={searchText ? handleFilterData() : companies}
          total={searchText ? handleFilterData()?.length : companies?.length}
        />
      </Row>
    </>
  );
}; //-------AdminDevices component end here

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  store: state.store,
  company: state.company,
  spinnerArray: state.system.spinnerArray,
  storeCompany: state.authentication.company,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getAllCompanies: () => dispatch(companyActions.getAllCompanies()),
  deleteCompany: (companyId) => {
    dispatch(companyActions.deleteCompany(companyId));
  },
});

//-------Export AdminDevices Component
export default connect(mapStateToProps, mapDispatchToProps)(Companies);

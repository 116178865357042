import React, { useState } from "react";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";

import SelectField from "../../../../shared/components/selectField/SelectField";
import MultiSelectField from "../../../../shared/components/multiSelectField/MultiSelectField";
import Button from "../../../../shared/components/Buttons/Button";
import InputTextField from "../../../../shared/components/inputTextField/InputTextField";
import InputPasswordField from "../../../../shared/components/inputPasswordField/InputPasswordField";
import {
  buttonNameConstants,
  buttonTypeConstants,
  globalConstants,
  ROLES,
  routesPathConstants,
  systemUserConstants,
} from "../../../../constants";
import {
  addSystemUserValidationSchema,
  updateSystemUserValidationSchema,
  updateSystemUserWithPasswordValidationSchema,
} from "../../../../shared/validationSchema/validationSchema";
import { systemUserActions } from "../../../../redux/actions";
import { useLocation, useNavigate } from "react-router";
import Topbar from "../../../../shared/components/topbar/Topbar";
import { decryptObject } from "../../../../system/encryption";
import {
  createCompanyDynamicUrl,
  isSpinnerEnabled,
} from "../../../../shared/utility";

//-------AddNewSystemUser component start here
const AddNewSystemUser = (props) => {
  const { addNewSystemUser, updateSystemUser, store, spinnerArray } = props;
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isEditPassword, setIsEditPassword] = useState(false);

  //------Store reducer
  const { stores } = store;
  //-------Initial state of user form
  const initialValues = {
    role: ROLES.ADMIN,
    firstName: state?.data?.firstName || globalConstants.EMPTY_STRING,
    lastName: state?.data?.lastName || globalConstants.EMPTY_STRING,
    email: state?.data?.email || globalConstants.EMPTY_STRING,
    password: state?.isEdit
      ? decryptObject(
          state?.data?.password ?? globalConstants.EMPTY_STRING,
          process.env.REACT_APP_PASSWORD_ENCRYPTED_KEY
        )
      : globalConstants.EMPTY_STRING,
    stores: [],
    confirmPassword: globalConstants.EMPTY_STRING,
    companyUserId: 0,
    companyId: systemUserConstants.ADMIN_SYSTEMUSERS,
  };

  //-------handle submit for add new user form
  const handleSubmit = (data) => {
    delete data["confirmPassword"];
    if (state?.isEdit) {
      updateSystemUser({
        ...data,
        id: state?.data?.id,
        email: data.email.toLowerCase(),
        password: isEditPassword ? data.password : globalConstants.EMPTY_STRING,
      });
    } else {
      addNewSystemUser({
        ...data,
        email: data.email.toLowerCase(),
      });
    }
  };

  //-------handle cancel button click
  const handleCancelButton = () => {
    navigate(createCompanyDynamicUrl(routesPathConstants.ADMIN_SYSTEMUSERS));
  };

  const handleEditPasswordClick = (setFieldValue) => {
    setIsEditPassword(!isEditPassword);
    if (isEditPassword) {
      const newPassword = decryptObject(
        state?.data?.password ?? globalConstants.EMPTY_STRING,
        process.env.REACT_APP_PASSWORD_ENCRYPTED_KEY
      );
      setFieldValue("password", newPassword);
    } else {
      setFieldValue("password", globalConstants.EMPTY_STRING);
    }
  };

  //-------Return AddNewSystemUser Component
  return (
    <>
      <Topbar
        title={state?.isEdit ? "Edit Superadmin" : "New Superadmin"}
        backNavigation={{
          isVisible: true,
          route: routesPathConstants.ADMIN_SYSTEMUSERS,
        }}
      />
      <Row className="m-0 w-100 p-4">
        <Formik
          initialValues={initialValues}
          validate={
            state?.isEdit
              ? isEditPassword
                ? updateSystemUserWithPasswordValidationSchema
                : updateSystemUserValidationSchema
              : addSystemUserValidationSchema
          }
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Row className="m-0">
                {/* <Col md={6} className="mb-3">
                  <SelectField
                    label="Role"
                    name="role"
                    placeHolder={"Select Role"}
                    options={systemUserConstants.USER_ROLES}
                  />
                </Col> */}
                <Col md={6} className="mb-3">
                  <InputTextField
                    name="firstName"
                    label="First Name"
                    placeHolder="Enter First Name"
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <InputTextField
                    name="lastName"
                    label="Last Name"
                    placeHolder="Enter Last Name"
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <InputTextField
                    name="email"
                    label="Email"
                    placeHolder="Email"
                  />
                </Col>
                <Col md={6} className="mb-3 d-flex gap-2">
                  <InputPasswordField
                    name="password"
                    label="Password"
                    placeHolder="Enter Password"
                    className="w-100"
                    isDisabled={state?.isEdit && !isEditPassword}
                  />
                  {state?.isEdit
                    ? !isEditPassword && (
                        <Button
                          type="button"
                          label={buttonNameConstants.EDIT}
                          handleClick={() => {
                            handleEditPasswordClick(setFieldValue);
                          }}
                          className="edit-password-button"
                        />
                      )
                    : ""}
                </Col>
                {isEditPassword ? (
                  <Col md={6} className="mb-3">
                    <div className="d-flex w-100 gap-2">
                      <InputPasswordField
                        name="confirmPassword"
                        label="Confirm Password"
                        className="w-100"
                        placeHolder="Enter Confirm Password"
                      />
                      <Button
                        type="button"
                        label={buttonNameConstants.CANCEL}
                        handleClick={() => {
                          handleEditPasswordClick(setFieldValue);
                        }}
                        className="edit-password-button"
                        buttonType={buttonTypeConstants.GHOST_BUTTON}
                      ></Button>
                    </div>
                  </Col>
                ) : (
                  ""
                )}

                {/* {values.role === "store-owner" ? (
                  <Col md={6} className="mb-3">
                    <MultiSelectField
                      label="Store"
                      name="stores"
                      placeHolder={"Select Store"}
                      options={storeList?.map((store) => {
                        return {
                          value: store.id,
                          label: store.storeName,
                        };
                      })}
                    />
                  </Col>
                ) : ( */}
                {/* {!isEditPassword && <Col md={6}></Col>} */}
                {/* )} */}
                <Col
                  md={6}
                  className={`mb-3 d-flex gap-2 ${
                    isEditPassword && "edit-password-button"
                  }`}
                >
                  <Button
                    type="button"
                    label={buttonNameConstants.CANCEL}
                    handleClick={() => {
                      handleCancelButton();
                    }}
                    buttonType={buttonTypeConstants.GHOST_BUTTON}
                    className="w-100"
                    isDisabled={
                      isSpinnerEnabled(
                        spinnerArray,
                        systemUserConstants.UPDATE_SYSTEM_USER
                      ) ||
                      isSpinnerEnabled(
                        spinnerArray,
                        systemUserConstants.ADD_NEW_SYSTEM_USER
                      )
                    }
                  ></Button>
                  <Button
                    type="submit"
                    className="w-100"
                    label={
                      state?.isEdit
                        ? buttonNameConstants.UPDATE
                        : buttonNameConstants.SAVE
                    }
                    isDisabled={
                      isSpinnerEnabled(
                        spinnerArray,
                        systemUserConstants.UPDATE_SYSTEM_USER
                      ) ||
                      isSpinnerEnabled(
                        spinnerArray,
                        systemUserConstants.ADD_NEW_SYSTEM_USER
                      )
                    }
                  ></Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Row>
    </>
  ); //-------AddNewSystemUser component end here
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  store: state.store,
  spinnerArray: state.system.spinnerArray,
  company: state.authentication.company,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  addNewSystemUser: (data) =>
    dispatch(systemUserActions.addNewSystemUser(data)),
  updateSystemUser: (data) =>
    dispatch(systemUserActions.updateSystemUser(data)),
});

//-------Export AddNewSystemUser Component
export default connect(mapStateToProps, mapDispatchToProps)(AddNewSystemUser);

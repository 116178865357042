export const IMPORTED_FROM_FILE = "IMPORTED_FROM_FILE";
export const EMPTY_STRING = "";
export const LOGS_TYPES = {
  ERROR_BOUNDARY_LOGS: "ERROR_BOUNDARY_LOGS",
  CLIENT_REPORTED_LOGS: "CLIENT_REPORTED_LOGS",
  CUSTOMER_LICENSE_SCAN_LOGS: "CUSTOMER_LICENSE_SCAN_LOGS",
};
export const MAXIMUM_VALUE_FOR_NUMBER_INPUT_FIELD = 999999999;
export const TRUE_STRING = "true";
export const FALSE_STRING = "false";

import { connect } from "react-redux";
import React from "react";

import NestedSidebarBody from "./NestedSidebarBody";
import { SidebarToggleIcon } from "../../../assets/icons/iconsProvider";
import {
  NESTED_SIDEBAR_OPTIONS,
  routesPathConstants,
  systemConstants,
  EMPLOYEE_NOT_ALLOWED_NESTED_SIDEBAR_TABS,
} from "../../../constants";
import { STORE_CARD_PAYMENT_TYPE_ENUMS } from "../../../system/globalEnums";
import {
  isAdminLogin,
  isEmployeeLogin,
  isManagerLogin,
  isStoreOwnerLogin,
} from "../../../shared/utility";

const NestedSidebar = (props) => {
  const {
    activeSidebarBtn,
    currentStore: { activeCardPaymentType },
    user,
  } = props;
  let NESTED_SIDEBAR_OPTIONS_LOCAL = { ...NESTED_SIDEBAR_OPTIONS };

  // only shows settings devices if the current store activeCardPaymentType is VERIFONE
  if (
    !isEmployeeLogin(user) &&
    activeCardPaymentType &&
    activeCardPaymentType === STORE_CARD_PAYMENT_TYPE_ENUMS.THIRD_PARTY
  ) {
    NESTED_SIDEBAR_OPTIONS_LOCAL.SETTINGS = [
      ...NESTED_SIDEBAR_OPTIONS_LOCAL.SETTINGS.filter((nestedSidebarTab) => {
        if (nestedSidebarTab.route === routesPathConstants.SETTINGS_DEVICES) {
          return false;
        } else {
          return true;
        }
      }),
    ];
  }

  // store owner not allowed
  if (!isAdminLogin(user)) {
  }
  // manager owner not allowed
  if (!isAdminLogin(user) && !isStoreOwnerLogin(user)) {
  }
  // employee owner not allowed
  if (
    !isStoreOwnerLogin(user) &&
    !isAdminLogin(user) &&
    !isManagerLogin(user)
  ) {
    // updating NESTED_SIDEBAR_OPTIONS and removing employee not allowed routes in settings nested sidebar body
    NESTED_SIDEBAR_OPTIONS_LOCAL.SETTINGS = [
      ...NESTED_SIDEBAR_OPTIONS_LOCAL.SETTINGS.filter((nestedSidebarTab) => {
        return !EMPLOYEE_NOT_ALLOWED_NESTED_SIDEBAR_TABS.includes(
          nestedSidebarTab.route
        );
      }),
    ];
  }

  return (
    <>
      {NESTED_SIDEBAR_OPTIONS_LOCAL[activeSidebarBtn] &&
        NESTED_SIDEBAR_OPTIONS_LOCAL[activeSidebarBtn].length > 0 && (
          <div
            className={`nested-sidebar p-0 m-0  ${
              props.isNestedSidebarOpen
                ? "nested-sidebar-opened"
                : "nested-sidebar-closed"
            }`}
          >
            <NestedSidebarBody
              tabs={NESTED_SIDEBAR_OPTIONS_LOCAL[activeSidebarBtn]}
            />
            {/* <div
              className={`toggle-sidebar-btn toggle-nested-sidebar-btn ${
                props.isNestedSidebarOpen && "toggle-sidebar-rotate"
              }`}
              onClick={() => props.toggleNestedSidebar()}
            >
              <img src={sidebarToggleIcon} alt="sidebar toggle" />
            </div> */}
            <div
              className={`toggle-sidebar-btn toggle-nested-sidebar-btn ${
                props.isNestedSidebarOpen && "toggle-sidebar-rotate"
              }`}
              onClick={() => props.toggleNestedSidebar()}
            >
              <SidebarToggleIcon className="text-white toggle-icon" />
            </div>
          </div>
        )}
    </>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  activeSidebarBtn: state.system.activeSidebarBtn,
  isNestedSidebarOpen: state.system.isNestedSidebarOpen,
  user: state.authentication.user,
  currentStore: state.store.currentStore,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  toggleNestedSidebar: (data) =>
    dispatch({ type: systemConstants.TOGGLE_NESTED_SIDEBAR }),
});

export default connect(mapStateToProps, mapDispatchToProps)(NestedSidebar);

import React from "react";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import { Row, Col } from "react-bootstrap";

import { authenticationActions } from "../../redux/actions";
import Button from "../../shared/components/Buttons/Button";
import {
  authenticationConstants,
  buttonNameConstants,
  buttonTypeConstants,
  EMPTY_STRING,
  routesPathConstants,
  systemConstants,
} from "../../constants";
import InputTextField from "../../shared/components/inputTextField/InputTextField";
import { forgotPasswordValidationSchema } from "../../shared/validationSchema/validationSchema";
import { SIDEBAR_BUTTONS } from "../../constants";
import LogoImage from "../../assets/images/LoginLogo.png";
import { isSpinnerEnabled } from "../../shared/utility";
import { useNavigate } from "react-router";

//-------ForgotPassword component start here
const ForgotPassword = (props) => {
  const initialValues = {
    email: "",
  };
  const navigate = useNavigate();
  //-------Handle handleSendEmail click
  const handleSendEmail = (values) => {
    props.forgotPassword(
      {
        email: values.email.toLowerCase(),
        userId: EMPTY_STRING,
      },
      EMPTY_STRING
    );
  };

  const handleCancelButtonClick = () => {
    navigate(routesPathConstants.LOGIN);
  };

  //-------Return ForgotPassword Component
  return (
    <div>
      <div className=" login-card-wrapper row d-flex justify-content-center align-items-center m-0 p-4 min-vh-100 min-vw-100">
        <img
          className="login-collect-pos-icon"
          src={LogoImage}
          alt="collect pos icon"
        />
        <div className="login-card col-sm-8 col-md-6 col-lg-4 py-4 px-5 pb-3 d-flex flex-column justify-content-center align-items-center rounded-4">
          <h1>Forgot Your Password?</h1>
          <p className="opacity-75">
            Enter your Email Address to receive a password reset link.
          </p>
          <Formik
            initialValues={initialValues}
            validate={forgotPasswordValidationSchema}
            onSubmit={handleSendEmail}
          >
            <Form className="login-form-wrapper">
              <Row className="w-100 m-0">
                <Col xs={12} className="p-0 mb-3">
                  <InputTextField
                    type="email"
                    name="email"
                    label="Email"
                    placeHolder="Email Address"
                  />
                </Col>
                <Col xs={12} className="p-0 mt-3 ">
                  <Button
                    type="submit"
                    className="w-100"
                    label={buttonNameConstants.SEND_EMAIL}
                    buttonType={buttonTypeConstants.BLACK_BUTTON}
                    isDisabled={isSpinnerEnabled(
                      props.spinnerArray,
                      authenticationConstants.FORGOT_PASSWORD
                    )}
                  />
                </Col>
                <Col
                  xs={12}
                  className="p-0 mt-2 mb-0 d-flex align-items-center"
                >
                  <span
                    className="forgot-password-link"
                    onClick={handleCancelButtonClick}
                  >
                    Cancel
                  </span>
                </Col>
                <Col
                  xs={12}
                  className="p-0 mt-4 mb-0 d-flex justify-content-center align-items-center text-muted"
                >
                  Powered by CollectPOS
                </Col>
              </Row>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
}; //-------ForgotPassword component end here

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  spinnerArray: state.system.spinnerArray,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  forgotPassword: (data, companyId) =>
    dispatch(authenticationActions.forgotPassword(data, companyId)),
});

//-------Export ForgotPassword Component
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);

import { inventoryService } from "../../services";
import {
  customToast,
  exportTagFunc,
  exportStringArrayFunc,
  getCountOfTradeTotalItems,
  addSearchKeyToArrayObject,
  beautifyStringForImport,
  handleIsLabelNotificationOnEditInventory,
  exportUpcFunc,
} from "../../shared/utility";
import { custom, failure, success } from "./actionsUtilities";
import {
  systemConstants,
  inventoryConstants,
  toastType,
  toastMessages,
  routesPathConstants,
  globalConstants,
  defaultGridState,
} from "../../constants";
import { systemActions } from "./system.action";
import { storeActions } from "./store.action";
import { downloadCSV } from "../../shared/utility";
import { NOTIFICATION_TYPES } from "../../system/globalEnums";

//-------get All inventory of current store
const getAllInventory = (storeId, isInventorySummaryApiCall = true) => {
  return (dispatch) => {
    dispatch(
      custom(
        inventoryConstants.GET_ALL_INVENTORY,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    inventoryService
      .getAllInventory(storeId)
      .then(
        (response) => {
          dispatch(
            success(
              addSearchKeyToArrayObject(response, ["sku", "product_name"]),
              inventoryConstants.GET_ALL_INVENTORY
            )
          );
          isInventorySummaryApiCall &&
            dispatch(getStoreInventorySummary(storeId));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            inventoryConstants.GET_ALL_INVENTORY,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

const updateInventory = (
  storeId,
  data,
  isLabelNotification,
  gridState = defaultGridState,
  navigate = false
) => {
  return (dispatch) => {
    dispatch(
      custom(
        inventoryConstants.UPDATE_INVENTORY,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    inventoryService
      .updateInventory(storeId, data)
      .then(
        (response) => {
          if (navigate) {
            navigate(routesPathConstants.INVENTORY, {
              state: {
                gridState: gridState,
              },
            });
          }
          dispatch(getStoreInventorySummary(storeId));
          dispatch(getPaginatedInventory(storeId, gridState));
          if (
            handleIsLabelNotificationOnEditInventory(
              response,
              isLabelNotification
            )
          ) {
            dispatch(
              storeActions.getUserNotifications(
                storeId,
                true,
                inventoryConstants.PRODUCT_UPDATED_SUCCESSFULLY,
                true
              )
            );
          } else {
            customToast(
              inventoryConstants.PRODUCT_UPDATED_SUCCESSFULLY,
              toastType.SUCCESS
            );
          }
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            inventoryConstants.UPDATE_INVENTORY,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

const updatePriceChangeProduct = (
  storeId,
  data,
  isLabelNotification,
  gridState
) => {
  return (dispatch) => {
    dispatch(
      custom(
        inventoryConstants.UPDATE_INVENTORY,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    inventoryService
      .updateInventory(storeId, data)
      .then(
        (response) => {
          dispatch(getPaginatedInventory(storeId, gridState, true));
          if (
            handleIsLabelNotificationOnEditInventory(
              response,
              isLabelNotification
            )
          ) {
            dispatch(
              storeActions.getUserNotifications(
                storeId,
                true,
                inventoryConstants.PRODUCT_UPDATED_SUCCESSFULLY,
                true
              )
            );
          } else {
            customToast(
              inventoryConstants.PRODUCT_UPDATED_SUCCESSFULLY,
              toastType.SUCCESS
            );
          }
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            inventoryConstants.UPDATE_INVENTORY,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

const quantityShrink = (data) => {
  return (dispatch) => {
    dispatch(
      custom(
        inventoryConstants.SHRINK_INVENTORY,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    inventoryService
      .quantityShrink(data)
      .then(
        (response) => {
          // customToast(
          //   toastMessages.SHARINK_UPDATED_SUCCESSFULLY,
          //   toastType.SUCCESS
          // );
          dispatch(
            systemActions.pageRedireaction(routesPathConstants.INVENTORY)
          );
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            inventoryConstants.SHRINK_INVENTORY,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------get All inventory summary of current store
const getStoreInventorySummary = (storeId) => {
  return (dispatch) => {
    dispatch(
      custom(
        inventoryConstants.INVENTORY_HISTORY,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    inventoryService
      .getStoreInventorySummary(storeId)
      .then(
        (response) => {
          dispatch(success(response, inventoryConstants.INVENTORY_HISTORY));
        },
        (error) => {
          dispatch(failure(error, inventoryConstants.INVENTORY_HISTORY));
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            inventoryConstants.INVENTORY_HISTORY,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Empty inventory Redux
const emptyAllInventory = () => {
  return (dispatch) => {
    dispatch(
      success(
        {
          inventory: [],
          pageSize: 20,
          pageNumber: 1,
          totalPages: 0,
          totalRecords: 0,
          pageEndRecordNumber: 0,
          pageStartRecordNumber: 0,
        },
        inventoryConstants.GET_ALL_INVENTORY
      )
    );
  };
};

//-------delete some or multi product
const deleteInventory = (data, storeId, gridState = defaultGridState) => {
  return (dispatch) => {
    dispatch(
      custom(
        inventoryConstants.DELETE_INVENTORY,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    inventoryService
      .deleteInventory(data, storeId)
      .then(
        (response) => {
          customToast(
            toastMessages.ITEM_DELETED_SUCCESSFULLY,
            toastType.SUCCESS
          );
          dispatch(getPaginatedInventory(storeId, gridState));
          dispatch(getStoreInventorySummary(storeId));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            inventoryConstants.DELETE_INVENTORY,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

const clearItemHistory = (data) => {
  return (dispatch) => {
    dispatch(success(data, inventoryConstants.GET_INVENTORY_HISTORY));
  };
};

const getInventoryHistory = (storeId, type, start, end, cat) => {
  return (dispatch) => {
    dispatch(
      custom(
        inventoryConstants.GET_INVENTORY_HISTORY,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    inventoryService
      .getInventoryHistory(storeId, type, start, end, cat)
      .then(
        (response) => {
          dispatch(success(response, inventoryConstants.GET_INVENTORY_HISTORY));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            inventoryConstants.GET_INVENTORY_HISTORY,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------get All inventory of current store
const getPricechartingProductById = (productMetaData, productId) => {
  return (dispatch) => {
    dispatch(
      custom(
        inventoryConstants.GET_PRICECHARTING_PRODUCT_BY_ID,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    inventoryService
      .getPricechartingProductById(productId)
      .then(
        (response) => {
          dispatch(
            success(
              {
                productMetaData: productMetaData,
                productObject: response,
              },
              inventoryConstants.GET_PRICECHARTING_PRODUCT_BY_ID
            )
          );
        },
        (error) => {
          customToast(error, toastType.ERROR);
          dispatch(
            failure([], inventoryConstants.GET_PRICECHARTING_PRODUCT_BY_ID)
          );
        }
      )
      .finally(() => {
        dispatch(
          custom(
            inventoryConstants.GET_PRICECHARTING_PRODUCT_BY_ID,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------get pokemon card product by id
const getScryFallProductById = (productMetaData, productId) => {
  return (dispatch) => {
    dispatch(
      custom(
        inventoryConstants.GET_SCRYFALL_PRODUCT_BY_ID,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    inventoryService
      .getscryfallApiProductById(productId)
      .then(
        (response) => {
          dispatch(
            success(
              {
                productMetaData: productMetaData,
                productObject: response,
              },
              inventoryConstants.GET_PRICECHARTING_PRODUCT_BY_ID
            )
          );
        },
        (error) => {
          customToast(error, toastType.ERROR);
          dispatch(
            failure([], inventoryConstants.GET_PRICECHARTING_PRODUCT_BY_ID)
          );
        }
      )
      .finally(() => {
        dispatch(
          custom(
            inventoryConstants.GET_SCRYFALL_PRODUCT_BY_ID,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------get All inventory of current store
const getInventoryByPricechartingId = (storeId, data) => {
  return (dispatch) => {
    dispatch(
      custom(
        inventoryConstants.GET_INVENTORY_PRODUCT_BY_PRICECHARTING_ID,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    inventoryService
      .getInventoryByPricechartingId(storeId, data)
      .then(
        (response) => {
          dispatch(
            success(
              response,
              inventoryConstants.GET_INVENTORY_PRODUCT_BY_PRICECHARTING_ID
            )
          );
        },
        (error) => {
          customToast(error, toastType.ERROR);
          dispatch(
            failure(
              [],
              inventoryConstants.GET_INVENTORY_PRODUCT_BY_PRICECHARTING_ID
            )
          );
        }
      )
      .finally(() => {
        dispatch(
          custom(
            inventoryConstants.GET_INVENTORY_PRODUCT_BY_PRICECHARTING_ID,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

// process submision if no consolidation, and shows modal if consolidation
const getInventoryByProductMetaDataAndConsolidation = (
  storeId,
  productMetaData,
  handleYesButtonClick,
  handleNoButtonClick
) => {
  return (dispatch) => {
    dispatch(
      custom(
        inventoryConstants.GET_INVENTORY_PRODUCT_BY_PRICECHARTING_ID,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    inventoryService
      .getInventoryByProductMetaData(storeId, productMetaData)
      .then(
        (response) => {
          // consolidation case
          if (response) {
            dispatch(
              success(
                { isConsolidationModal: true, handleYesButtonClick },
                inventoryConstants.SET_CONSOLIDATION_MODAL
              )
            );
          }

          // no consolidation, proceed with form submission
          else {
            handleYesButtonClick?.isProductEdit
              ? handleYesButtonClick.functionToCall(
                  handleYesButtonClick.functionAttributes
                )
              : handleYesButtonClick.functionToCall(
                  ...handleYesButtonClick.functionAttributes
                );
            if (handleYesButtonClick.toggleCustomItemModal) {
              handleYesButtonClick.toggleCustomItemModal();
            }
          }
        },
        (error) => {
          customToast(error, toastType.ERROR);
          dispatch(
            failure(
              [],
              inventoryConstants.GET_INVENTORY_PRODUCT_BY_PRICECHARTING_ID
            )
          );
        }
      )
      .finally(() => {
        dispatch(
          custom(
            inventoryConstants.GET_INVENTORY_PRODUCT_BY_PRICECHARTING_ID,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

// all these set the same state: getInventoryByPricechartingId, getInventoryByProductMetaDataAndConsolidation, getStoreCustomItemsForInventoryProductsReducer.
const getInventoryByCustomItemCheck = (storeId) => {
  return (dispatch) => {
    dispatch(
      custom(
        inventoryConstants.GET_INVENTORY_PRODUCT_BY_CUSTOM_ITEM_CHECK,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    inventoryService
      .getStoreCustomProducts(storeId)
      .then(
        (response) => {
          dispatch(
            success(
              response.length ? response : [],
              inventoryConstants.GET_INVENTORY_PRODUCT_BY_CUSTOM_ITEM_CHECK
            )
          );
        },
        (error) => {
          customToast(error, toastType.ERROR);
          dispatch(
            failure(
              [],
              inventoryConstants.GET_INVENTORY_PRODUCT_BY_CUSTOM_ITEM_CHECK
            )
          );
        }
      )
      .finally(() => {
        dispatch(
          custom(
            inventoryConstants.GET_INVENTORY_PRODUCT_BY_CUSTOM_ITEM_CHECK,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------get All inventory of current store
const getTags = (storeId) => {
  return (dispatch) => {
    dispatch(
      custom(inventoryConstants.GET_TAGS, systemConstants.SPINNER_ACTIVATE)
    );
    inventoryService
      .getTags(storeId)
      .then(
        (response) => {
          dispatch(success(response, inventoryConstants.GET_TAGS));
        },
        (error) => {
          customToast(error, toastType.ERROR);
          dispatch(failure([], inventoryConstants.GET_TAGS));
        }
      )
      .finally(() => {
        dispatch(
          custom(
            inventoryConstants.GET_TAGS,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

const emptyPriceChartingProductObject = () => {
  return (dispatch) => {
    dispatch(
      custom(globalConstants.EMPTY_STRING, inventoryConstants.EMPTY_THE_OBJECT)
    );
  };
};

const emptyInventoryProductsList = () => {
  return (dispatch) => {
    dispatch(
      custom(
        globalConstants.EMPTY_STRING,
        inventoryConstants.EMPTY_INVENTORY_PRODUCTS_LIST
      )
    );
  };
};

//-------get All inventory of current store
const addInventory = (data, handleClearFilter) => {
  return (dispatch) => {
    dispatch(
      custom(inventoryConstants.ADD_INVENTORY, systemConstants.SPINNER_ACTIVATE)
    );
    inventoryService
      .addBatchInventory([data])
      .then(
        (response) => {
          handleClearFilter();
          dispatch(toggleAddModal(false));
          dispatch(getPaginatedInventory(data?.store?.id, defaultGridState));
          dispatch(getStoreInventorySummary(data?.store?.id));
          dispatch(
            storeActions.getUserNotifications(
              data.store.id,
              true,
              `${data.price.quantity} items added successfully.`
            )
          );

          dispatch(getTags(data.store.id));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            inventoryConstants.ADD_INVENTORY,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------Page Redireaction
const toggleAddModal = (value) => {
  return (dispatch) => {
    dispatch(custom(value, inventoryConstants.TOGGLE_ADD_MODAL));
  };
};

//-------Page Redireaction
const setPokemonProductInReducer = (productMetaData, value) => {
  return (dispatch) => {
    dispatch(
      custom(
        inventoryConstants.SET_POKEMON_OBJECT,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    dispatch(
      custom(
        {
          productMetaData: productMetaData,
          productObject: value,
        },
        inventoryConstants.SET_POKEMON_OBJECT
      )
    );
    dispatch(
      custom(
        inventoryConstants.SET_POKEMON_OBJECT,
        systemConstants.SPINNER_DEACTIVATE
      )
    );
  };
};

//-------Add batch inventory
const addBatchInventory = (data, handleCloseModal, handleClearFilter) => {
  return (dispatch) => {
    dispatch(
      custom(inventoryConstants.ADD_INVENTORY, systemConstants.SPINNER_ACTIVATE)
    );
    inventoryService
      .addBatchInventory(data)
      .then(
        (response) => {
          handleClearFilter();
          handleCloseModal(true);
          dispatch(getPaginatedInventory(data[0].store.id, defaultGridState));
          dispatch(getStoreInventorySummary(data[0].store.id));
          dispatch(
            storeActions.getUserNotifications(
              data[0].store.id,
              true,
              `${getCountOfTradeTotalItems(
                data || 0,
                "quantity"
              )} items added successfully.`
            )
          );

          dispatch(getTags(data[0].store.id));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            inventoryConstants.ADD_INVENTORY,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

const addBatchInventoryDraft = (data) => {
  return (dispatch) => {
    dispatch(
      custom(inventoryConstants.ADD_INVENTORY, systemConstants.SPINNER_ACTIVATE)
    );
    inventoryService
      .addBatchInventoryDraft(data)
      .then(
        (response) => {
          customToast(
            toastMessages.DRAFT_SAVED_SUCCESSFULLY,
            toastType.SUCCESS
          );
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(success([], inventoryConstants.SET_ADD_BATCH_INVENTORY));
        dispatch(success(false, inventoryConstants.SET_IS_BATCH_INVENTORY));
        dispatch(
          custom(
            inventoryConstants.ADD_INVENTORY,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

// //-------Add batch inventory
// const importFileInventory = (
//   data,
//   toastMessage,
//   hideModalCallback = undefined,
//   frontEndValidateRes = undefined
// ) => {
//   return (dispatch) => {
//     inventoryService
//       .importFileInventory(data)
//       .then(
//         (response) => {
//           // structure the response of invnetory import and creating CSV containing invalid products (front end invalid inventory + backend invalid inventory)
//           handleImportInventoryResponse(
//             data,
//             dispatch,
//             response,
//             toastMessage,
//             hideModalCallback,
//             frontEndValidateRes
//           );
//         },
//         (error) => {
//           customToast(error, toastType.ERROR);
//         }
//       )
//       .finally(() => {
//         dispatch(custom(false, inventoryConstants.IS_INVENTORY_IMPORTING));
//       });
//   };
// };

//-------Add batch inventory
const addCustomItem = (data, toastMessage, handleHideModal) => {
  return (dispatch) => {
    dispatch(
      custom(inventoryConstants.ADD_INVENTORY, systemConstants.SPINNER_ACTIVATE)
    );
    inventoryService
      .addBatchInventory(data)
      .then(
        (response) => {
          handleHideModal();
          dispatch(getPaginatedInventory(data[0].store.id, defaultGridState));
          dispatch(getStoreInventorySummary(data[0].store.id));
          dispatch(
            storeActions.getUserNotifications(
              data[0].store.id,
              true,
              `${getCountOfTradeTotalItems(
                data || 0,
                "quantity"
              )} items added successfully.`
            )
          );
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            inventoryConstants.ADD_INVENTORY,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------get price change threshold
const getPriceChangeThreshold = (storeId) => {
  return (dispatch) => {
    dispatch(
      custom(
        inventoryConstants.GET_PRICE_CHANGE_THRESHOLD,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    inventoryService
      .getPriceChangeThreshold(storeId)
      .then(
        (response) => {
          dispatch(
            success(response, inventoryConstants.GET_PRICE_CHANGE_THRESHOLD)
          );
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            inventoryConstants.GET_PRICE_CHANGE_THRESHOLD,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------get price change threshold
const addPriceChangeThreshold = (data, handleHideModal) => {
  return (dispatch) => {
    dispatch(
      custom(
        inventoryConstants.ADD_PRICE_CHANGE_THRESHOLD,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    inventoryService
      .addPriceChangeThreshold(data)
      .then(
        (response) => {
          dispatch(
            getPaginatedInventory(data.store.id, defaultGridState, true)
          );
          dispatch(getPriceChangeThreshold(data.store.id));
          customToast(
            toastMessages.THRESHOLD_UPDATED_SUCCESSFULLY,
            toastType.SUCCESS
          );
          handleHideModal();
          dispatch(getPriceChanges(data.store.id));
          // inventoryService.handleThresholdCsvData(data.store.id).then(() => {
          //   dispatch(getPriceChanges(data.store.id));
          // });
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            inventoryConstants.ADD_PRICE_CHANGE_THRESHOLD,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------get Data for price changes grid
const getPriceChanges = (storeId) => {
  return (dispatch) => {
    dispatch(
      custom(
        inventoryConstants.GET_PRICE_CHANGE_DATA,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    inventoryService
      .getPriceChanges(storeId)
      .then(
        (response) => {
          dispatch(success(response, inventoryConstants.GET_PRICE_CHANGE_DATA));
        },
        (error) => {
          dispatch(success([], inventoryConstants.GET_PRICE_CHANGE_DATA));
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            inventoryConstants.GET_PRICE_CHANGE_DATA,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------dismiss price changes product
const dismissProduct = (storeId, data) => {
  return (dispatch) => {
    dispatch(
      custom(
        inventoryConstants.GET_PRICE_CHANGE_DATA,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    inventoryService
      .dismissProduct(storeId, data)
      .then(
        (response) => {
          customToast(
            toastMessages.PRODUCT_DISMISSED_SUCCESSFULLY,
            toastType.SUCCESS
          );
          dispatch(getPriceChanges(storeId));
        },
        (error) => {
          dispatch(success([], inventoryConstants.GET_PRICE_CHANGE_DATA));
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            inventoryConstants.GET_PRICE_CHANGE_DATA,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

const updatePriceChangeProductPrices = (
  storeId,
  gridState,
  data,
  handleHideModal
) => {
  return (dispatch) => {
    dispatch(
      custom(
        inventoryConstants.UPDATE_INVENTORY,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    inventoryService
      .updatePriceChangeProductPrices(storeId, data.changeProductPrice)
      .then(
        (response) => {
          handleHideModal();
          dispatch(getPaginatedInventory(storeId, gridState, true));

          dispatch(
            storeActions.getUserNotifications(
              storeId,
              true,
              toastMessages.PRODUCT_PRICES_UPDATED,
              true
            )
          );
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            inventoryConstants.UPDATE_INVENTORY,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

const updateProductTypes = (storeId, data, handleHideModal) => {
  return (dispatch) => {
    dispatch(
      custom(
        inventoryConstants.UPDATE_INVENTORY,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    inventoryService
      .updatePriceChangeProductPrices(storeId, data)
      .then(
        (response) => {
          dispatch(getPaginatedInventory(storeId, defaultGridState));
          dispatch(getStoreInventorySummary(storeId));
          handleHideModal();
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            inventoryConstants.UPDATE_INVENTORY,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//------get instock products if found
const getInventoryProducts = (storeId, data) => {
  return inventoryService.getInventoryByPricechartingId(storeId, data).then(
    (response) => {
      return response;
    },
    (error) => {
      customToast(error, toastType.ERROR);
    }
  );
};

//-------activate inventory spinner
const activateImportInventorySpinner = (data) => {
  return (dispatch) => {
    dispatch(custom(data, inventoryConstants.IS_INVENTORY_IMPORTING));
  };
};

const importCsvFileResponse = (
  store,
  response,
  toastMessage,
  frontEndValidateRes
) => {
  let resMessage = toastMessage;
  // all items imported successfully
  if (
    response.updatedInventory?.length &&
    response.reqInvalidInventory?.length === 0 &&
    frontEndValidateRes.invalidInventory?.length === 0
  ) {
    resMessage =
      response.updatedInventory.length +
      toastMessages.INVENTORY_FILE_IMPORTED_SUCCESSFULLY;
  }
  // there are invalid items (it can be only front-end invalid, only backend invalid or front-end invalid + backend invalid)
  else if (
    response.reqInvalidInventory?.length ||
    frontEndValidateRes.invalidInventory?.length
  ) {
    // toast message
    resMessage = `${response.updatedInventory?.length ?? 0} ${
      toastMessages.INVENTORY_FILE_IMPORTED_SUCCESSFULLY
    }; ${
      (response.reqInvalidInventory?.length ?? 0) +
      (frontEndValidateRes.invalidInventory?.length ?? 0)
    } ${toastMessages.INVENTORY_FILE_NOT_IMPORTED}`;
    // creating csv of invalid items and downloading it
    let temp = null;
    downloadCSV(
      [
        ...frontEndValidateRes.invalidInventory.map((inv) => {
          temp = { ...inv, "Rejection Reason": inv.errorMessage };
          delete temp.errorMessage;
          return temp;
        }),
        ...response.reqInvalidInventory.map((inv) => ({
          "Product Name": beautifyStringForImport(inv.product_name),
          "Product Type": inv.productType,
          Category: inv.category_name,
          Subcategory: inv.subcategory,
          Condition: inv.price.type,
          "Serial Number": inv.serialNumber,
          Quantity: inv.price.quantity,
          "Cost of Goods": inv.price.unit_purchase_price,
          "Sell Price": inv.price.unit_sell_price,
          SKU: exportStringArrayFunc(inv.sku),
          UPC: exportUpcFunc(inv.upc),
          Tags: exportTagFunc(inv.tags),
          "Rejection Reason": inv.errorMessage,
        })),
      ],
      "invalid inventory products"
    );
  }

  customToast(resMessage, toastType.SUCCESS);
  return (dispatch) => {
    dispatch(activateImportInventorySpinner({ isVisible: false, message: "" }));
    // closes the modal and get updated inventory if there was at leat 1 successfull inventory import
    dispatch(
      storeActions.createNotification(store?.id, {
        message: resMessage,
        sky: [NOTIFICATION_TYPES.PRODUCT_LABEL_IMPORT],
        product_name: NOTIFICATION_TYPES.PRODUCT_LABEL_IMPORT,
        category_name: NOTIFICATION_TYPES.PRODUCT_LABEL_IMPORT,
        products: [],
        productType: "Other",
        imgUrl: "",
        type: NOTIFICATION_TYPES.PRODUCT_LABEL_IMPORT,
        isRead: false,
        readByUserIds: [],
        isActive: true,
        store: {
          id: store.id,
          name: store.storeName,
        },
      })
    );
    if (response.updatedInventory?.length) {
      dispatch(getPaginatedInventory(store.id, defaultGridState));
      dispatch(getStoreInventorySummary(store.id));
    }
  };
};

//-------get All inventory of current store
const getPaginatedInventory = (
  storeId,
  gridState,
  isPriceChangesApiCall = false
) => {
  return (dispatch) => {
    dispatch(
      custom(
        inventoryConstants.GET_ALL_INVENTORY,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    inventoryService
      .getPaginatedInventory(storeId, gridState, isPriceChangesApiCall)
      .then(
        (response) => {
          dispatch(success(response, inventoryConstants.GET_ALL_INVENTORY));
        },
        (error) => {
          customToast(error, toastType.ERROR);
        }
      )
      .finally(() => {
        dispatch(
          custom(
            inventoryConstants.GET_ALL_INVENTORY,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

const getPaginatedInventoryHistory = (storeId, gridState) => {
  return (dispatch) => {
    dispatch(
      custom(
        inventoryConstants.GET_INVENTORY_HISTORY,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    inventoryService
      .getPaginatedInventoryHistory(storeId, gridState)
      .then(
        (response) => {
          dispatch(success(response, inventoryConstants.GET_INVENTORY_HISTORY));
        },
        (error) => {
          customToast(error, toastType.ERROR);
          dispatch(failure(null, inventoryConstants.GET_INVENTORY_HISTORY));
        }
      )
      .finally(() => {
        dispatch(
          custom(
            inventoryConstants.GET_INVENTORY_HISTORY,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//-------get company Inventory
const getCompanyInventory = (companyId, gridState, setIsSearchLoading) => {
  return (dispatch) => {
    dispatch(
      custom(
        inventoryConstants.GET_COMPANY_INVENTORY,
        systemConstants.SPINNER_ACTIVATE
      )
    );
    inventoryService
      .getCompanyInventory(companyId, gridState)
      .then(
        (response) => {
          dispatch(success(response, inventoryConstants.GET_COMPANY_INVENTORY));
        },
        (error) => {
          customToast(error, toastType.ERROR);
          dispatch(failure({}, inventoryConstants.GET_COMPANY_INVENTORY));
        }
      )
      .finally(() => {
        setIsSearchLoading(false);
        dispatch(
          custom(
            inventoryConstants.GET_COMPANY_INVENTORY,
            systemConstants.SPINNER_DEACTIVATE
          )
        );
      });
  };
};

//------- Empty Company Search Inventory
const emptyCompanyInventory = () => {
  return (dispatch) => {
    dispatch(failure({}, inventoryConstants.GET_COMPANY_INVENTORY));
  };
};

//-------Export Authentication Actions
export const inventoryActions = {
  getAllInventory,
  updateInventory,
  quantityShrink,
  getInventoryHistory,
  getStoreInventorySummary,
  deleteInventory,
  getPricechartingProductById,
  emptyPriceChartingProductObject,
  getInventoryByPricechartingId,
  addInventory,
  getTags,
  toggleAddModal,
  getScryFallProductById,
  setPokemonProductInReducer,
  addBatchInventory,
  getPriceChangeThreshold,
  addPriceChangeThreshold,
  dismissProduct,
  getPriceChanges,
  updatePriceChangeProductPrices,
  addCustomItem,
  getInventoryProducts,
  activateImportInventorySpinner,
  importCsvFileResponse,
  emptyAllInventory,
  updateProductTypes,
  addBatchInventoryDraft,
  getPaginatedInventory,
  getPaginatedInventoryHistory,
  getInventoryByProductMetaDataAndConsolidation,
  getInventoryByCustomItemCheck,
  emptyInventoryProductsList,
  updatePriceChangeProduct,
  clearItemHistory,
  getCompanyInventory,
  emptyCompanyInventory,
};

import { updateObject } from "../../shared/utility";
import {
  getDecryptPayload,
  getUserInfoFromToken,
  getDecryptCompanyPayload,
} from "../../system/encryption";
import {
  authenticationConstants,
  globalConstants,
  userConstants,
} from "../../constants";

//-------initializing the company token from the browser
let companyPayload = getDecryptCompanyPayload();
let company = companyPayload.accessToken
  ? getUserInfoFromToken(companyPayload.accessToken)
  : {};
let companyAccessToken =
  companyPayload.accessToken || globalConstants.EMPTY_STRING;
let companyRefreshToken =
  companyPayload.refreshToken || globalConstants.EMPTY_STRING;
let isCompanyAuthenticated = companyAccessToken.length > 0;

//-------initializing the token from the browser
let payload = getDecryptPayload();
let user = payload.accessToken ? getUserInfoFromToken(payload.accessToken) : {};
let accessToken = payload.accessToken || globalConstants.EMPTY_STRING;
let refreshToken = payload.refreshToken || globalConstants.EMPTY_STRING;
let isAuthenticated = accessToken.length > 0;

accessToken =
  isCompanyAuthenticated && !isAuthenticated
    ? companyAccessToken
    : isCompanyAuthenticated && isAuthenticated
    ? accessToken
    : accessToken;

refreshToken =
  isCompanyAuthenticated && !isAuthenticated
    ? companyRefreshToken
    : isCompanyAuthenticated && isAuthenticated
    ? refreshToken
    : refreshToken;
//-------InitialState of the reducer
const initialState = {
  user,
  company,
  accessToken,
  refreshToken,
  companyAccessToken,
  companyRefreshToken,
  isCompanyAuthenticated: isCompanyAuthenticated,
  isAuthenticated: isAuthenticated,
};

//-------Export Authentication Reducer
export const authentication = (state = initialState, action) => {
  switch (action.type) {
    //-------User login success case
    case authenticationConstants.EMPLOYEE_LOGIN + globalConstants.SUCCESS:
      return updateObject(state, {
        isAuthenticated: true,
        acccessToken: action.data.accessToken,
        refreshToken: action.data.refreshToken,
        user: action.data.user,
      });
    //-------User login failure case
    case authenticationConstants.EMPLOYEE_LOGIN + globalConstants.FAILURE:
      return updateObject(state, {
        isAuthenticated: false,
        accessToken: globalConstants.EMPTY_STRING,
        refreshToken: globalConstants.EMPTY_STRING,
      });
    //-------company login success case
    case authenticationConstants.COMPANY_LOGIN + globalConstants.SUCCESS:
      return updateObject(state, {
        isCompanyAuthenticated: true,
        companyAccessToken: action.data.accessToken,
        companyAccessToken: action.data.refreshToken,
        company: action.data.company,
      });
    //-------Company login failure case
    case authenticationConstants.COMPANY_LOGIN + globalConstants.FAILURE:
      return updateObject(state, {
        isCompanyAuthenticated: false,
        companyAccessToken: globalConstants.EMPTY_STRING,
        companyAccessToken: globalConstants.EMPTY_STRING,
      });
    //-------User logout failure case
    case authenticationConstants.USER_LOGOUT + globalConstants.SUCCESS:
      return updateObject(state, {
        isAuthenticated: action.data,
        acccessToken: globalConstants.EMPTY_STRING,
        refreshToken: globalConstants.EMPTY_STRING,
      });
    case authenticationConstants.COMPANY_LOGOUT + globalConstants.SUCCESS:
      return updateObject(state, {
        isCompanyAuthenticated: action.data,
        acccessToken: globalConstants.EMPTY_STRING,
        refreshToken: globalConstants.EMPTY_STRING,
      });
    //-------Update user information case
    case userConstants.UPDATE_USER_INFORMATION:
      return updateObject(state, {
        user: { ...state.user, ...action.data },
      });
    //-------Default State
    default:
      return state;
  }
};

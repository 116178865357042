import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";

import Button from "../../../../shared/components/Buttons/Button";
import {
  buttonNameConstants,
  buttonTypeConstants,
  inventoryConstants,
  toastMessages,
  toastType,
  transactionConstants,
} from "../../../../constants";
import SimpleNumberField from "../../../../shared/components/inputNumberField/SimpleNumberField";
import {
  CloseIcon,
  DollarIcon,
  TickIconLong,
} from "../../../../assets/icons/iconsProvider";
import {
  customToast,
  isAllSystemHasSerialNumber,
  isSpinnerEnabled,
  parseToThousandSeperatorDecimalNumber,
} from "../../../../shared/utility";
import InventoryOverviewThreeCards from "../../../../shared/components/inventoryOverviewThreeCards/InventoryOverviewThreeCards";
import EditCogsOffer from "./EditCogsOffer";

const AddBatchInventoryFooter = ({
  closeModal,
  data,
  handleCogsValueChange,
  cogsPrice,
  spinnerArray,
  handleResetBatch,
  ...props
}) => {
  const [totalCost, setTotalCost] = useState(0);

  const handleCogsChange = (data) => {
    if (data?.value > 0) {
      handleCogsValueChange(data);
    } else {
      customToast(toastMessages.TOTAL_COST, toastType.ERROR);
    }
  };

  useEffect(() => {
    setTotalCost(data.totalCost);
  }, [data.totalCost]);

  return (
    <>
      <Row className="m-0 mb-3 px-0">
        <Col md={6} className="ps-0">
          <Col md={12} className="h-100">
            <EditCogsOffer
              {...props}
              data={data}
              handleCogsChange={handleCogsChange}
              cogsPrice={totalCost}
              handleRefresh={handleResetBatch}
            />
          </Col>
        </Col>
        <Col md={6} className="pe-0">
          <InventoryOverviewThreeCards
            data={[
              { label: "Total Items", value: data.totalItems },
              {
                label: "Total Market Value",
                value:
                  "$" +
                  parseToThousandSeperatorDecimalNumber(
                    data.totalMarketPrice ?? 0
                  ),
              },
              {
                label: "Total Price",
                value:
                  "$" + parseToThousandSeperatorDecimalNumber(data.totalPrice),
              },
            ]}
          />
          <Row className="m-0 w-100 mt-3">
            <Col
              md={6}
              className="d-flex justify-content-end align-items-center ps-0"
            >
              <Button
                label={buttonNameConstants.CANCEL}
                className="w-100"
                type="button"
                handleClick={() => closeModal()}
                buttonType={buttonTypeConstants.GHOST_BUTTON}
                isDisabled={isSpinnerEnabled(spinnerArray, [
                  inventoryConstants.ADD_INVENTORY,
                  transactionConstants.ADD_TRADE_BATCH_ITEM,
                ])}
              />
            </Col>
            <Col md={6} className="d-flex align-items-center pe-0">
              <Button
                label={buttonNameConstants.ADD_TO_INVENTORY}
                className="w-100"
                type="submit"
                handleClick={props.handleSubmitAddBatchInventory}
                isDisabled={
                  !Number(data.totalItems) ||
                  isSpinnerEnabled(spinnerArray, [
                    inventoryConstants.ADD_INVENTORY,
                    transactionConstants.ADD_TRADE_BATCH_ITEM,
                  ]) ||
                  isAllSystemHasSerialNumber(
                    props.currentStore,
                    props.addBatchInventory
                  )
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default AddBatchInventoryFooter;

import React, { useEffect, useState } from "react";

import VerticalNumberField from "../inputNumberField/VerticalNumberField";
import { TRADING_CARD_CATEGORIES_ENUMS } from "../../../system/globalEnums";
import {
  EditIcon,
  LockIcon,
  UnLockIcon,
  DeleteIcon,
} from "../../../assets/icons/iconsProvider";
import {
  globalConstants,
  inventoryConstants,
  transactionConstants,
} from "../../../constants";
import {
  isQuickTradeSku,
  parseToDecimalNumber,
  getProductDetailCardTags,
  parseToThousandSeperatorDecimalNumber,
} from "../../utility";

const ProductCartCardTrade = ({
  max = 9999,
  totalItems,
  activeButton,
  productObject,
  handleEditCartItem,
  handleDeleteCartItem,
  handleQuantityChange,
  updateCashTradeOffer,
  handleLockOrUnlockItem,
}) => {
  const [unit_purchase_price, setUnit_purchase_price] = useState("");
  const handleLocalQuantityChange = (sku, newQuantity) => {
    handleQuantityChange(sku, newQuantity);
  };

  const handlePriceChange = (newPrice) => {
    // const newPrice = convertToFixedPrecision(event.target.value);
    setUnit_purchase_price(newPrice);
    updateCashTradeOffer(
      productObject?.sku,
      activeButton,
      newPrice,
      productObject?.price?.marketPrice
    );
  };
  useEffect(() => {
    let updatedPurchase = productObject?.cashOffer;
    if (activeButton === globalConstants.CASH) {
      updatedPurchase = productObject?.cashOffer;
    } else {
      updatedPurchase = productObject?.tradeOffer;
    }
    const priceValue = parseToDecimalNumber(updatedPurchase);
    setUnit_purchase_price(priceValue);
  }, [activeButton, productObject?.cashOffer, productObject?.tradeOffer]);

  return (
    <div
      className="cart-card d-flex align-items-center gap-2 justify-content-between mb-2"
      key={productObject?.id}
    >
      <VerticalNumberField
        sku={productObject?.sku}
        quantity={productObject?.price?.quantity}
        maxValue={
          productObject?.category_name !== transactionConstants.QUICK_TRADE
            ? max
            : 1
        }
        onChange={handleLocalQuantityChange}
        disabled={
          !(productObject?.trackQuantity ||
          productObject?.trackQuantity === undefined ||
          productObject?.trackQuantity === null
            ? true
            : false)
        }
      />
      <div className="cart-card-name-wrapper cart-card-name-without-discount d-flex flex-column gap-1">
        <span>
          {productObject.product_name}{" "}
          {productObject?.category_name == transactionConstants.QUICK_TRADE &&
            `(${totalItems} Items)`}
        </span>

        <span className="d-flex align-items-center gap-1">
          {productObject?.category_name !== transactionConstants.QUICK_TRADE &&
            `${productObject?.category_name} (${productObject?.price?.type})`}
          {productObject?.tags?.length
            ? getProductDetailCardTags(
                productObject.tags?.filter(
                  (tag) => tag !== inventoryConstants.CUSTOM_ITEM_TAG
                ),
                productObject?.product_id
              )
            : ""}
        </span>
        {!isQuickTradeSku(productObject?.sku) && (
          <span className="d-flex align-items-center gap-2">
            <span className="trade-cart-cart-sku pe-2">
              {productObject?.category_name !==
                transactionConstants.QUICK_TRADE &&
                `Market Price:$${parseToThousandSeperatorDecimalNumber(
                  productObject?.price?.marketPrice
                )}`}
            </span>
            {productObject?.subcategory ===
              TRADING_CARD_CATEGORIES_ENUMS.SYSTEM &&
              productObject?.serialNumber && (
                <span className="pe-1">
                  S/N : {productObject?.serialNumber}
                </span>
              )}
          </span>
        )}
      </div>
      <div className=" d-flex flex-column justify-content-between align-items-end gap-2">
        <div className="w-100 d-flex justify-content-end align-items-center gap-2">
          <button
            type="button"
            className="cart-card-delete-button cart-card-edit-icon px-0"
            onClick={() => handleEditCartItem(productObject)}
          >
            <EditIcon />
          </button>
          <button
            type="button"
            className="cart-card-delete-button px-0"
            onClick={() => handleDeleteCartItem(productObject?.id)}
          >
            <DeleteIcon />
          </button>
        </div>
        <div className="d-flex justify-content-end align-items-center gap-3">
          <button
            className="action-Button-Wrapper  ps-0 pe-0 mx-auto"
            onClick={() => {
              handleLockOrUnlockItem(productObject?.id);
            }}
          >
            {productObject?.tradeProductMetaData?.isItemLocked ? (
              <LockIcon className="cursor-pointer text-primary" />
            ) : (
              <UnLockIcon className="cursor-pointer" />
            )}
          </button>
          <div className="amount-to-paid-field-wrapper product-cart-card-price">
            <span className="dollar-icon-alignment">$</span>
            <input
              key={unit_purchase_price}
              defaultValue={unit_purchase_price}
              type="number"
              className="amount-to-paid-field"
              step={0.01}
              onBlur={(e) => {
                const inputValue = parseToDecimalNumber(e.target.value);
                handlePriceChange(inputValue);
              }}
              max={99999}
              min={1}
              disabled={productObject?.tradeProductMetaData?.isItemLocked}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCartCardTrade;

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import Button from "../Buttons/Button";
import { customerService } from "../../../services";
import CustomBodyModal from "../modal/CustomBodyModal";
import { customerActions } from "../../../redux/actions";
import CustomerImage from "../../../assets/images/customerImage.svg";
import {
  buttonNameConstants,
  buttonTypeConstants,
  EMPTY_STRING,
  toastMessages,
  toastType,
  transactionConstants,
} from "../../../constants";
import {
  EditIcon,
  DeleteIcon,
  ResetCartIcon,
} from "../../../assets/icons/iconsProvider";
import {
  customToast,
  getCustomerFullName,
  getLockCustomerPayload,
  parseToThousandSeperatorDecimalNumber,
} from "../../utility";
import CustomerDropdown from "../selectDropdown/CustomerDropdown";
import { custom } from "../../../redux/actions/actionsUtilities";

const SelectedCustomerCard = (props) => {
  const {
    customer,
    currentStore,
    getCustomerById,
    isStoreCredit = true,
    transferCustomerCredit,
    isLockCustomer = false,
    handleCustomerEditClick,
    isDeleteEditOptions = true,
    handleAddCustomerClick,
    handleCustomerDeleteClick,
    isTransferCustomerCreditModal,
    setIsTransferCustomerCreditModal,
    transactionId = EMPTY_STRING, //sale/trade transaction id
    className = {
      img: EMPTY_STRING,
      nameDiv: EMPTY_STRING,
      nameSpan: EMPTY_STRING,
      parnetDiv: EMPTY_STRING,
      storeCreditDiv: EMPTY_STRING,
      editDeleteDiv: EMPTY_STRING,
      deleteButton: EMPTY_STRING,
      editButton: EMPTY_STRING,
    },
  } = props;

  const handleTransferCustomerStoreCredit = (
    fromCustomer,
    toCustomer,
    isResetBalanceOfFrom = true
  ) => {
    if (fromCustomer?.id) {
      transferCustomerCredit({
        transactionId: transactionId,
        id: toCustomer?.id ? toCustomer?.id : fromCustomer.id, // customer id of the customer you want to set in cart after update
        fromId: fromCustomer.id,
        toId: toCustomer?.id ?? EMPTY_STRING,
        isResetBalanceOfFrom: isResetBalanceOfFrom,
        balanceToTransfer: fromCustomer.currentBalance,
        companyId: currentStore.companyId,
        store: {
          id: currentStore.id,
          name: currentStore.storeName,
        },
      });
    } else {
      customToast(toastMessages.SOMETHING_WENT_WRONG, toastType.ERROR);
    }
  };
  const handleCustomerDelete = (e) => {
    handleCustomerDeleteClick(e);
    if (isLockCustomer && customer.isGuestCustomer) {
      customerService.lockCustomer(
        getLockCustomerPayload(customer, currentStore, false)
      );
    }
  };

  useEffect(() => {
    getCustomerById(
      currentStore?.companyId,
      currentStore?.id,
      customer?.id,
      handleCustomerEditClick
    );
  }, []);

  return (
    <>
      <div
        key={customer?.id}
        className={`w-100 d-flex align-items-center justify-content-start gap-2
        ${className.parnetDiv || "selected-customer"}
      `}
      >
        <div className="d-flex gap-1">
          <img
            className={`${className.img}`}
            src={CustomerImage}
            alt="customer"
          />
          <div
            className={`d-flex flex-column justify-content-center ${className.nameDiv} `}
          >
            <span className={`customer-name-heading ${className.nameSpan}`}>
              {getCustomerFullName(customer)}
            </span>
            <span>
              {customer?.mobile || customer?.email || "(000) 000-0000"}
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center gap-3 ms-auto">
          {customer?.isGuestCustomer && customer?.currentBalance > 0 && (
            <Button
              label={buttonNameConstants.TRANSFER}
              className={"customer-reset p-0 m-0"}
              handleClick={() => {
                setIsTransferCustomerCreditModal(true);
              }}
              title={buttonNameConstants.TRANSFER_CUSTOMER_CREDIT}
              buttonType={buttonTypeConstants.TRANSPARENT_BUTTON_PURPLE}
            />
          )}
          {isStoreCredit && (
            <div
              className={`d-flex flex-column justify-content-center align-items-center customer-credit
          ${className.storeCreditDiv} 
          ${customer?.isGuestCustomer ? EMPTY_STRING : "ms-auto"}
          `}
            >
              <span>Store Credit</span>
              <span>
                $
                {parseToThousandSeperatorDecimalNumber(
                  customer?.currentBalance || 0
                )}
              </span>
            </div>
          )}
          <div className={`d-flex ${className.editDeleteDiv}`}>
            {customer?.isGuestCustomer && customer?.currentBalance > 0 && (
              <button
                title={buttonNameConstants.RESET_CUSTOMER_CREDIT}
                className={"customer-reset p-0 m-0 cart-card-delete-button"}
                onClick={() =>
                  handleTransferCustomerStoreCredit(
                    {
                      ...customer,
                      currentBalance: 0,
                    },
                    null,
                    true
                  )
                }
              >
                <ResetCartIcon />
              </button>
            )}
            {isDeleteEditOptions && (
              <>
                <button
                  type="button"
                  className={`cart-card-delete-button px-0 ${className.editButton}`}
                  onClick={handleCustomerEditClick}
                >
                  <EditIcon />
                </button>
                <button
                  type="button"
                  className={`cart-card-delete-button px-0 ${className.deleteButton}`}
                  onClick={handleCustomerDelete}
                >
                  <DeleteIcon />
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      {isTransferCustomerCreditModal && handleAddCustomerClick && (
        <TransferCustomerCreditModal
          customer={customer}
          isShow={isTransferCustomerCreditModal}
          setIsShow={setIsTransferCustomerCreditModal}
          handleAddCustomerClick={handleAddCustomerClick}
          handleTransferCustomerStoreCredit={handleTransferCustomerStoreCredit}
        />
      )}
    </>
  );
};

const TransferCustomerCreditModal = (props) => {
  const {
    isShow,
    customer,
    setIsShow,
    handleAddCustomerClick,
    handleTransferCustomerStoreCredit,
  } = props;
  return (
    <CustomBodyModal
      size={"md"}
      show={isShow}
      onHide={setIsShow}
      title={buttonNameConstants.TRANSFER_CUSTOMER_CREDIT}
    >
      <CustomerDropdown
        value={EMPTY_STRING}
        menuPortalTarget={null}
        handleAddCustomerClick={() =>
          handleAddCustomerClick({
            currentBalance: customer.currentBalance,
            selectedGuestCustomer: customer,
          })
        }
        handleChange={(option) => {
          const selectedCustomer = JSON.parse(option.value);
          handleTransferCustomerStoreCredit(customer, selectedCustomer, true);
        }}
      />
    </CustomBodyModal>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  currentStore: state.store.currentStore,
  isTransferCustomerCreditModal:
    state.transaction.isTransferCustomerCreditModal,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  transferCustomerCredit: (data) =>
    dispatch(customerActions.transferCustomerCredit(data)),
  getCustomerById: (companyId, storeId, customerId) =>
    dispatch(customerActions.getCustomerById(companyId, storeId, customerId)),
  setIsTransferCustomerCreditModal: (data) =>
    dispatch(
      custom(data, transactionConstants.SET_IS_TRANSFER_CUSTOMER_CREDIT_MODAL)
    ),
});

//-------Export History Component
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectedCustomerCard);

import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";

const CompanyProductDetailModal = ({
  product,
  isProductDetailModal,
  toggleProductDetailModal,
}) => {
  const columnHeaders = [
    {
      id: "storeName",
      name: "Store",
      selector: (row) => row.storeName,
      cell: (row) => {
        return <span className="devices-cell-max-size">{row.storeName}</span>;
      },
      sortable: false,
      sortDirection: null,
    },
    {
      id: "quantityInStock",
      name: "Quantity in Stock",
      selector: (row) => row.quantityInStock,
      cell: (row) => {
        return (
          <span className="devices-cell-max-size">{row.quantityInStock}</span>
        );
      },
      sortable: false,
      sortDirection: null,
    },
    {
      id: "sellPrice",
      name: "Sell Price",
      selector: (row) => row.sellPrice,
      cell: (row) => {
        return <span className="devices-cell-max-size">{row.sellPrice}</span>;
      },
      sortable: false,
      sortDirection: null,
    },
    {
      id: "phoneNumber",
      name: "Phone Number",
      selector: (row) => row.phoneNumber,
      cell: (row) => {
        return <span className="devices-cell-max-size">{row.phoneNumber}</span>;
      },
      sortable: false,
      sortDirection: null,
    },
    {
      id: "address",
      name: "Address",
      selector: (row) => row.address,
      cell: (row) => {
        return <span className="devices-cell-max-size">{row.address}</span>;
      },
      sortable: false,
      sortDirection: null,
    },
    {
      id: "city",
      name: "City",
      selector: (row) => row.city,
      cell: (row) => {
        return <span className="devices-cell-max-size">{row.city}</span>;
      },
      sortable: false,
      sortDirection: null,
    },
    {
      id: "state",
      name: "State",
      selector: (row) => row.state,
      cell: (row) => {
        return <span className="devices-cell-max-size">{row.state}</span>;
      },
      sortable: false,
      sortDirection: null,
    },
  ];

  const handleHideModal = () => {
    toggleProductDetailModal();
  };
  return (
    <Modal
      centered
      animation={true}
      backdrop="static"
      onHide={handleHideModal}
      show={isProductDetailModal}
      size={"xl"}
      className="add-inventory-modal"
    >
      <Modal.Header closeButton className="add-inventory-modal-header">
        <Modal.Title>
          <span className="add-inventory-modal-header-name">
            {`${product?.product_name || "Product Name"} (${
              product?.category_name || "Product Name"
            })`}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="add-inventory-modal-body ">
        <Row>
          <Col md={12}>
            <DataTable
              className="price-changes-edit-modal"
              data={product?.storesInventory || []}
              columns={columnHeaders}
              fixedHeader
            />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default CompanyProductDetailModal;

import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

import SimpleNumberField from "../../../../shared/components/inputNumberField/SimpleNumberField";
import ToggleButtonWithText from "../../../../shared/components/toggleButtons/ToggleButtonWithText";
import {
  DollarIcon,
  PercentageIcon,
} from "../../../../assets/icons/iconsProvider";
import Button from "../../../../shared/components/Buttons/Button";
import {
  buttonNameConstants,
  buttonTypeConstants,
  EMPTY_STRING,
  transactionConstants,
} from "../../../../constants";
import { parseToDecimalNumber } from "../../../../shared/utility";

const EditTradeCashOfferModal = ({
  data,
  value,
  className,
  percentage,
  headingLabel,
  handleSubmit,
  toggleEditOfferModal,
}) => {
  const [isPercentageOffer, setIsPercentageOffer] = useState(true);

  const togglePercentageOffer = () => setIsPercentageOffer(!isPercentageOffer);
  const [inputValue, setInputValue] = useState(value);

  // hide modal
  const handleHideModal = () => {
    toggleEditOfferModal(EMPTY_STRING);
  };

  const getValue = () => {
    if (isPercentageOffer) {
      return parseToDecimalNumber(inputValue);
    } else {
      return parseToDecimalNumber(
        (Number(data?.totalMarketPrice) * Number(inputValue)) / 100
      );
    }
  };

  useEffect(() => {
    setInputValue(parseToDecimalNumber(isPercentageOffer ? value : percentage));
  }, [value, isPercentageOffer]);

  return (
    <div
      className={`edit-offer-modal py-2 ${className}`}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Row className="m-0">
        <Col md={12} className="mb-2">
          <span className="edit-offer-modal-heading">{headingLabel}</span>
        </Col>
        <Col md={7} className="mb-2">
          <SimpleNumberField
            // disabled={true}
            name="tradeOffer"
            placeHolder="0"
            className="add-inventory-price-field input-background"
            IconImage={isPercentageOffer ? DollarIcon : PercentageIcon}
            onChange={(e) => setInputValue(e)}
            value={inputValue}
          />
        </Col>
        <Col
          md={5}
          className="d-flex justify-content-end align-items-center mb-1"
        >
          <ToggleButtonWithText
            isToggle={isPercentageOffer}
            handleLeftButtonClick={() => togglePercentageOffer()}
            handleRightButtonClick={() => togglePercentageOffer()}
            leftButtonLabel="%"
            rightButtonLabel="$"
            className="discount-toggle-button dashboard-card-button edit-offer-modal-toggle-button opacity-100"
          />
        </Col>
        <Col
          md={12}
          className="d-flex justify-content-center align-items-center gap-2 border-top pt-2"
        >
          <Button
            label={buttonNameConstants.CLOSE}
            className="w-100 edit-offer-modal-button"
            type="button"
            handleClick={() => handleHideModal()}
            buttonType={buttonTypeConstants.GHOST_BUTTON}
            isDisabled={false}
          />
          <Button
            label={buttonNameConstants.APPLY}
            className="w-100 edit-offer-modal-button"
            type="button"
            handleClick={() => {
              handleSubmit({
                value: getValue(),
              });
              handleHideModal();
            }}
            isDisabled={false}
          />
        </Col>
      </Row>
    </div>
  );
};

export default EditTradeCashOfferModal;

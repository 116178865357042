import React from "react";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router";
import { Row, Col } from "react-bootstrap";

import { SIDEBAR_BUTTONS } from "../../constants";
import { isSpinnerEnabled } from "../../shared/utility";
import LogoImage from "../../assets/images/LoginLogo.png";
import { authenticationActions } from "../../redux/actions";
import Button from "../../shared/components/Buttons/Button";
import { EmailIcon, PasswordIcon } from "../../assets/icons/iconsProvider";
import InputTextField from "../../shared/components/inputTextField/InputTextField";
import { loginValidationSchema } from "../../shared/validationSchema/validationSchema";
import InputPasswordField from "../../shared/components/inputPasswordField/InputPasswordField";
import {
  authenticationConstants,
  buttonNameConstants,
  buttonTypeConstants,
  routesPathConstants,
  systemConstants,
} from "../../constants";

//-------Login component start here
const Login = (props) => {
  const initialValues = {
    email: "",
    password: "",
  };
  const navigate = useNavigate();
  //-------Handle login click
  const handleLogin = (values) => {
    props.handleLoginClick({
      email: values.email.toLowerCase(),
      password: values.password,
    });
    props.setActiveSidebarBtn(SIDEBAR_BUTTONS.DASHBOARD);
  };

  const handleForgotPasswordClick = () => {
    navigate(routesPathConstants.FORGOT_PASSWORD);
  };

  //-------Return Login Component
  return (
    <div>
      <div className=" login-card-wrapper row d-flex justify-content-center align-items-center m-0 p-4 min-vh-100 min-vw-100">
        <img
          className="login-collect-pos-icon"
          src={LogoImage}
          alt="collect pos icon"
        />
        <div className="login-card col-sm-8 col-md-6 col-lg-4 py-4 px-5 pb-3 d-flex flex-column justify-content-center align-items-center rounded-4">
          <h1 className="py-2">Login to Your Store or Company</h1>
          <Formik
            initialValues={initialValues}
            validate={loginValidationSchema}
            onSubmit={handleLogin}
          >
            <Form className="login-form-wrapper">
              <Row className="w-100 m-0">
                <Col xs={12} className="p-0 mb-3">
                  <InputTextField
                    type="email"
                    name="email"
                    label="Email"
                    placeHolder="Email Address"
                  />
                </Col>
                <Col xs={12} className="p-0 mb-3">
                  <InputPasswordField
                    type="password"
                    name="password"
                    label="Password"
                    placeHolder="Password"
                    autoComplete="on"
                  />
                </Col>
                <Col xs={12} className="p-0 mt-3 ">
                  <Button
                    type="submit"
                    className="w-100"
                    label={buttonNameConstants.LOGIN}
                    buttonType={buttonTypeConstants.BLACK_BUTTON}
                    isDisabled={isSpinnerEnabled(
                      props.spinnerArray,
                      authenticationConstants.EMPLOYEE_LOGIN
                    )}
                  />
                </Col>
                <Col
                  xs={12}
                  className="p-0 mt-2 mb-0 d-flex align-items-center"
                >
                  <span
                    className="forgot-password-link"
                    onClick={handleForgotPasswordClick}
                  >
                    Forgot Your Password?
                  </span>
                </Col>
                <Col
                  xs={12}
                  className="p-0 mt-4 mb-0 d-flex justify-content-center align-items-center text-muted"
                >
                  Powered by CollectPOS
                </Col>
              </Row>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
}; //-------Login component end here

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  spinnerArray: state.system.spinnerArray,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  handleLoginClick: (data) =>
    dispatch(authenticationActions.companyLogin(data)),
  setActiveSidebarBtn: (data) =>
    dispatch({ type: systemConstants.SET_ACTIVE_SIDEBAR_BTN, data: data }),
});

//-------Export Login Component
export default connect(mapStateToProps, mapDispatchToProps)(Login);

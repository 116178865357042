import React, { useRef, useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { Formik, Form } from "formik";
import { connect } from "react-redux";
import { Parse, IsExpired } from "aamva-parser";
import BarcodeReader from "react-barcode-reader";
import { State } from "country-state-city";

import {
  globalConstants,
  buttonNameConstants,
  customerConstants,
  countryCodesConstants,
  EMPTY_STRING,
} from "../../../../constants";
import InputTextField from "../../../../shared/components/inputTextField/InputTextField";
import InputGenericField from "../../../../shared/components/inputGenericField/InputGenericField";
import { addCustomerValidationSchema } from "../../../../shared/validationSchema/validationSchema";
import SelectFieldCity from "../../../../shared/components/selectField/SelectFieldCity";
import Button from "../../../../shared/components/Buttons/Button";
import {
  customerActions,
  logErrorsActions,
  transactionActions,
} from "../../../../redux/actions";
import SelectField from "../../../../shared/components/selectField/SelectField";
import ScanIdModal from "../../../Customers/CustomerModals/ScanIdModal";
import {
  capitalizeEveryWord,
  getIsShowCustomerScanButton,
  handleCustomerLicenseScanError,
  parseLiscenceScannedData,
} from "../../../../shared/utility";
import InputTextArea from "../../../../shared/components/inputTextArea/InputTextArea";
import CustomerCreditAndSubmitButtons from "./CustomerCreditAndSubmitButtons";

const AddNewCustomerModal = (props) => {
  const {
    user,
    store,
    logErrors,
    updateCustomer,
    customerToEdit,
    addNewCustomer,
    addCustomerModal,
    selectedCustomer,
    toggleAddCustomerModal,
    transferCustomerCredit,
    isTransferCustomerCreditModal,
  } = props;

  const { currentStore } = store;
  const formikRef = useRef();

  const initialValues = {
    firstName: customerToEdit?.firstName || globalConstants.EMPTY_STRING,
    lastName: customerToEdit?.lastName || globalConstants.EMPTY_STRING,
    email: customerToEdit?.email || globalConstants.EMPTY_STRING,
    mobile: customerToEdit?.mobile || globalConstants.EMPTY_STRING,
    driverLicense:
      customerToEdit?.driverLicense || globalConstants.EMPTY_STRING,
    line1: customerToEdit?.line1 || globalConstants.EMPTY_STRING,
    line2: customerToEdit?.line2 || globalConstants.EMPTY_STRING,
    city: customerToEdit?.city || globalConstants.EMPTY_STRING,
    state: customerToEdit?.state || globalConstants.EMPTY_STRING,
    zip: customerToEdit?.zip || globalConstants.EMPTY_STRING,
    currentBalance: customerToEdit?.currentBalance || 0,
    isSmsAlert: customerToEdit?.isSmsAlert || false,
    isEmailAlert: customerToEdit?.isEmailAlert || false,
    productNotification: customerToEdit?.productNotification || [],
    companyCustomerId: customerToEdit?.companyCustomerId || 0,
    note: customerToEdit?.note || globalConstants.EMPTY_STRING,
    driverLicenseIdType:
      customerToEdit?.driverLicenseIdType ||
      customerConstants.ID_TYPES[0].value,
  };

  const [isScanning, setIsScanning] = useState(false);
  const [isShowScanButton, setIsShowScanButton] = useState(
    getIsShowCustomerScanButton(customerToEdit?.driverLicenseIdType)
  );

  const toggleScanningModal = () => {
    setIsScanning(!isScanning);
  };

  const handleIdTypeChange = (value) => {
    setIsShowScanButton(getIsShowCustomerScanButton(value));
  };

  const handleHideModal = () => {
    toggleAddCustomerModal(false);
  };

  const handleSubmit = (data) => {
    selectedCustomer(true);

    if (customerToEdit?.id) {
      updateCustomer({
        ...data,
        id: customerToEdit?.id,
        store: {
          id: currentStore?.id,
          name: currentStore?.storeName,
        },
        companyId: currentStore?.companyId,
      });
    } else {
      if (isTransferCustomerCreditModal) {
        //it is true in case of guest customer -> transfer -> addNewCustomer (and only in this case customerToEdit has guestCustomer object) otherwise it is false and there is no guestCustomer=undefined in customerToEdit
        transferCustomerCredit({
          fromId: customerToEdit.selectedGuestCustomer.id,
          toId: EMPTY_STRING,
          isResetBalanceOfFrom: true,
          balanceToTransfer: 0,
          companyId: currentStore.companyId,
          store: {
            id: currentStore.id,
            name: currentStore.storeName,
          },
        });
      }
      addNewCustomer({
        ...data,
        store: {
          id: currentStore?.id,
          name: currentStore?.storeName,
        },
        companyId: currentStore?.companyId,
      });
    }
  };

  const handleScan = (scannedData) => {
    // const datae = `@ANSI 636014090102DL00410292ZC03330024DLDAQY5597134DCSBAINSDDENDACSIMRANDDFNDADSINGHDDGNDCACDCBNONEDCDNONEDBD12082020DBB01012000DBA01012025DBC1DAU076 INDAYBRODAG1234 MAIN STDAISAN JOSEDAJCADAK951480000  DCF12/08/202066815/AAFD/25DCGUSADAW205DAZBLKDCK20343Y55971340401DDAFDDB08292017DDJ11012021DDK1ZCZCABRNZCBBLKZCCZCD`;
    const preparedData = parseLiscenceScannedData(scannedData, IsExpired);

    if (preparedData.isDataParsed) {
      const usLicenseData = Parse(preparedData.data);

      const state = State.getStatesOfCountry(countryCodesConstants.USA).find(
        (state) => state.isoCode === usLicenseData.state
      );

      formikRef.current.setValues((prevVal) => ({
        ...prevVal,
        city: capitalizeEveryWord(usLicenseData.city),
        lastName: usLicenseData.lastName,
        state: state?.name,
        firstName: usLicenseData.firstName,
        line1: usLicenseData.streetAddress,
        zip: usLicenseData.postalCode?.slice(0, 5),
        driverLicense: usLicenseData.driversLicenseId,
      }));
      toggleScanningModal();
    } else {
      const errorMessgae = `scannedPayload: ${scannedData} , error Type: ${preparedData.data}`;
      handleCustomerLicenseScanError(
        user,
        errorMessgae,
        logErrors,
        currentStore
      );
    }
  };

  const handleError = () => {};

  return (
    <>
      {isScanning && (
        <div>
          <BarcodeReader onError={handleError} onScan={handleScan} />
        </div>
      )}
      <Modal
        show={addCustomerModal}
        size={customerToEdit?.isGuestCustomer ? "md" : "lg"}
        onHide={handleHideModal}
        animation={true}
        centered
        backdrop="static"
        className="add-inventory-modal"
      >
        <Modal.Header closeButton className="add-inventory-modal-header">
          <Modal.Title className="d-flex w-100 justify-content-between">
            <span className="add-inventory-modal-header-name">
              {customerToEdit?.id && customerToEdit?.isGuestCustomer
                ? "Edit Guest Customer"
                : customerToEdit?.id
                ? "Edit Customer"
                : "New Customer"}
            </span>
            {isShowScanButton && (
              <div className="me-3">
                <Button
                  type="submit"
                  className="w-100"
                  label={buttonNameConstants.SCAN_ID}
                  handleClick={toggleScanningModal}
                ></Button>
              </div>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-inventory-modal-body ">
          <Formik
            initialValues={initialValues}
            validate={addCustomerValidationSchema}
            onSubmit={handleSubmit}
            innerRef={formikRef}
          >
            {({ resetForm, values }) => (
              <Form className="">
                <Row className="m-0  p-0">
                  {customerToEdit?.isGuestCustomer ? (
                    <CustomerCreditAndSubmitButtons
                      user={user}
                      mdColSize={12}
                      resetForm={resetForm}
                      customerToEdit={customerToEdit}
                      handleHideModal={handleHideModal}
                    />
                  ) : (
                    <>
                      <Row className="p-0">
                        <p className="form-portion-heading">
                          Personal Information
                        </p>
                        <Col md={6} className="mb-3 ">
                          <InputTextField
                            name="firstName"
                            label="First Name"
                            placeHolder="First Name"
                          />
                        </Col>
                        <Col md={6} className="mb-3 ">
                          <InputTextField
                            name="lastName"
                            label="Last Name"
                            placeHolder="Last Name"
                          />
                        </Col>
                        <Col md={6} className="mb-3 ">
                          <InputTextField
                            name="email"
                            label="Email"
                            placeHolder="Email"
                          />
                        </Col>
                        <Col md={6} className="mb-3">
                          <InputGenericField
                            name="mobile"
                            type="tel"
                            placeHolder="Phone Number"
                            label="Phone Number"
                            inputMask="(999) 999-9999"
                          />
                        </Col>
                        <Col md={6}>
                          <SelectField
                            label="ID Type"
                            name="driverLicenseIdType"
                            placeHolder={"Select ID Type"}
                            options={customerConstants.ID_TYPES}
                            isSearchable={false}
                            onChangeValue={handleIdTypeChange}
                          />
                        </Col>
                        <Col md={6} className="mb-3  ">
                          <InputTextField
                            name="driverLicense"
                            label="ID Number"
                            placeHolder="ID Number"
                          />
                        </Col>
                      </Row>
                      <Row className="p-0 mt-3">
                        <p className="form-portion-heading ">Address</p>
                        <Col md={6} className="mb-3 ">
                          <InputTextField
                            name="line1"
                            label="Street 1"
                            placeHolder="Enter Street 1"
                          />
                        </Col>
                        <Col md={6} className="mb-3 ">
                          <InputTextField
                            name="line2"
                            label="Street 2"
                            placeHolder="Enter Street 2"
                          />
                        </Col>
                        <Col md={6} className="mb-3 ">
                          <SelectFieldCity
                            cityValue={
                              values?.city
                                ? { value: values.city, label: values.city }
                                : ""
                            }
                          />
                        </Col>
                        <Col md={6} className="mb-3">
                          {/* <SelectFieldState /> */}
                          <SelectField
                            label="State"
                            name="state"
                            placeHolder={"Select State"}
                            options={customerConstants.STATE_OPTIONS}
                          />
                        </Col>
                        <Col md={6} className="mb-3  ">
                          <InputTextField
                            name="zip"
                            placeHolder="00000"
                            label="Zip"
                          />
                        </Col>
                      </Row>
                      <Row className="p-0 mt-3">
                        <Col md={6} className="mb-3 ">
                          <p className="form-portion-heading ">Notes</p>
                          <InputTextArea
                            maxLength={1500}
                            name="note"
                            placeHolder="Enter Note"
                          />
                        </Col>
                        <CustomerCreditAndSubmitButtons
                          user={user}
                          mdColSize={6}
                          resetForm={resetForm}
                          customerToEdit={customerToEdit}
                          handleHideModal={handleHideModal}
                        />
                      </Row>
                    </>
                  )}
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      <ScanIdModal
        isScanning={isScanning}
        toggleScanningModal={toggleScanningModal}
      />
    </>
  );
};

// reducer
const mapStateToProps = (state) => ({
  store: state.store,
  customer: state.customer,
  user: state.authentication.user,
  isTransferCustomerCreditModal:
    state.transaction.isTransferCustomerCreditModal,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  logErrors: (data) => dispatch(logErrorsActions.logErrors(data)),
  addNewCustomer: (data) => dispatch(customerActions.addNewCustomer(data)),
  updateCustomer: (data) => dispatch(customerActions.updateCustomer(data)),
  toggleAddCustomerModal: (value) =>
    dispatch(transactionActions.toggleAddCustomerModal(value)),
  transferCustomerCredit: (data) =>
    dispatch(customerActions.transferCustomerCredit(data)),
});

//-------Export AddNewUser Component
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddNewCustomerModal);

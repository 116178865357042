import React from "react";
// eslint-disable-next-line import/no-cycle
import { authenticationService } from "../services";
import { GLOBAL_STATUS } from "./globalEnums";
import {
  globalConstants,
  routesPathConstants,
  toastMessages,
} from "../constants";
import { authenticationActions } from "../redux/actions";

function allErrorMessages(errors) {
  let errorMessages = [];
  // eslint-disable-next-line
  Object.keys(errors).map((key) => {
    errorMessages.push(errors[key]);
  });
  return errorMessages;
}

//-------Handle server error response
const handleHTTPResponses = (httpResponse) => {
  let errrorMessage = globalConstants.EMPTY_STRING;
  if (!httpResponse) {
    errrorMessage = toastMessages.NETWORK_ERROR;
    return Promise.reject(errrorMessage);
  }
  switch (httpResponse.status) {
    case GLOBAL_STATUS.BAD_REQUEST:
      errrorMessage = httpResponse.data?.message;
      break;

    case GLOBAL_STATUS.UNAUTHORIZED:
      errrorMessage = toastMessages.NOT_AUTHORIZED_TO_PERFORM_ACTION;
      authenticationActions.employeeLogout();
      break;

    case GLOBAL_STATUS.NOT_FOUND:
      errrorMessage = toastMessages.ITEM_NOT_FOUND;
      break;

    case GLOBAL_STATUS.INTERNAL_SERVER_ERROR:
      errrorMessage = toastMessages.PLEASE_CONTACT_AADMINISTRATOR;
      break;

    default:
      errrorMessage = toastMessages.PLEASE_CONTACT_AADMINISTRATOR;
      break;
  }

  return Promise.reject(errrorMessage);
};

//-------Refresh Token
const refreshToken = () => {};

function handleResponseByStatus(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (response.status === GLOBAL_STATUS.UNAUTHORIZED) {
      // auto logout if 401 response returned from api
      authenticationService.logout().then(window.location.assign("/log_in"));
    }

    const error = (data && data.message) || response.statusText;
    return Promise.reject(error);
  });
}

function handleServerResponse(response) {
  const { status, message, errors = [], payload } = response.data;

  const Msg = () => (
    <div>
      <h4 className="m-0">{message || "Something went wrong"}</h4>
      {Object.keys(errors).map((key) => (
        <div key={key}>{errors[key]}</div>
      ))}
    </div>
  );
  return Promise.reject(Msg);
}

export default {
  handleHTTPResponses,
  handleResponseByStatus,
  handleServerResponse,
};

import {
  EMPTY_STRING,
  globalConstants,
  inventoryConstants,
  printerConstants,
} from "../../../constants";
import {
  PRINT_LABEL_DESIGN_ENUMS,
  PRODUCT_CONDITIONS_ENUMS,
  PRODUCT_TYPES_ENUMS,
} from "../../../system/globalEnums";
import { getShortestSku } from "../../utility";

const getPriceOffset = (itemPrice) => {
  let priceXaxisOffset;

  switch (itemPrice.length) {
    case 3:
      priceXaxisOffset = 315;
      break;
    case 4:
      priceXaxisOffset = 300;
    case 5:
      priceXaxisOffset = 305;
      break;
    case 6:
      priceXaxisOffset = 290;
      break;
    case 7:
      priceXaxisOffset = 275;
      break;
    case 8:
      priceXaxisOffset = 255;
      break;
    case 9:
      priceXaxisOffset = 240;
      break;
    case 10:
      priceXaxisOffset = 225;
      break;
    default:
      priceXaxisOffset = 250;
  }
  return priceXaxisOffset;
};

const truncateItemName = (product_name, maxLength = 25) => {
  // Split the product_name into words
  const words = product_name.split(" ");

  let firstLine = "";
  let currentLength = 0;

  for (let i = 0; i < words.length; i++) {
    const potentialLength =
      currentLength === 0
        ? words[i].length
        : currentLength + 1 + words[i].length;

    if (potentialLength > maxLength) {
      break;
    }

    if (currentLength === 0) {
      firstLine += words[i];
    } else {
      firstLine += " " + words[i];
    }

    currentLength = potentialLength;
  }

  let secondPart = product_name.slice(firstLine.length).trim();

  if (secondPart.length > maxLength) {
    secondPart = secondPart.slice(0, maxLength - 3) + "...";
  }

  return {
    firstPart: firstLine,
    secondPart: secondPart,
  };
};

//-------function to break string when reach max length by word not character
const sliceStringWithWordBreaks = (text, maxLength) => {
  const slicedStrings = [];
  let currentIndex = 0;

  while (currentIndex < text.length) {
    let endIndex = currentIndex + maxLength;

    if (endIndex > text.length) {
      endIndex = text.length;
    } else {
      while (endIndex > currentIndex && !/\s/.test(text[endIndex - 1])) {
        endIndex--;
      }
    }

    slicedStrings.push(text.slice(currentIndex, endIndex).trim());
    currentIndex = endIndex;
  }

  return slicedStrings;
};
const calculateOffsetofItemName = (
  str,
  minLength,
  maxLength,
  minOffset,
  maxOffset
) => {
  const length = str.length;

  if (length < minLength || length > maxLength) {
    return 0;
  }

  const offset =
    minOffset +
    ((maxOffset - minOffset) * (maxLength - length)) / (maxLength - minLength);

  return offset;
};

export const printZebraLabel = (type, selectedRowsForLabel, storeLogo) => {
  let zpl;
  const isNewItem =
    selectedRowsForLabel.productType === PRODUCT_TYPES_ENUMS.VIDEO_GAME
      ? selectedRowsForLabel.price.type === PRODUCT_CONDITIONS_ENUMS.NEW
        ? printerConstants.NEW_PRODUCT_LABEL
        : printerConstants.PRE_OWNED_PRODUCT_LABEL
      : EMPTY_STRING;

  //------- General Properties for label
  const itemTypeCondition = `${selectedRowsForLabel.price.type}`;
  const tagList =
    selectedRowsForLabel.tags?.filter(
      (tag) => tag !== inventoryConstants.CUSTOM_ITEM_TAG
    ) || [];

  //------- Standard Label Settings
  const standardLabelItemName = truncateItemName(
    selectedRowsForLabel.product_name,
    30
  );

  //------- Minimal Label Settings
  const itemName = truncateItemName(selectedRowsForLabel.product_name);
  const itemCategory =
    selectedRowsForLabel.category_name.length > 30
      ? `${selectedRowsForLabel.category_name.slice(0, 30)}...`
      : selectedRowsForLabel.category_name;
  const itemPrice = `${Number(
    selectedRowsForLabel.price.unit_sell_price
  ).toFixed(2)}`;
  const itemSku = getShortestSku(selectedRowsForLabel.sku);
  const itemCondition =
    itemTypeCondition.length > 30
      ? `${itemTypeCondition.slice(0, 30)}...`
      : itemTypeCondition;
  const itemTags =
    tagList?.join(", ").length > 30
      ? `${tagList.join(", ").replaceAll("#", "").slice(0, 30)}...`
      : tagList.join(", ").replaceAll("#", "");

  //------- Alternate Label Settings
  const spineLabelItemName = truncateItemName(
    selectedRowsForLabel.product_name,
    19
  );
  const splineLabelitemCategory =
    selectedRowsForLabel.category_name.length > 21
      ? `${selectedRowsForLabel.category_name.slice(0, 21)}...`
      : selectedRowsForLabel.category_name;
  const splineLabelItemCondition =
    itemTypeCondition.length > 19
      ? `${itemTypeCondition.slice(0, 19)}...`
      : itemTypeCondition;

  const priceXaxisOffset = getPriceOffset(itemPrice);

  // ZPL script to print a simple barcode
  if (type === PRINT_LABEL_DESIGN_ENUMS.MINIMAL.value) {
    zpl = [
      `^XA\n`,
      `^FWN\n`,
      `^BY1,1,60\n`,
      `^CF0,20\n`,
      `^FO30,107${storeLogo || globalConstants.EMPTY_STRING}\n`,
      `^FO15,15^FD${itemName.firstPart}^FS\n`,
      `^FO15,40^FD${itemName.secondPart}^FS\n`,
      `^CF0,15\n`,
      `^FO15,${itemName.secondPart ? "65" : "40"}^FD${itemCategory}^FS\n`,
      `^FO15,${
        itemName.secondPart ? "80" : "60"
      }^FDCondition: ${itemCondition} ^FS\n`,
      `^FO15,${itemName.secondPart ? "95" : "80"}^FD${itemTags}^FS\n`,
      `^CF0,35\n`,
      `^FO${priceXaxisOffset},15^FD$${itemPrice}^FS\n`,
      `^FO${calculateOffsetofItemName(
        itemSku,
        6,
        15,
        storeLogo ? 190 : 130,
        storeLogo ? 240 : 150
      )},125^BC^FD${itemSku}^FS\n`,
      `^XZ\n`,
    ];
  } else if (type === PRINT_LABEL_DESIGN_ENUMS.ALTERNATE.value) {
    zpl = [
      `^XA\n`,
      `^FWR\n`,
      `^BY1,1,60\n`,
      `^CF0,20\n`,
      `^FO30,107\n`,
      `^FO210,${calculateOffsetofItemName(
        spineLabelItemName.firstPart,
        1,
        19,
        20,
        100
      )}^FD${spineLabelItemName.firstPart}^FS\n`,
      spineLabelItemName.secondPart
        ? `^FO190,${
            spineLabelItemName.secondPart
              ? calculateOffsetofItemName(
                  spineLabelItemName.secondPart,
                  1,
                  21,
                  20,
                  100
                )
              : ""
          }^FD${spineLabelItemName.secondPart}^FS\n`
        : "",
      `^CF0,17\n`,
      `^FO${
        spineLabelItemName.secondPart ? "165" : "185"
      },${calculateOffsetofItemName(
        splineLabelitemCategory,
        1,
        19,
        20,
        100
      )}^FD${splineLabelitemCategory}^FS\n`,
      `^FO${
        spineLabelItemName.secondPart ? "140" : "165"
      },${calculateOffsetofItemName(
        splineLabelItemCondition,
        1,
        19,
        20,
        100
      )}^FD${splineLabelItemCondition} ^FS^FO15,80^FD^FS\n`,
      `^CF0,35\n`,
      `^FO50,${calculateOffsetofItemName(
        itemPrice,
        1,
        10,
        15,
        90
      )}^FD$${itemPrice}^FS\n`,
      `^CF0,10\n`,
      `^FO110,0^FD_________________________________________^FS\n`,
      `^FO270,${calculateOffsetofItemName(itemSku, 6, 15, 15, 70)}\n`,
      `^BCN\n`,
      `^FD${itemSku}^FS\n`,
      `^XZ\n`,
    ];
  } else if (type === PRINT_LABEL_DESIGN_ENUMS.STANDARD.value) {
    zpl = [
      `^XA\n`,
      `^FWN\n`,
      `^BY1,1,60\n`,
      `^CF0,15\n`,
      `^FO10,15${storeLogo || globalConstants.EMPTY_STRING}\n`,
      `^FO15,105^FD${standardLabelItemName.firstPart}^FS\n`,
      `^FO15,125^FD${standardLabelItemName.secondPart}^FS\n`,
      `^CF0,15\n`,
      `^FO15,${
        standardLabelItemName.secondPart ? "145" : "125"
      }^FD${itemCategory}^FS\n`,
      `^FO15,${
        standardLabelItemName.secondPart ? "165" : "145"
      }^FDCondition: ${itemCondition} ^FS\n`,
      `^FO15,${
        standardLabelItemName.secondPart ? "185" : "165"
      }^FD${itemTags}^FS\n`,
      `^CF0,30\n`,
      `^FO${
        isNewItem === printerConstants.NEW_PRODUCT_LABEL ? "270" : "200"
      },90^FD${isNewItem} ^FS\n`,
      `^CF0,60\n`,
      `^FO${calculateOffsetofItemName(
        itemPrice,
        4,
        10,
        storeLogo ? 110 : 10,
        storeLogo ? 250 : 140
      )},20^FD$${itemPrice}^FS\n`,
      `^FO260,125^BC^FD${itemSku}^FS\n`,
      `^XZ\n`,
    ];
  }
  console.log("zpl", zpl);
  return zpl;
};

// export const printLabel = async (zpl) => {
//   try {
//     // Create a new instance of the object
//     const browserPrint = new ZebraBrowserPrintWrapper();

//     // Select default printer
//     const defaultPrinter = await browserPrint.getDefaultPrinter();

//     // Set the printer
//     browserPrint.setPrinter(defaultPrinter);
//     browserPrint.print(zpl);
//   } catch (error) {
//     customToast(error, toastType.ERROR);
//   }
// };

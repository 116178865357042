import React from "react";
import { connect } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";

import MainWrapper from "./MainWrapper";
import { getRoutes } from "../../../system/routes";
import AppLayout from "../../layout/AppLayout";
import {
  routesPathConstants,
  STORE_OWNER_NOT_ALLOWED_ROUTES,
  STORE_MANAGER_NOT_ALLOWED_ROUTES,
  EMPLOYEE_NOT_ALLOWED_ROUTES,
  EMPTY_STRING,
  STORE_INACTIVE_ROUTES,
} from "../../../constants";
import { STORE_CARD_PAYMENT_TYPE_ENUMS } from "../../../system/globalEnums";
import ReportProblem from "../ErrorBoundary/ReportProblem";
import {
  createCompanyDynamicUrl,
  getDynamicNotAllowedRoutes,
  isAdminLogin,
  isEmployeeLogin,
  isManagerLogin,
  isStoreOwnerLogin,
} from "../../../shared/utility";
import { Nav } from "react-bootstrap";

const RouterWrapper = ({
  isStoreActive,
  activeCardPaymentType,
  isCompanyAuthenticated,
  company,
  user,
  ...props
}) => {
  const { domainUrl } = company;
  const isAdmin = isAdminLogin(user);
  const isManager = isManagerLogin(user);
  const isEmployee = isEmployeeLogin(user);
  const isStoreOwner = isStoreOwnerLogin(user);

  let routes = [];
  let parentPath = EMPTY_STRING;

  if (isAdmin) {
    routes = getRoutes(EMPTY_STRING);
  } else {
    parentPath = domainUrl ? `/${domainUrl}` : "";
    routes = getRoutes(parentPath);
  }

  // as dashboard is not allowed to employee, so update the default and unknown route for employee
  if (isEmployee) {
    routes.forEach((route) => {
      if (
        (route.path ===
          createCompanyDynamicUrl(routesPathConstants.DEFAULT_PATH) ||
          route.path ===
            createCompanyDynamicUrl(routesPathConstants.UNKNOWN_PATH)) &&
        route.protected
      ) {
        route.component = (
          <Navigate
            to={createCompanyDynamicUrl(routesPathConstants.SELL_AND_TRADE)}
          />
        );
      }
    });
  }
  //------- Public Routes
  const publicRoutes = (
    <>
      {routes
        .filter((route) => !route.isEmployeeRoute && !route.protected)
        .map((route, index) => (
          <Route key={index} path={route.path} element={route.component} />
        ))}
    </>
  );

  //------- Employee Login Routes
  let employeeRoutes = (
    <>
      {routes
        .filter((route) => route.isEmployeeRoute)
        .map((route, index) => (
          <Route key={index} path={route.path} element={route.component} />
        ))}
    </>
  );

  //------- Private Routes
  let privateRoutes = (
    <>
      {routes
        .filter((route) => {
          // STORE OWNER NOT ALLOWED
          return !isAdmin
            ? STORE_OWNER_NOT_ALLOWED_ROUTES.includes(route.path)
              ? false
              : true
            : true;
        })
        .filter((route) => {
          // STORE MANAGER NOT ALLOWED ROUTES
          return !isAdmin && !isStoreOwner
            ? getDynamicNotAllowedRoutes(
                STORE_MANAGER_NOT_ALLOWED_ROUTES
              ).includes(route.path)
              ? false
              : true
            : true;
        })
        .filter((route) => {
          // EMPLOYEE NOT ALLOWED ROUTES
          return !isAdmin && !isStoreOwner && !isManager
            ? getDynamicNotAllowedRoutes(EMPLOYEE_NOT_ALLOWED_ROUTES).includes(
                route.path
              )
              ? false
              : true
            : true;
        })
        .filter((route) => {
          // if store is inActive only allow these routes, at first render the isStoreActive is undefined so in this all the routes are allowd
          return isStoreActive === undefined || isStoreActive || isAdmin
            ? true
            : getDynamicNotAllowedRoutes(STORE_INACTIVE_ROUTES).includes(
                route.path
              )
            ? true
            : false;
        })
        .filter((route) => {
          return route.path.toLowerCase() ===
            routesPathConstants.SETTINGS_DEVICES.toLowerCase()
            ? activeCardPaymentType ===
                STORE_CARD_PAYMENT_TYPE_ENUMS.VERIFONE || false
            : true;
        })
        .filter((route) => route.protected)
        .map((route, index) => (
          <Route key={index} path={route.path} element={route.component} />
        ))}
    </>
  );

  return (
    <MainWrapper>
      <Routes>
        {(isAdmin && props.isAuthenticated) ||
        (isCompanyAuthenticated && props.isAuthenticated) ? (
          <>
            <Route path={parentPath} element={<AppLayout />}>
              {privateRoutes}
            </Route>
            <Route
              path={routesPathConstants.UNKNOWN_PATH}
              element={
                <Navigate to={`${parentPath}/${privateRoutes[0]?.path}`} />
              }
            />
          </>
        ) : isCompanyAuthenticated && !props.isAuthenticated ? (
          employeeRoutes
        ) : (
          publicRoutes
        )}
        <Route
          path={routesPathConstants.REPORT_ISSUE}
          element={<ReportProblem />}
        />
      </Routes>
    </MainWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.authentication.user,
    company: state.authentication.company,
    isAuthenticated: state.authentication.isAuthenticated,
    isStoreActive: state.store.currentStore.isStoreActive,
    isCompanyAuthenticated: state.authentication.isCompanyAuthenticated,
    activeCardPaymentType: state.store.currentStore.activeCardPaymentType,
  };
};

export default connect(mapStateToProps)(React.memo(RouterWrapper));

import React from "react";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router";
import { Row, Col } from "react-bootstrap";

import LogoImage from "../../assets/images/LoginLogo.png";
import { authenticationActions } from "../../redux/actions";
import Button from "../../shared/components/Buttons/Button";
import InputTextField from "../../shared/components/inputTextField/InputTextField";
import { forgotPasswordValidationSchema } from "../../shared/validationSchema/validationSchema";
import {
  systemConstants,
  buttonTypeConstants,
  buttonNameConstants,
  routesPathConstants,
  authenticationConstants,
  EMPTY_STRING,
} from "../../constants";
import {
  isSpinnerEnabled,
  createCompanyDynamicUrl,
} from "../../shared/utility";

//-------ForgotPin component start here
const ForgotPin = (props) => {
  const initialValues = {
    email: "",
  };
  const navigate = useNavigate();
  //-------Handle handleSendEmail click
  const handleSendEmail = (values) => {
    props.forgotPassword(
      {
        email: values.email.toLowerCase(),
        userId: EMPTY_STRING,
      },
      props.company?.id
    );
  };

  const handleCancelButtonClick = () => {
    navigate(createCompanyDynamicUrl(routesPathConstants.EMPLOYEE_LOGIN));
  };

  //-------Return ForgotPassword Component
  return (
    <div>
      <div className=" login-card-wrapper row d-flex justify-content-center align-items-center m-0 p-4 min-vh-100 min-vw-100">
        <img
          className="login-collect-pos-icon"
          src={LogoImage}
          alt="collect pos icon"
        />

        <div className="login-card col-sm-8 col-md-6 col-lg-4 py-4 px-5 pb-3 d-flex flex-column justify-content-center align-items-center rounded-4">
          <Row className="w-100 m-0">
            <Col
              md={12}
              className="p-0 d-flex justify-content-center align-items-center py-3"
            >
              <span className="company-name">
                {props?.company?.companyName || EMPTY_STRING}
              </span>
              {/* <img src={CompanyLogo} className="company-logo" /> */}
            </Col>
          </Row>
          <h1>Employee-Forgot ID or PIN?</h1>
          <p className="opacity-75 text-center">
            Enter your email to retrieve your ID and reset your PIN (if
            required).
          </p>
          <Formik
            initialValues={initialValues}
            validate={forgotPasswordValidationSchema}
            onSubmit={handleSendEmail}
          >
            <Form className="login-form-wrapper">
              <Row className="w-100 m-0">
                <Col xs={12} className="p-0 mb-3">
                  <InputTextField
                    type="email"
                    name="email"
                    label="Email"
                    placeHolder="Email Address"
                  />
                </Col>
                <Col xs={12} className="p-0 mt-3 ">
                  <Button
                    type="submit"
                    className="w-100"
                    label={buttonNameConstants.SEND_EMAIL}
                    buttonType={buttonTypeConstants.BLACK_BUTTON}
                    isDisabled={isSpinnerEnabled(
                      props.spinnerArray,
                      authenticationConstants.FORGOT_PASSWORD
                    )}
                  />
                </Col>
                <Col
                  xs={12}
                  className="p-0 mt-2 mb-0 d-flex align-items-center"
                >
                  <span
                    className="forgot-password-link"
                    onClick={handleCancelButtonClick}
                  >
                    Cancel
                  </span>
                </Col>
                <Col
                  xs={12}
                  className="p-0 mt-4 mb-0 d-flex justify-content-center align-items-center text-muted"
                >
                  Powered by CollectPOS
                </Col>
              </Row>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
}; //-------ForgotPin component end here

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  spinnerArray: state.system.spinnerArray,
  company: state.authentication.company,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  forgotPassword: (data, companyId) =>
    dispatch(authenticationActions.forgotPassword(data, companyId)),
});

//-------Export ForgotPin Component
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPin);

import { Row, Col } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ReactImageZoom from "react-image-zoom";

import Topbar from "../../../../shared/components/topbar/Topbar";
import Button from "../../../../shared/components/Buttons/Button";
import ProductImage from "../../../../assets/images/productimage.png";
import CustomTooltip from "../../../../shared/components/toolTipComponent/CustomTooltip";
import DataTableComponent from "../../../../shared/components/table/ReactDataTableComponent";
import SimpleNumberField from "../../../../shared/components/inputNumberField/SimpleNumberField";
import {
  TickIcon,
  CopyIcon,
  EditIcon,
  CrossIcon,
  DollarIcon,
  ActionDotsIcons,
} from "../../../../assets/icons/iconsProvider";
import {
  routesPathConstants,
  buttonNameConstants,
  buttonTypeConstants,
} from "../../../../constants";
import {
  handleHighlightSelectedRow,
  handleDehighlightSelectedRow,
  handleKeyDown,
  createCompanyDynamicUrl,
} from "../../../../shared/utility";
import { useLocation, useNavigate } from "react-router";
import { inventoryActions } from "../../../../redux/actions";
import PrintLabelModal from "../../Items/printingLabelModal/PrintLabelModal";

const defaultGridState = {
  pageSize: 50,
  pageIndex: 0,
  sortBy: [],
};

const ChangePrice = (props) => {
  const { state } = useLocation();
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [isUpdateAll, setIsUpdateAll] = useState(false);
  const [rowBeingEditing, setRowBeingEditing] = useState({});
  const [gridState, setGridState] = useState(defaultGridState);
  const [copiedSku, setCopiedSku] = useState(null);
  const [printLabelModal, setPrintLabelModal] = useState(false);
  const [productStockPrice, setProductSTockPrice] = useState(
    state?.data?.price.unit_sell_price
  );
  const [defaultSortFieldId, setDefaultSortFieldId] = useState();
  const handleStockPriceChange = (value) => {
    setProductSTockPrice(value);
  };
  const navigate = useNavigate();
  const {
    currentStore,
    getInventoryByPricechartingId,
    inventoryProducts,
    updatePriceChangeProductPrices,
  } = props;
  //-------handle Row Double Click
  const handleRowDoubleClick = (row) => {
    if (rowBeingEditing?.id) {
      handleDehighlightSelectedRow(rowBeingEditing?.id);
      setRowBeingEditing({});
    } else {
      handleHighlightSelectedRow(row);
      handleDehighlightSelectedRow(rowBeingEditing?.id);
      setRowBeingEditing(row);
    }
  };

  const togglePrintLabelModal = () => {
    setPrintLabelModal(!printLabelModal);
  };

  //-------handle edit item click
  const handleEditClick = (row) => {
    if (rowBeingEditing?.id) {
      handleDehighlightSelectedRow(rowBeingEditing?.id);
      setRowBeingEditing({});
    } else {
      handleHighlightSelectedRow(row);
      handleDehighlightSelectedRow(rowBeingEditing?.id);
      setRowBeingEditing(row);
    }
  };

  const handlePrintButtonClick = () => {
    if (inventoryProducts) {
      togglePrintLabelModal();
    }
  };

  //-------Handle Edit Item Submit Click
  const handleEditSubmitClick = (row) => {
    updatePriceChangeProductPrices(
      currentStore?.id,
      {
        changeProductPrice: [
          {
            unit_sell_price: rowBeingEditing.price.unit_sell_price,
            id: rowBeingEditing.id,
          },
        ],
        removeProducts: [rowBeingEditing.sku],
        priceChartingProducts: {
          id: state?.data?.product_id,
          productName: state?.data?.product_name,
          categoryName: state?.data?.category_name,
        },
      },
      false
    );
    handleDehighlightSelectedRow(rowBeingEditing?.id);
    setRowBeingEditing({});
  };

  //-------Handle Edit Item Submit Click
  const handleEditCancelClick = () => {
    handleDehighlightSelectedRow(rowBeingEditing?.id);
    setRowBeingEditing({});
  };

  //-------copy sku number to clipboard
  const copyToClipboard = (skuNumber) => {
    const textArea = document.createElement("textarea");
    textArea.value = skuNumber;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    setCopiedSku(skuNumber);
    setTimeout(() => {
      setCopiedSku(null);
    }, 3000);
  };
  //-------define column headers for the items table
  const columnHeaders = [
    {
      id: "item",
      name: "Item",
      selector: (row) => row?.product_name,
      cell: (row) => {
        return (
          <div className="d-flex align-items-center">
            <img className="product-image" src={row?.imgUrl || ProductImage} />
            <div className="product-content-wrapper d-flex flex-column gap-2">
              <span>{row?.product_name}</span>
              <div className="sku-number-text d-flex align-items-center">
                <p className="mb-0">{row.sku}</p>
                <button
                  type="button"
                  onClick={() => copyToClipboard(row.sku)}
                  className="copy-button"
                >
                  <CopyIcon />
                </button>
                {copiedSku === row.sku && <span>Copied!</span>}
              </div>
            </div>
          </div>
        );
      },
      sortable: true,
      sortDirection: null,
    },
    {
      id: "unit_sell_price",
      name: "Price",
      selector: (row) => row?.price.unit_sell_price,
      cell: (row) => {
        return row.id === rowBeingEditing?.id ? (
          <input
            min={0}
            onKeyDown={handleKeyDown}
            type="number"
            defaultValue={row?.price.unit_sell_price}
            className="input-textfield input-textfield-without-icon  editable-table-row-fields inventory-editable-row-fields"
            onChange={(e) => {
              setRowBeingEditing(() => ({
                ...rowBeingEditing,
                price: {
                  ...rowBeingEditing.price,
                  unit_sell_price: e.target.value,
                },
              }));
            }}
          ></input>
        ) : (
          <span className="inventory-cell-max-size">
            {row?.price.unit_sell_price}
          </span>
        );
      },
      sortable: true,
      sortDirection: null,
    },
    {
      id: "action",
      name: "",
      cell: (row) => {
        return row.id === rowBeingEditing?.id ? (
          <>
            <button
              id={`modalTool${row.id}`}
              className="action-Button-Wrapper"
              onClick={() => handleEditSubmitClick(row)}
            >
              <TickIcon className="cursor-pointer text-primary" />
            </button>
            <button
              id={`modalTool${row.id}`}
              className="action-Button-Wrapper"
              onClick={() => handleEditCancelClick(row)}
            >
              <CrossIcon className="cursor-pointer" />
            </button>
          </>
        ) : (
          <>
            <CustomTooltip anchorSelect={`#tool${row.id}`}>
              <Button
                className="w-100"
                IconImage={EditIcon}
                label={buttonNameConstants.EDIT}
                handleClick={() => handleEditClick(row)}
                buttonType={buttonTypeConstants.ACTION_BUTTON}
              />
            </CustomTooltip>
            <button id={`tool${row.id}`} className="action-Button-Wrapper">
              <ActionDotsIcons className="cursor-pointer" />
            </button>
          </>
        );
      },
      sortable: false,
      width: "5%",
    },
  ];

  //-------Hanlde Table Sorting
  const onTableSort = (column, sortDirection) => {
    setColumns(
      columns.map((col) => {
        if (col.id === column.id) {
          return {
            ...col,
            sortDirection,
          };
        } else {
          return {
            ...col,
            sortDirection: null,
          };
        }
      })
    );
    setDefaultSortFieldId(column.id);
  };

  //-------Update GridState
  const updateGridState = (newGridState) => {
    setGridState(newGridState);
  };

  useEffect(() => {
    setColumns(columnHeaders);
  }, [rowBeingEditing, copiedSku]);

  useEffect(() => {
    if (inventoryProducts?.length > 0) {
      setData(
        inventoryProducts.filter(
          (item) =>
            item.price.type === state?.data?.price.type &&
            item.sku !== state?.data?.sku
        )
      );
    }
  }, [inventoryProducts]);

  useEffect(() => {
    if (currentStore?.id !== state?.data?.store.id) {
      navigate(
        createCompanyDynamicUrl(
          routesPathConstants.INVENTORY_ITEMS_PRICE_CHANGES
        )
      );
    }
    getInventoryByPricechartingId(state?.data?.store.id, {
      id: state?.data?.product_id,
      productName: state?.data?.product_name,
      categoryName: state?.data?.category_name,
    });
  }, [currentStore]);

  const handleCancelButton = () => {
    navigate(
      createCompanyDynamicUrl(routesPathConstants.INVENTORY_ITEMS_PRICE_CHANGES)
    );
  };

  const hanldeSaveButtonClick = () => {
    let dataToSend = {};
    if (isUpdateAll) {
      dataToSend = {
        changeProductPrice: inventoryProducts?.map((item) => ({
          unit_sell_price: productStockPrice,
          id: item.id,
        })),
        removeProducts: inventoryProducts?.map((item) => item.id),
      };
    } else {
      dataToSend = {
        changeProductPrice: [
          {
            unit_sell_price: productStockPrice,
            id: state?.data?.id,
          },
        ],
        removeProducts: [state?.data?.id],
      };
    }
    updatePriceChangeProductPrices(currentStore?.id, dataToSend, true);
  };

  useEffect(() => {
    if (state.currentStore.id !== currentStore.id) {
      navigate(
        createCompanyDynamicUrl(
          routesPathConstants.INVENTORY_ITEMS_PRICE_CHANGES
        )
      );
    }
  }, [currentStore]);

  return (
    <>
      <Topbar
        backNavigation={{
          isVisible: true,
          route: routesPathConstants.INVENTORY_ITEMS_PRICE_CHANGES,
        }}
        title="Price Changes - Edit"
        exportButton={true}
        buttonType={buttonTypeConstants.LIGHT_PURPLE}
        label={buttonNameConstants.PRINT_PRODUCT_LABEL}
        handleClick={handlePrintButtonClick}
      />
      <Row className="m-0">
        <Col md={5}>
          <div className="price-change-product-info-card">
            <Row className="m-0">
              <Col className="px-0 d-flex align-items-center gap-3" md={12}>
                <div className="product-image-wrapper">
                  <ReactImageZoom
                    width={80}
                    className="rounded-2 overflow-hidden"
                    zoomPosition="original"
                    img={state?.data?.imgUrl || ProductImage}
                  />
                </div>
                <div className="product-content-wrapper">
                  <span>{state?.data?.product_name}</span>
                  <span>{state?.data?.category_name}</span>
                  <span>#{state?.data?.sku}</span>
                </div>
              </Col>
              <Col
                className="px-0 mt-1 py-2 d-flex justify-content-between align-items-center border-bottom product-stats-wrapper"
                md={12}
              >
                <span>Competitor Sell/Buy Price</span>
                <span>
                  ${state?.data?.price?.unit_sell_price}/$
                  {state?.data?.price?.unit_purchase_price}
                </span>
              </Col>
              <Col
                className="px-0 mt-1 py-2 d-flex justify-content-between align-items-center border-bottom product-stats-wrapper"
                md={12}
              >
                <span>Condition</span>
                <span>{state?.data?.price.type}</span>
              </Col>
              <Col
                className="px-0 mt-1 py-2 d-flex justify-content-between align-items-center border-bottom product-stats-wrapper"
                md={12}
              >
                <span>Category</span>
                <span>{state?.data?.category_name}</span>
              </Col>
              <Col
                className="px-0 mt-1 py-2 d-flex justify-content-between align-items-center border-bottom product-stats-wrapper"
                md={12}
              >
                <span>Store</span>
                <span>{state?.data?.store.name}</span>
              </Col>{" "}
              <Col
                className="px-0 mt-1 py-2 d-flex justify-content-between align-items-center border-bottom product-stats-wrapper"
                md={12}
              >
                <span>Available Quantity</span>
                <span>{state?.data?.price.quantity}</span>
              </Col>
            </Row>
          </div>
          <Col
            className="px-0 d-flex justify-content-between align-items-center mt-3"
            md={12}
          >
            <span>Stock Price</span>
            <SimpleNumberField
              className="stock-price-field "
              value={productStockPrice}
              onChange={handleStockPriceChange}
              maxValue={9999999}
              IconImage={DollarIcon}
            />
          </Col>
          <Col md={12} className="d-flex align-items-center gap-2 mt-1">
            <input
              className="checkbox-field"
              type="checkbox"
              checked={isUpdateAll}
              onChange={() => {
                setIsUpdateAll(!isUpdateAll);
              }}
            />
            Update for all
          </Col>
          <Col md={12} className="mt-3  d-flex gap-2 ">
            <Button
              type="button"
              label={buttonNameConstants.CANCEL}
              buttonType={buttonTypeConstants.GHOST_BUTTON}
              className="w-100"
              handleClick={() => {
                handleCancelButton();
              }}
            ></Button>
            <Button
              type="submit"
              className="w-100"
              handleClick={() => hanldeSaveButtonClick()}
              label={buttonNameConstants.SAVE}
            ></Button>
          </Col>
        </Col>
        <Col md={7}>
          <DataTableComponent
            data={data}
            columns={columns}
            isPagination={false}
            onSort={onTableSort}
            gridState={gridState}
            className="editable-table"
            setGridState={updateGridState}
            defaultSortFieldId={defaultSortFieldId}
            onRowDoubleClicked={(row) => {
              handleRowDoubleClick(row);
            }}
          />
        </Col>
      </Row>
      <PrintLabelModal
        printLabelModal={printLabelModal}
        togglePrintLabelModal={togglePrintLabelModal}
        selectedRows={inventoryProducts || []}
        // singlePrintArray={singlePrintArray}
      />
    </>
  );
};

//-------Mapping the component's props to the reducer's state
const mapStateToProps = (state) => ({
  currentStore: state.store.currentStore,
  inventoryProducts: state.inventory.inventoryProducts,
  company: state.authentication.company,
});

//-------Mapping the component's props to the related actions
const mapDispatchToProps = (dispatch) => ({
  getInventoryByPricechartingId: (storeId, data) =>
    dispatch(inventoryActions.getInventoryByPricechartingId(storeId, data)),
  updatePriceChangeProductPrices: (storeId, data, isRedirect) =>
    dispatch(
      inventoryActions.updatePriceChangeProductPrices(storeId, data, isRedirect)
    ),
});

//-------Export AddNewUser Component
export default connect(mapStateToProps, mapDispatchToProps)(ChangePrice);
